import customAxiosInterceptor from "../customAxiosInterceptor";

// Fetch blogs  
// GET https://api.infocomp.ba/api/post
export const fetchBlogsThunk = async (data, thunkAPI) => {
  try {
// blogs, partneri, solarni-paneli
    const response = await customAxiosInterceptor.get("/post", { params: data});
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

// Fetch blog with slug
// GET https://api.infocomp.ba/api/post/1
export const fetchBlogBySlugThunk = async (post_slug, thunkAPI) => {
    try {
      const response = await customAxiosInterceptor.get(`/post/${post_slug}`);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  };