import { Form, Formik } from "formik";
// import { withFormikDevtools } from "formik-devtools-extension";
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { InputField } from "../../../components";
import LinkLanguage from "../../../locales/LinkLanguage";
import { login } from "../../../store/authentication/authentication.slice"
import { isLogin } from "../../../services/LocalStorage/LocalStorage.service";

import "../../../components/form/FormikForm.scss";


const Login = () => {
  const { t } = useTranslation("login", { useSuspense: false });
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const LoginValidation = object().shape({
    email: string()
      .required(t("unesite_email"))
      .email(t("email_mora_biti_validan")),
    password: string()
      .min(8, t("najmanje_8_cifara"))
      .required(t("unesite_lozinku")),
  });

  const handleLogin = (values: any, { setSubmitting }: any) => {
    dispatch(login(values)).then(() => {
      if (isLogin()) {
        navigate("/");
      } else {
        // navigate("/login");
        setSubmitting(false);
      }
    });
  };

  return (
    <div className="Form-Box">
      <div className="Form">
        <h1 className="Form-Title">{t("prijavi_se")}</h1>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={LoginValidation}
          onSubmit={handleLogin}
        >
          {
            (
              //   values,
              //   errors,
              //   touched,
              //   handleChange,
              //   handleBlur,
              //   handleSubmit,
              //isSubmitting,
              formikProps
            ) => (
              // withFormikDevtools(
              //   formikProps,
              <Form className="Formik-Form">
                <InputField name="email" label={t("email")} required={true} />
                <InputField
                  name="password"
                  label={t("lozinka")}
                  type="password"
                  required={true}
                />
                {/* <CheckBoxField
                name="saveData"
                label="Zapamti podatke za prijavu"
                type="checkbox"
              /> */}
                <button
                  type="submit"
                  disabled={formikProps.isSubmitting}
                  className="Form-Button"
                >
                  {t("potvrdi")}
                </button>
                <label className="Form-Label">
                  {t("nemate_nalog")} 
                  &nbsp;
                  <LinkLanguage 
                  to="/registration" 
                  // className="Form-Label"
                  >
                    {t("registruj_se")}
                  </LinkLanguage>
                </label>
              </Form>
            )
            // )
          }
        </Formik>
      </div>
    </div>
  );
};
export default Login;
