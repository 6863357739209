import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from 'antd';
import { API_IMAGES_URL } from '../../config/constants.tsx';
import { putImageWebpSupport } from '../../store/basic/basic.slice.js';


/**
 * @param {object} props Component props
 * @param {string} props.img_name name of image
 * @param {boolean} props.calculate should image size be calculated
 * @param {integer} props.width width of image in px
 * @param {integer} props.height height of image in px
 * @param {string} props.img_class_name 
 * @param {string} props.img_alt 
 */
const PhotoDisplayHandler = ({ width = null, height = null, calculate = true, img_alt = "Infocomp image", img_class_name = "", ...props }) => {

    const [closestWidth, setClosestWidth] = useState(null);
    const [baseName, setBaseName] = useState(null);
    const [extension, setExtension] = useState(null);
    const { webpSupport } = useSelector(store => store.basic);
    const refContainer = useRef(null);
    const dispatch = useDispatch();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    let content;

    useEffect(() => {
        // const sizes = [2560, 1920, 1600, 1440, 1366, 1200, 1024, 960, 800, 768, 640, 533, 455, 320, 160];
        const sizes = [1920, 1600, 1440, 1366, 1200, 1024, 960, 800, 768, 640, 533, 455, 320, 160];
        if (webpSupport === null) {
            let elem = document.createElement('canvas');
            if (!!(elem.getContext && elem.getContext('2d'))) {
                dispatch(putImageWebpSupport(elem.toDataURL('image/webp').indexOf('data:image/webp') === 0));
            } else {
                dispatch(putImageWebpSupport(false));
            }
        } else {
            if (props.img_name !== undefined) {
                let filename = props.img_name;
                let lastIndex = filename.lastIndexOf('.');
                let extension = filename.substring(lastIndex + 1);
                let name = filename.substring(0, lastIndex);
                setBaseName(name);
                setExtension(extension);
            }
            if (refContainer.current) {
                let width = refContainer.current.clientWidth;
                let height = refContainer.current.clientHeight;
                // let width = refContainer.current.parentElement.offsetWidth;
                // let height = refContainer.current.parentElement.offsetHeight;
                setDimensions({
                    width: width,
                    height: height,
                });
                // console.log("sirina : " + width + " visina " + height)
                const closest = sizes.reduce((prev, curr) => {
                    return (Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev);
                });
                // console.log(closest)
                setClosestWidth(closest);
            }
        }
    }, [dispatch, webpSupport, props.img_name]);

    if (calculate === true) {

        if (webpSupport === null || closestWidth === null || baseName === null || extension === null) {
            content = <Skeleton.Image active={true}
                // width={width === null ? dimensions.width : width}
                // height={height !== null ? height : 'auto'}
                style={{ width: width === null ? dimensions.width : width, height: height !== null ? height : 'auto' }}
                className={img_class_name}
            />
        } else if (webpSupport === true && closestWidth !== null && baseName !== null && extension !== null) {
            content = <img
                src={API_IMAGES_URL + baseName + '_' + closestWidth + '.webp'}
                width={width === null ? dimensions.width : width}
                height={height !== null ? height : 'auto'}
                className={img_class_name}
                alt={img_alt}
            />
        } else if (webpSupport === false && closestWidth !== null && baseName !== null && extension !== null) {
            content = <img
                src={API_IMAGES_URL + baseName + '_' + closestWidth + '.' + extension}
                width={width === null ? dimensions.width : width}
                height={height !== null ? height : 'auto'}
                className={img_class_name}
                alt={img_alt}
            />
        } else {
            content = <Skeleton.Image active={true}
                style={{ width: width === null ? dimensions.width : width, height: height !== null ? height : 'auto' }}
                // width={width === null ? dimensions.width : width}
                // height={height !== null ? height : 'auto'}
                className={img_class_name}
            />
        }
    } else {
        if (webpSupport === null || closestWidth === null || baseName === null || extension === null) {
            content = <Skeleton.Image active={true}
                // width={width === null ? dimensions.width : width}
                // height={height !== null ? height : 'auto'}
                style={{ width: width === null ? dimensions.width : width, height: height !== null ? height : 'auto' }}
                className={img_class_name} />
        } else if (webpSupport === true && closestWidth !== null && baseName !== null && extension !== null) {
            content = <img
                src={API_IMAGES_URL + baseName + '.webp'}
                width={width === null ? dimensions.width : width}
                height={height !== null ? height : 'auto'}
                className={img_class_name}
                alt={img_alt}
            />
        } else if (webpSupport === false && closestWidth !== null && baseName !== null && extension !== null) {
            content = <img
                src={API_IMAGES_URL + baseName + '.' + extension}
                width={width === null ? dimensions.width : width}
                height={height !== null ? height : 'auto'}
                className={img_class_name}
                alt={img_alt}
            />
        } else {
            content = <Skeleton.Image active={true}
                style={{
                    width: width === null ? dimensions.width : width, height: height !== null ? height : 'auto',

                }}
                // width={width === null ? dimensions.width : width}
                // height={height !== null ? height : 'auto'}
                className={img_class_name}
            />
        }
    }

    return (
        <div ref={refContainer}>
            {content}
        </div>
    );

};

export default PhotoDisplayHandler;
