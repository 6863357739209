import { Form, Formik } from "formik";
// import { withFormikDevtools } from "formik-devtools-extension";
import { object, ref, string } from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { InputField } from "../../../components";
import LinkLanguage from "../../../locales/LinkLanguage";
import { register } from "../../../store/authentication/authentication.slice";

import "../../../components/form/FormikForm.scss";


const Registration = () => {
  const { t } = useTranslation("registration", { useSuspense: false });
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const RegistrationValidation = object().shape({
    user_firstname: string().required(t("unesite_ime")),
    user_lastname: string().required(t("unesite_prezime")),
    email: string()
      .required(t("unesite_email"))
      .email(t("email_mora_biti_validan")),
    password: string()
      .min(8, t("najmanje_8_cifara"))
      .required(t("unesite_lozinku")),
    password_confirm: string()
      .label(t("potvrdite_lozinku"))
      .required(t("potvrdite_lozinku"))
      .oneOf([ref("password"), ""], t("lozinke_se_moraju_poklapati")),
  });

  const handleRegistration = (values: any, { setSubmitting }: any) => {
    // console.log(values);
    dispatch(register(values)).then(() => {
      setSubmitting(false);
      navigate("/login");
    });
  };

  return (
    <div className="Form-Box">
      <div className="Form">
        <h1 className="Form-Title">{t("registruj_se")}</h1>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={RegistrationValidation}
          onSubmit={handleRegistration}
        >
          {(
            //   formikProps.values,
            //   formikProps.errors,
            //   formikProps.touched,
            //   formikProps.handleChange,
            //   formikProps.handleBlur,
            //   formikProps.handleSubmit,
            // formikProps.isSubmitting,
            formikProps
          ) => (
            // withFormikDevtools(
            //   formikProps,
            <Form className="Formik-Form">
              <InputField
                name="user_firstname"
                label={t("ime")}
                required={true}
              />
              <InputField
                name="user_lastname"
                label={t("prezime")}
                required={true}
              />
              <InputField name="email" label={t("email")} required={true} />
              <InputField
                name="password"
                label={t("lozinka")}
                type="password"
                required={true}
              />
              <InputField
                name="password_confirm"
                label={t("ponovljena_lozinka")}
                type="password"
                required={true}
              />
              {/* <CheckBoxField
                name="saveData"
                label="Zapamti podatke za prijavu"
                type="checkbox"
              /> */}
              <button
                type="submit"
                disabled={formikProps.isSubmitting}
                className="Form-Button"
              >
                {t("potvrdi")}
              </button>
              <label className="Form-Label">
                {t("imate_nalog")}&nbsp;
                <LinkLanguage to="/login">{t("prijavi_se")} </LinkLanguage>
              </label>
            </Form>
            // )
          )}
        </Formik>
      </div>
    </div>
  );
};
export default Registration;
