import { useTranslation } from "react-i18next";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import "./SearchForm.scss";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchTerm } from "../../../store/search/search.slice";
import SearchItem from "./SearchItem";
import { useEffectDeviceAndOrientation } from "../../../utils/hooks";

const SearchForm = () => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const [device, orientation] = useEffectDeviceAndOrientation();

  const [searchInput, setSearchInput] = useState("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const { is_loading, error, data } = useSelector((store: any) => store.search);

  const onSearchClick = () => {
    console.log("nesto")
    dispatch(searchTerm(searchInput));
    setShowDropdown(true);
  };
  const hideDropdown = (e: any) => {
    setShowDropdown(false);
    setSearchInput("");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const searchRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchRef.current &&
      !searchRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
      setSearchInput("");

    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="Search-Box">
      <div className="Search-Container" ref={searchRef}>
        <form className="Search-Form">
          <input
            className="Search-Form-Input"
            type="search"
            value={searchInput}
            onChange={handleInputChange}
            placeholder={t("pretrazite_proizvode") || ""}
            aria-label={t("pretrazite_proizvode") || ""}
            onKeyDown={onSearchClick}
          />
          <button
            className="Search-Form-Button"
            type="button"
            onClick={onSearchClick}
          >
            <SearchOutlined className="Search-Form-Button-Icon" />
          </button>
        </form>

        {showDropdown && (
          <div
            className="Search-Dropdown-Content" 
            style={
              device === "mobile" || orientation === "portrait"
                ? { width: "100%" }
                : { width: "120%" }
            }
          >
            {is_loading ? (
              <LoadingOutlined />
            ) : (
              <div>
                <div className="Search-Dropdown-Title">Proizvodi</div>
                <div className="Search-Dropdown-Item">
                  {data.articles.length === 0 ? (
                    <div>Nisu pronađeni proizvodi</div>
                  ) : (
                    <div className="Search-Dropdown-Item-Box">
                      {data.articles.map((itemData: any, key: any) => (
                        <SearchItem
                          data={itemData}
                          key={key}
                          hideDropdown={hideDropdown}
                          isCategory={false}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <div className="Search-Dropdown-Title">Kategorije</div>
                <div className="Search-Dropdown-Item">
                  {data.article_categories.length === 0 ? (
                    <div>Nisu pronađene kategorije</div>
                  ) : (
                    <div className="Search-Dropdown-Item-Box">
                      {data.article_categories.map(
                        (itemData: any, key: any) => (
                          <SearchItem
                            data={itemData}
                            key={key}
                            hideDropdown={hideDropdown}
                            isCategory={true}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default SearchForm;
