import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-chained-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend";
import XHRBackEnd from "i18next-xhr-backend";

export const isDevelopmentEnv = process.env.NODE_ENV === "development";

export const backEnds = isDevelopmentEnv
  ? [XHRBackEnd]
  : [LocalStorageBackend, XHRBackEnd];

export const loadPath = () => "/locales/{{lng}}/{{ns}}.json";

export const backEndOptions = isDevelopmentEnv
  ? [{ loadPath }]
  : [
    { prefix: "artwork_locale_", versions: { sr: "v1.7", en: "v1.7" } },
    { loadPath }
  ];

if (isDevelopmentEnv) {
  window.i18n = i18n;
}

i18n
  .use(Backend)
  // .use(LanguageDetector) // Automatically detecting the user’s language
  .use(initReactI18next)
  .init({
    ns: ["dashboard", "home", "header", "login", "registration", "order", "breadcrumbs"],
    defaultNS: "dashboard",
    lng: 'sr', // Default language
    languages: ["sr", "en"],
    fallbackLng: "sr",
    follbackNS: true,
    // nonExplicitSupportedLngs: true, 
    debug: false, //ako zelim da mi ispisuje onda stavim
    load: "languageOnly",
    returnObjects: true,
    joinArrays: true,
    cleanCode: true,
    interpolation: { 
      escapeValue: false, // React already safes from xss
      formatSeparator: "," 
    },
    missingKeyHandler: true, 
    react: {
      wait: true,
      transEmptyNodeValue: '',
      // transSupportBasicHtmlNodes: true,
      useSuspense: true
    },  
    backend: {
      backends: backEnds,
      backendOptions: backEndOptions
    }
  });

    // Allows "en-US" and "en-UK" to be implcitly supported when "en"
    // is supported
    // nonExplicitSupportedLngs: true,  

export default i18n;
