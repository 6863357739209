import { toast, ToastOptions, TypeOptions } from "react-toastify";
import "./ToastHelper.scss";

// Definisanje tipa za opcione parametre
// interface CustomToastOptions extends ToastOptions {
//   type?: TypeOptions;
// }

// Generička funkcija za prikazivanje toast poruka
export const showToast = (message: string, type: string) => {

  
  let options = {
    // position: toast.POSITION.BOTTOM_RIGHT,
    // autoClose: 5000,
    // hideProgressBar: false,
    // closeOnClick: true,
    // pauseOnHover: true,
    // draggable: true,
    // className: "Generic-Toast",
  };
  if (type === "warn") {
    toast.warn(message, options);
  } else if (type === "error") {
    toast.error(message, options);
  } else if (type === "info") {
    toast.info(message, options);
  } else if (type === "success") {
    toast.success(message, options);
  }
};
