import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Steps } from "antd";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { BiErrorAlt } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { LoadingOutlined } from "@ant-design/icons";

import { BreadcrumbLink } from "../../../components";
import { useEffectDeviceAndOrientation } from "../../../utils/hooks";
import { newOrder, updateOrder } from "../../../store/order/order.slice";
import { resetCart } from "../../../store/cart/cart.slice";
import { goToTop } from "../../../utils/helpers/screenOperations";
import OrderPageStep1 from "./OrderPageStep1";
import OrderPageStep2 from "./OrderPageStep2";
import OrderPageStep3 from "./OrderPageStep3";
import OrderPageStep4 from "./OrderPageStep4";
import {
  OrderDataType,
  OrderDataValidType,
} from "../../../types/order/OrderDataType";

import "./OrderPage.scss";
import "./../../../components/form/FormikForm.scss";

const { Step } = Steps;

const stepTitle = [
  <label className="Order-Step-Title">Podaci kupca</label>,
  <label className="Order-Step-Title">Način i adresa isporuke</label>,
  <label className="Order-Step-Title">Plaćanje</label>,
  <label className="Order-Step-Title">Pregled narudžbine</label>,
];

const OrderPage = () => {
  const { t } = useTranslation("order", { useSuspense: false });

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [device, orientation] = useEffectDeviceAndOrientation();

  const cart = useSelector((store: any) => store.cart);
  const order = useSelector((store: any) => store.order);
  // console.log(order)

  const [current, setCurrent] = useState(0);
  const [formValid, setFormValid] = useState<OrderDataValidType>({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });
  const [allFormValid, setAllFormValid] = useState(false);
  const updateFormValid = (
    step: keyof OrderDataValidType,
    isValid: boolean
  ) => {
    setFormValid((prevState) => ({
      ...prevState,
      [step]: isValid,
    }));
  };
  // menja trenutni step
  const onChange = (newCurrent: number) => {
    setCurrent(newCurrent);
  };
  // yup objekat za validaciju 1. stepa
  const step1OrderValidationObject = Yup.object({
    first_name: Yup.string().required(t("ime_required")),
    last_name: Yup.string().required(t("prezime_required")),
    telephone: Yup.string().required(t("telefon_required")),
    // email: Yup.string().email(t("email_valid")).required(t("email_required")),
    // person: Yup.string()
    //   .required(t("da_li_ste_pravno_lice_required"))
    //   .oneOf(["fizicko_lice", "pravno_lice"], t("nevazeca_opcija")),
    company_name: Yup.string().when("person", (r, schema) =>
      r.toString() === "pravno_lice"
        ? schema.required(t("naziv_kompanije_required"))
        : schema.notRequired()
    ),
  });
  // yup objekat za validaciju 2. stepa
  const step2OrderValidationObject = Yup.object({
    // receiving_way: Yup.string()
    //   .required(t("nacin_dostave_required"))
    //   .oneOf(["na_kucnu_adresu", "na_adresu_firme"], t("nevazeca_opcija")),
    state: Yup.string().when("receiving_way", (r, schema) =>
      r.toString() === "na_kucnu_adresu"
        ? schema.required(t("drzava_required"))
        : schema.notRequired()
    ),
    city: Yup.string().when("receiving_way", (r, schema) =>
      r.toString() === "na_kucnu_adresu"
        ? schema.required(t("grad_required"))
        : schema.notRequired()
    ),
    zip: Yup.string().when("receiving_way", (r, schema) =>
      r.toString() === "na_kucnu_adresu"
        ? schema.required(t("postanski_broj_required"))
        : schema.notRequired()
    ),
    address: Yup.string().when("receiving_way", (r, schema) =>
      r.toString() === "na_kucnu_adresu"
        ? schema.required(t("ulica_required"))
        : schema.notRequired()
    ),
  });
  // yup objekat za validaciju 3. stepa
  const step3OrderValidationObject = Yup.object({
    // method_of_payment: Yup.string()
    //   .required(t("nacin_placanja_required"))
    //   .oneOf(
    //     ["placanje_prilikom_preuzimanja", "placanje_karticom"],
    //     t("nevazeca_opcija")
    //   ),
  });
  // yup objekat za validaciju 4. stepa
  const step4OrderValidationObject = Yup.object({
    agreement: Yup.boolean().oneOf([true], t("saglasnost_required")),
  });
  // validaciona schema za step 1
  const step1ValidationSchema = Yup.object().shape({
    step1: step1OrderValidationObject,
  });
  // validaciona schema za step 2
  const step2ValidationSchema = Yup.object().shape({
    step2: step2OrderValidationObject,
  });
  // validaciona schema za step 3
  const step3ValidationSchema = Yup.object().shape({
    step3: step3OrderValidationObject,
  });
  // validaciona schema za step 4
  const step4ValidationSchema = Yup.object().shape({
    step4: step4OrderValidationObject,
  });
  // validaciona schema za citavu formu
  const allValidationSchema = Yup.object().shape({
    step1: step1OrderValidationObject,
    step2: step2OrderValidationObject,
    step3: step3OrderValidationObject,
    step4: step4OrderValidationObject,
  });
  // validaciona schema u odnosu na step
  const currentValidationSchema = () => {
    // console.log("validation schema je " + current);
    switch (current) {
      case 0:
        return step1ValidationSchema;
      case 1:
        return step2ValidationSchema;
      case 2:
        return step3ValidationSchema;
      case 3:
        return step4ValidationSchema;
      case 4:
        return allValidationSchema;
      default:
        return undefined;
    }
  };
  // submit forme kada se klikne a kada je sve validno
  const onSubmit = async (values: OrderDataType, setSubmitting: any) => {
    // console.log("submit ");

    switch (current) {
      case 0:
        dispatch(
          updateOrder({ step: "step1", value: values.step1, isValid: true })
        );
        updateFormValid("step1", true);
        setCurrent(current + 1);
        break;
      case 1:
        dispatch(
          updateOrder({ step: "step2", value: values.step2, isValid: true })
        );
        updateFormValid("step2", true);
        setCurrent(current + 1);
        break;
      case 2:
        dispatch(
          updateOrder({ step: "step3", value: values.step3, isValid: true })
        );
        updateFormValid("step3", true);
        setCurrent(current + 1);
        break;
      case 3:
        dispatch(
          updateOrder({ step: "step4", value: values.step4, isValid: true })
        );
        updateFormValid("step4", true);
        if (formValid.step1 === false) {
          setCurrent(0);
        } else if (formValid.step2 === false) {
          setCurrent(1);
        } else if (formValid.step3 === false) {
          setCurrent(2);
        } else {
          setCurrent(current + 1);
        }
        goToTop();
        break;
      case 4:
        if (
          formValid.step1 &&
          formValid.step2 &&
          formValid.step3 &&
          formValid.step4
        ) {
          // console.log("VALIDNA FORMA");
          dispatch(newOrder({ order_data: values, cart_data: cart })).then(
            (rez: any) => {
              // console.log(rez.type);
              setAllFormValid(true);
              setSubmitting(false);
              if (rez.type === "order/newOrder/fulfilled") {
                setTimeout(() => {
                  navigate("/");
                }, 2000);
              } else if (rez.type === "order/newOrder/rejected") {
                setTimeout(() => {
                  navigate("/cart");
                }, 2000);
              }
            }
          );
          dispatch(resetCart());
        } else {
          // console.log("nije validna forma");
          setAllFormValid(false);
          if (formValid.step1 === false) {
            setCurrent(0);
          } else if (formValid.step2 === false) {
            setCurrent(1);
          } else if (formValid.step3 === false) {
            setCurrent(2);
          } else if (formValid.step4 === false) {
            setCurrent(3);
          }
          goToTop();
        }
        break;
      default:
        setAllFormValid(false);
        console.error("Forma nije validna u nekom od koraka.");
    }
  };
  const stepValidation = (current: number) => {
    switch (current) {
      case 0:
        setFormValid({
          step1: false,
          step2: false,
          step3: false,
          step4: false,
        });
        break;
      case 1:
        setFormValid((prevState) => ({
          ...prevState,
          step2: false,
          step3: false,
          step4: false,
        }));
        break;
      case 2:
        setFormValid((prevState) => ({
          ...prevState,
          step3: false,
          step4: false,
        }));
        break;
      case 3:
        updateFormValid("step4", false);
        break;
      default:
        return undefined;
    }
  };
  // funkcija za postavljanje vrednosti na chechbox polja
  // setFieldValue - iz formika dolazi
  const handleCheckboxChange = (event: any, setFieldValue: any) => {
    const { name, checked } = event.target;
    // console.log("Checkbox checked:", checked); // Custom logic: log the checkbox state
    setFieldValue(name, checked);
    stepValidation(current);
  };
  // funkcija za postavljanje vrednosti na obicna polja
  const handleCustomChange = (event: any, setFieldValue: any) => {
    const { name, value } = event.target;
    // console.log("Nova vrednost za", name, "je", value);
    setFieldValue(name, value);
    stepValidation(current);
  };

  const getBreadcrumbItems = () => {
    let list = [
      {
        title: (
          <BreadcrumbLink
            label={t("pocetna")}
            path={"/"}
            title={t("pocetna")}
          />
        ),
      },
      {
        title: (
          <BreadcrumbLink
            label={t("korpa")}
            path={"/cart"}
            title={t("korpa")}
          />
        ),
      },
    ];
    return list;
  };

  const getDeliveryPrice = (orderType: string) => {
    if (orderType === "na_kucnu_adresu") {
      if (cart.final_price >= 200) {
        return 0;
      } else {
        return 8;
      }
    } else {
      return 0;
    }
  };
  return (
    <div className="Page">
      {allFormValid === false ? (
        <div className="Page-Content">
          <Breadcrumb items={getBreadcrumbItems()} />
          <h1 className="Page-Title">Završite kupovinu</h1>
          <Formik
            initialValues={order.data}
            validationSchema={currentValidationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values, { setSubmitting }) =>
              onSubmit(values, setSubmitting)
            }
          >
            {({
              errors,
              touched,
              values,
              isSubmitting,
              isValidating,
              isValid,
              setFieldValue,
              validateField,
            }) => (
              <Form className="Order-Form">
                <div className="Order-Steps">
                  <Steps
                    current={current}
                    onChange={onChange}
                    direction="vertical"
                    style={{ marginTop: "1%" }}
                  >
                    <Step
                      key={0}
                      title={stepTitle[0]}
                      description={
                        <div
                          style={{
                            display: current === 0 ? "" : "none",
                          }}
                          className="Step-Content"
                        >
                          <OrderPageStep1
                            errors={errors}
                            touched={touched}
                            values={values}
                            isSubmitting={isSubmitting}
                            setFieldValue={setFieldValue}
                            validateField={validateField}
                            handleCustomChange={handleCustomChange}
                            formValid={formValid}
                          />
                        </div>
                      }
                    />
                    <Step
                      key={1}
                      title={stepTitle[1]}
                      description={
                        <div
                          style={{
                            display: current === 1 ? "" : "none",
                          }}
                          className="Step-Content"
                        >
                          <OrderPageStep2
                            errors={errors}
                            touched={touched}
                            values={values}
                            isSubmitting={isSubmitting}
                            setFieldValue={setFieldValue}
                            validateField={validateField}
                            handleCustomChange={handleCustomChange}
                            formValid={formValid}
                          />
                        </div>
                      }
                    />
                    <Step
                      key={2}
                      title={stepTitle[2]}
                      description={
                        <div
                          style={{
                            display: current === 2 ? "" : "none",
                          }}
                          className="Step-Content"
                        >
                          <OrderPageStep3
                            errors={errors}
                            touched={touched}
                            values={values}
                            isSubmitting={isSubmitting}
                            setFieldValue={setFieldValue}
                            validateField={validateField}
                            handleCustomChange={handleCustomChange}
                            formValid={formValid}
                          />
                        </div>
                      }
                    />
                    <Step
                      key={3}
                      title={stepTitle[3]}
                      description={
                        <div
                          style={{
                            display: current === 3 ? "" : "none",
                          }}
                          className="Step-Content"
                        >
                          <OrderPageStep4
                            errors={errors}
                            touched={touched}
                            values={values}
                            isSubmitting={isSubmitting}
                            setFieldValue={setFieldValue}
                            validateField={validateField}
                            handleCustomChange={handleCustomChange}
                            formValid={formValid}
                            updateFormValid={updateFormValid}
                            handleCheckboxChange={handleCheckboxChange}
                          />
                        </div>
                      }
                    />
                  </Steps>
                </div>
                {/* TODO moze da se napravi zajednicka komponenta za cart i za order */}
                <div className="Order-Info">
                  <h2 className="Cart-Subtitle">Pregled narudžbine</h2>
                  <div className="Cart-Item">
                    <label className="Cart-Label">Cijena za plaćanje:</label>
                    <label className="Cart-Label">
                      {cart.total_price.toFixed(2)}&nbsp;KM
                    </label>
                  </div>
                  <div className="Cart-Item">
                    <label className="Cart-Label">Popust:</label>
                    <label className="Cart-Label">
                      {cart.total_discount}&nbsp;%
                    </label>
                  </div>
                  <div className="Cart-Item">
                    <label className="Cart-Label">Cijena sa popustom:</label>
                    <label className="Cart-Label">
                      {cart.final_price.toFixed(2)}&nbsp;KM
                    </label>
                  </div>
                  <div className="Cart-Item">
                    <label className="Cart-Label">Poštarina:</label>
                    <label className="Cart-Label">
                      {getDeliveryPrice(values.step2.receiving_way).toFixed(2)}
                      &nbsp;KM
                    </label>
                  </div>
                  <div className="Page-Line"></div>
                  <div className="Cart-Item Bold">
                    <label className="Cart-Label">Iznos kupovine:</label>
                    <label className="Cart-Label ">
                      {(
                        cart.final_price +
                        getDeliveryPrice(values.step2.receiving_way)
                      ).toFixed(2)}
                      &nbsp;KM
                    </label>
                  </div>
                  <div className="Order-Button-Div">
                    <button
                      type="submit"
                      className="Order-Button2"
                      disabled={
                        !formValid.step1 ||
                        !formValid.step2 ||
                        !formValid.step3 ||
                        !formValid.step4
                      }
                    >
                      Poruči
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : order.is_loading ? (
        <LoadingOutlined />
      ) : (
        <>
          {order.error ? (
            <div className="Page-Content Success-Order">
              <BiErrorAlt
                style={{
                  fontSize: "150px",
                  color: "red",
                  marginBottom: "1%",
                }}
              />
              <h2>Vaša porudžbina nije kreirana. Pokusajte ponovo!</h2>
            </div>
          ) : (
            <div className="Page-Content Success-Order">
              <FaRegCircleCheck
                style={{
                  fontSize: "150px",
                  color: "green",
                  marginBottom: "1%",
                }}
              />
              <h2>Vaša porudžbina je uspešno kreirana!</h2>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default OrderPage;
