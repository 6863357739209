import Gallery from "react-image-gallery";
import "./PhotoGallery.scss";
import { getUrlFromPhoto } from "../../utils/helpers/photo";
import { useEffect, useRef, useState } from "react";

// DOCS https://github.com/xiaolin/react-image-gallery
const PhotoGallery = ({ images, type }: any) => {
  const refContainer = useRef(null);
  const [closestWidth, setClosestWidth] = useState(0);

  useEffect(() => {
    
    const sizes = [
      1920, 1600, 1440, 1366, 1200, 1024, 960, 800, 768, 640, 533, 455, 320,
      160,
    ];

    if (refContainer.current) {
      let width = refContainer.current["clientWidth"];
      let height = refContainer.current["clientHeight"];
      // console.log("sirina : " + width + " visina " + height);
      const closest = sizes.reduce((prev, curr) => {
        return Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev;
      });
      // console.log(closest);
      setClosestWidth(closest);
    }
  }, []);

  const getImages = () => {
    let list: any[] = [];
    images.forEach((image: any) => {
      list.push({
        original: getUrlFromPhoto(image, true, closestWidth, false),
        thumbnail: getUrlFromPhoto(image, true, closestWidth, true),
        originalAlt: image,
      });
    });
    // console.log(list);
    return list;
  };

  return (
    <div
      className={
        type === "blog" ? "Photo-Gallery-Blog" : "Photo-Gallery-Article"
      }
      ref={refContainer}
    >
      <Gallery items={getImages()} />
    </div>
  );
};
export default PhotoGallery;
