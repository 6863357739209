import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FormStep, OrderDataType } from "../../types/order/OrderDataType";
import { newOrderThunk } from "./order.actions";
import { showToast } from "../../utils/helpers/toastHelper";
import { resetCart } from "../cart/cart.slice";

export const orderDataInitialState: OrderDataType = {
  step1: {
    first_name: "",
    last_name: "",
    telephone: "",
    email: "",
    person: "fizicko_lice",
    company_name: undefined,
    is_valid: false,
  },
  step2: {
    receiving_way: "na_kucnu_adresu",
    store: "",
    state: "Bosna i Hercegovina",
    city: "",
    zip: "",
    address: "",
    address_addition: "",
    is_valid: false,
  },
  step3: {
    method_of_payment: "placanje_prilikom_preuzimanja",
    is_valid: false,
  },
  step4: {
    description: "",
    agreement: false,
    is_valid: false,
  },
};
export const orderInitialState = {
  is_loading: false,
  error: false,
  data: orderDataInitialState,
};
export const newOrder = createAsyncThunk("order/newOrder", newOrderThunk);

const OrderSlice = createSlice({
  name: "order",
  initialState: orderInitialState,
  reducers: {
    updateOrder: (
      state,
      {
        payload,
      }: {
        payload: {
          step: FormStep;
          value: Partial<OrderDataType[FormStep]>;
          isValid: boolean;
        };
      }
    ) => {
      // console.log(payload);
      const { step, value } = payload;
      if (state.data[step]) {
        state.data = {
          ...state.data,
          [step]: { ...state.data[step], ...value, is_valid: payload.isValid },
        };
      }
    },
    resetOrder: (state) => {
      state.data = orderDataInitialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newOrder.fulfilled, (state, { payload }) => {
        // console.log("uspesno")
        // resetOrder();
        state.data = orderDataInitialState;
        // resetCart();
        // todo dodati api 
        showToast(`Uspiješno ste poručili`, "success");

      })
      .addCase(newOrder.pending, (state) => {
        
        // console.log("pending")
        return { ...state, is_loading: true };

      })
      .addCase(newOrder.rejected, (state) => {
        showToast("Greska prilikom kreiranja narudzbine! Pokušajte ponovo.", "error");
        // return { ...state, is_loading: false, user_details: userDetails };
        // console.log("odbijeno")
        return { ...state, error: true, is_loading: false };
      });
  },
});

export const { updateOrder, resetOrder } = OrderSlice.actions;
export default OrderSlice.reducer;
