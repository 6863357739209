import { useTranslation } from "react-i18next";
import "../InfoPages.scss";

const PrivatePolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="Page">
      <div className="Page-Content Contact">
        <h1 className="Page-Title">{t("politika_privatnosti")}</h1>

        <h2 className="Page-Subtitle">Uvod</h2>
        <p className="Paragraf">
          Da bismo mogli realizovati Vašu narudžbu potrebni su nam sljedeći
          podaci: ime i prezime, adresa, email, broj telefona. Ove podatke
          online servis <em>www.infocomp.ba</em> štiti i spriječava njihovu
          dostupnost trećim licima. Podaci koji su unešeni prilikom registracije
          ili narudžbe, naš servis se obavezuje da neće prodavati,
          prosljeđivati, iznajmljivati ili na bilo koji drugi način prenositi
          trećim licima.
        </p>
        <p className="Paragraf">
          Pored ovog, osoblje našeg servisa je dužno redovno sprovoditi kontrole
          i pregled sadržaja, te pronalazak eventualnih neispravnosti i
          otklanjanje istih, kao i zaštitu od hakerskih upada i drugih pokušaja
          pristupa/korišćenja korisničkog sadržaja. Kao korisnik našeg online
          servisa, možete zahtjevati uklanjanje vaših podataka iz naše baze
          nakon realizacije narudžbe.
        </p>

        <h2 className="Page-Subtitle">Diskretnost podataka</h2>
        <div className="Paragraf-List">
          <p className="Paragraf">
            Prikupljamo samo neophodne, osnovne podatke o kupcima/korisnicima i
            podatke neophodne za poslovanje i informisanje korisnika u skladu sa
            dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge. Naš
            servis se obavezuje na korektno i diskretno čuvanje podataka na
            serveru, ali ne može garantovati:
          </p>
          <ul>
            <li>
              da će treća lica uvijek postupati prema Uslovima korišćenja,
            </li>
            <li>
              da eventualni vidljivi sadržaj neće biti kopiran ili prosleđivan
              od strane trećih lica, i ostalih nepoželjnih, ali mogućih
              scenarija nezaobilaznih u digitalnom okruženju.
            </li>
          </ul>
        </div>

        <h2 className="Page-Subtitle">Integritet podataka</h2>
        <p className="Paragraf">
          E-prodavnica <em>www.infocomp.ba</em> je dužna obezbijediti integritet
          sadržaja proslijeđenog od strane korisnika na najvišem mogućem nivou.
          Ovo podrazumijeva spriječavanje nedozvoljenih izmjena, dodavanja,
          brisanja, dupliranja, te krađe i sličnih aktivnosti. Ovo se ne odnosi
          na podatke koji imaju netačan, uvrijedljiv, zlonamjeran, eksplicitan i
          drugi neprihvatljiv sadržaj, koji će prema Uslovima korišćenja biti
          otklonjen od strane osoblja naše e-prodavnice.
        </p>
        <br/>
      </div>
    </div>
  );
};
export default PrivatePolicy;
