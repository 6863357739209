import { createSlice } from "@reduxjs/toolkit";
import banner3 from "../../assets/Baners/map-8.svg"
import banner2 from "../../assets/Baners/credit-card.svg"
import banner1 from "../../assets/Baners/printer.svg"


// TODO: POPRILICNO ZAKUCANO. POTREBNO POZVATI API
// treba dodati putanje do banera ako moze da se klikne na njih?
const initialState = {
    list: [
        {
            title: "Ketridži i toneri",
            description: "Generalni distributer za BiH i Srbiju",
            picture: banner1
        },
        {
            title: "MI IMAMO RATU ZA SVAKU PLATU",
            description: "0% kamata, 0% obrade kredita, 0% ostali troškovi",
            picture: banner2
        },
        {
            title: "27. MART br. 13 BIJELJINA 76300",
            description: "Posjetite nas u našem maloprodajnom objektu",
            picture: banner3
        },
    ]
}

const BanersSlice = createSlice({
    name: "baners",
    initialState,
    reducers: {
        clearValues: (state, { payload }) => {
            state.baners = initialState;
        }
    },
    extraReducers: (builder) => {
    }

});


export const { clearValues } = BanersSlice.actions;
export default BanersSlice.reducer;

