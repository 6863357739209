import { useTranslation } from "react-i18next";

import LinkLanguage from "../../locales/LinkLanguage";

import "../dashboard/Dashboard.scss";

const ErrorPage = () => {
  const { t } = useTranslation();
  // const [device, orientation] = useEffectDeviceAndOrientation();
  return (
    <div className="Error-Page">
      <div>
        <h1>{t("pogresna_stranica")}</h1>
        <LinkLanguage to="/login">{t("vrati_se_na_pocetnu")}</LinkLanguage>
      </div>
    </div>
  );
};
export default ErrorPage;
