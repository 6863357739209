
import "./BanerCard.scss";

const BanerCard = ({ title, description, picture, device, orientation }: any) => {
  return (
    <div className="Baner-Card">
      <div className="Baner-Image">
        {/*  TODO : Dodati PhotoDisplayHandler, ali kada se baneri srede na backendu */}
        <img
          className="Baner-Image-Img"
          alt={title}
          src={picture}
          width="100%"
          height="100%"
        />
      </div>
      <div className="Baner-Text">
        <div className="Baner-Text-Title">{title}</div>
        <div className="Baner-Text-Description">{description}</div>
      </div>
    </div>
  );
};

export default BanerCard;
