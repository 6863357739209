import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchFilteredArticlesThunk,
  fetchFiltersListThunk,
  fetchRecentArticlesThunk,
} from "./articles.actions";

export const fetchRecentArticles = createAsyncThunk(
  "sliders/fetchRecentArticles",
  fetchRecentArticlesThunk
);
export const fetchFilteredArticles = createAsyncThunk(
  "sliders/fetchFilteredArticles",
  fetchFilteredArticlesThunk
);
export const fetchFiltersList = createAsyncThunk(
  "sliders/fetchFiltersList",
  fetchFiltersListThunk
);

const metaFilter = {
  page: 1,
  take: 12,
  item_count: 0,
  page_count: 1,
  has_previous_page: false,
  has_next_page: false,
};
const initialState = {
  recent_articles: {
    list: [],
    is_fetch: false,
  },
  filtered_articles: {
    list: [],
    meta: metaFilter,
    is_fetch: false,
  },
  filters_list: {
    data: [
      {
        filter_key: "Cijena",
        filter_active: true,
        filter_label: "Cijena",
        filter_type: "scroll",
        filter_children: [
          {
            value_key: "Cijena_Min",
            value_label: "Min",
            value_active: true,
            value_number: 0,
          },
          {
            value_key: "Cijena_Max",
            value_label: "Max",
            value_active: true,
            value_number: 20000,
          },
        ],
        //sa filterima vratiti i min i max cenu
      },
    ],
    is_fetch: false,
  },
};

const ArticlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    clearValues: (state, { payload }) => {
      state.articles = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentArticles.fulfilled, (state, { payload }) => {
        return {
          ...state,
          recent_articles: {
            list: payload,
            is_fetch: true,
          },
        };
      })
      .addCase(fetchRecentArticles.pending, (state) => {
        return {
          ...state,
          recent_articles: {
            is_fetch: false,
            list: [],
          },
        };
      })
      .addCase(fetchRecentArticles.rejected, (state) => {
        return {
          ...state,
          recent_articles: {
            is_fetch: false,
            list: [],
          },
        };
      })
      .addCase(fetchFilteredArticles.fulfilled, (state, { payload }) => {
        return {
          ...state,
          filtered_articles: {
            list: payload.data,
            meta: payload.meta,
            is_fetch: true,
          },
        };
      })
      .addCase(fetchFilteredArticles.pending, (state) => {
        return {
          ...state,
          filtered_articles: {
            is_fetch: false,
            meta: metaFilter,
            list: [],
          },
        };
      })
      .addCase(fetchFilteredArticles.rejected, (state) => {
        return {
          ...state,
          filtered_articles: {
            is_fetch: false,
            list: [],
            meta: metaFilter,
          },
        };
      })
      .addCase(fetchFiltersList.fulfilled, (state, { payload }) => {
        let list = [];
        payload.data.forEach((filter) => {
          let values = [];
          let isActive = false;
          filter.filter_children.forEach((value) => {
            let existsInUrlParams = payload.url_filters.some(
              (urlParam) =>
                (urlParam.filter_values === value.value_label &&
                  urlParam.filter_label === filter.filter_label) ||
                urlParam.filter_label === value.value_key
            );
            if (existsInUrlParams) {
              isActive = true;
            }
            values.push({
              value_key: value.value_key,
              value_label: value.value_label,
              value_active: existsInUrlParams ? true : false, // Ako postoji, označavamo value_active kao true
              value_number: value.value_number,
            });
          });
          list.push({
            filter_key: filter.filter_key,
            filter_active: isActive,
            filter_label: filter.filter_label,
            filter_type: filter.filter_type,
            filter_children: values,
          });
        });

        return {
          ...state,
          filters_list: {
            data: list,
            is_fetch: true,
          },
        };
      })
      .addCase(fetchFiltersList.pending, (state) => {
        return {
          ...state,
          filters_list: {
            data: [],
            is_fetch: false,
          },
        };
      })
      .addCase(fetchFiltersList.rejected, (state) => {
        return {
          ...state,
          filters_list: {
            data: [],
            is_fetch: false,
          },
        };
      });
  },
});

export const { clearValues } = ArticlesSlice.actions;
export default ArticlesSlice.reducer;
