import { useTranslation } from "react-i18next";
import { CheckBoxField, InputField } from "../../../components";
import { useEffectDeviceAndOrientation } from "../../../utils/hooks";
import { Field } from "formik";

const OrderPageStep1 = ({
  errors,
  touched,
  values,
  isSubmitting,
  formValid,
  setFieldValue,
  validateField,
  handleCustomChange,
}: any) => {
  const [device, orientation] = useEffectDeviceAndOrientation();
  const { t } = useTranslation("order", { useSuspense: false });

  // const validateStep1 = () => {
  //   validateField("step1.first_name");
  //   validateField("step1.last_name");
  //   validateField("step1.telephone");
  //   validateField("step1.email");
  //   validateField("step1.person");
  //   if (values.step1.person === "pravno_lice") {
  //     validateField("step1.company_name");
  //   }
  // };

  return (
    <div className="Form-Box">
      <div className="Form2">
        <div
          className={
            (device === "mobile" && orientation === "portrait") ||
            orientation === "portrait"
              ? "Formik-Group-Mobile"
              : "Formik-Group"
          }
        >
          <div>
            <InputField
              name="step1.first_name"
              label={t("ime")}
              required={true}
              onChange={(event: any) => {
                handleCustomChange(event, setFieldValue);
              }}
            />
          </div>
          <div>
            <InputField
              name="step1.last_name"
              label={t("prezime")}
              required={true}
              onChange={(event: any) =>
                handleCustomChange(event, setFieldValue)
              }
            />
          </div>
        </div>
        <div
          className={
            (device === "mobile" && orientation === "portrait") ||
            orientation === "portrait"
              ? "Formik-Group-Mobile"
              : "Formik-Group"
          }
        >
          <div>
            <InputField
              name="step1.telephone"
              label={t("telefon")}
              required={true}
              onChange={(event: any) =>
                handleCustomChange(event, setFieldValue)
              }
            />
          </div>
          <div>
            <InputField
              name="step1.email"
              label={t("email")}
              required={false}
              onChange={(event: any) =>
                handleCustomChange(event, setFieldValue)
              }
            />
          </div>
        </div>
        <div
          className={
            (device === "mobile" && orientation === "portrait") ||
            orientation === "portrait"
              ? "Formik-Group-Mobile"
              : "Formik-Group"
          }
        >
          <div className="Select-Field">
            <label className="Select-Field-Label" htmlFor={"step1.person"}>
              {t("da_li_ste_pravno_lice")} *
            </label>
            <Field
              className="Select-Field-Selector"
              as="select"
              name="step1.person"
              id="step1.person"
              onChange={(event: any) =>
                handleCustomChange(event, setFieldValue)
              }
            >
              <option
                className="Select-Option"
                value="fizicko_lice"
                key="fizicko_lice"
              >
                {t("fizicko_lice")}
              </option>
              <option value="pravno_lice" key="pravno_lice">
                {t("pravno_lice")}
              </option>
            </Field>
          </div>
          <div>
            {values.step1.person === "pravno_lice" && (
              <InputField
                name="step1.company_name"
                label={t("naziv_kompanije")}
                required={true}
                onChange={(event: any) =>
                  handleCustomChange(event, setFieldValue)
                }
              />
            )}
          </div>
        </div>
        <div className="Order-Button-Div">
          {!formValid.step1 ? (
            <button type="submit" className="Order-Button">
              Nastavi
            </button>
          ) : (
            <label>Sačuvano</label>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderPageStep1;
