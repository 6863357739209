import customAxiosInterceptor from "../customAxiosInterceptor";

// Fetch menu  
// GET https://api.infocomp.ba/api/article-category
export const fetchMenuThunk = async (thunkAPI) => {
  try {
    const response = await customAxiosInterceptor.get("/article-category");
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};