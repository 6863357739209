import Logo from "../../Logo";
import MenuLink from "../MenuLink";
import MobileMenu from "./MobileMenu";
import "./Burger.scss";


const Burger = ({ openBurger, closeBurger }: any) => {
  return (
    <nav
      className="Burger-Nav"
      style={{ display: openBurger === true ? "inline" : "none" }}
    >
      <div className="Burger-Menu-Title">
        <Logo type="Desktop" />
      </div>
      <ul className="Burger-Menu">
        <h2 className="Burger-Menu-Sub-Title">Navigacija</h2>
        <MenuLink action={closeBurger} />
        <h2 className="Burger-Menu-Sub-Title">Svi proizvodi</h2>
        <MobileMenu action={closeBurger} />
      </ul>
    </nav>
  );
};
export default Burger;
