import { Image } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CartTable from "./CartTable";

import emptyCart from "../../../assets/Cart/emptycart.png";

import "../homePage/HomePage.scss";
import "./CartPage.scss";

const CartPage = () => {
  const navigate = useNavigate();
  const cart = useSelector((store: any) => store.cart);

  return (
    <div className="Page">
      <div className="Page-Content">
        {cart.total_count === 0 ? (
          <div className="Cart-Page Empty-Cart">
            <Image src={emptyCart} height={100} preview={false} />
            Vaša korpa je prazna
          </div>
        ) : (
          <>
            <h1 className="Page-Title">Vaša korpa</h1>
            <div className="Cart-Page">
              <div className="Cart-Page-Box1">
                <div className="Cart-Table">
                  <CartTable cartList={cart.list} view={false} />
                </div>
              </div>
              <div className="Cart-Page-Box2">
                {cart.total_count !== 0 && (
                  <>
                    <h2 className="Cart-Subtitle">Pregled narudžbine</h2>
                    <div className="Cart-Item">
                      <label className="Cart-Label">Cijena za plaćanje:</label>
                      <label className="Cart-Label">
                        {cart.total_price.toFixed(2)}&nbsp;KM
                      </label>
                    </div>
                    <div className="Cart-Item">
                      <label className="Cart-Label">Popust:</label>
                      <label className="Cart-Label">
                        {cart.total_discount} %
                      </label>
                    </div>
                    <div className="Page-Line"></div>
                    <div className="Cart-Item">
                      <label className="Cart-Label">Iznos kupovine:</label>
                      <label className="Cart-Label">
                        {cart.final_price.toFixed(2)}&nbsp;KM
                      </label>
                    </div>
                    <button
                      className="Cart-Button Gray-Button"
                      type="button"
                      onClick={() => navigate("/order")}
                    >
                      Poručite
                    </button>
                    {/* todo kada se uradi promo kod onda ovo dodati */}
                    {/* <div className="Cart-Item">
                  <label className="Cart-Label">Promo kod:</label>
                  <input className="Cart-Item-Input" />
                </div> */}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default CartPage;
