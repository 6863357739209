import { TiLocation } from "react-icons/ti";
import "./MapMarker.scss";
interface MapMarkerType {
  text: string;
  [key: string]: any; // This allows additional properties
}

const MapMarker = ({ text, ...props }: MapMarkerType) => {
  const handlePinClick = () => {
    // https://www.google.com/maps/dir/?api=1&destination=44.75432428694342,19.216005199324613
    const url = `https://www.google.com/maps/dir/?api=1&destination=${props.lat},${props.lng}`;
    window.open(url, "_blank");
  };
  return (
    <div className="Map-Marker" onClick={handlePinClick}>
      <TiLocation className="Map-Icon" title={text}></TiLocation>
    </div>
  );
};
export default MapMarker;
