import { Button } from "antd";
import { useState } from "react";
import OutsideAlerter from "../../../../header/menu/burger/OutsideAlerter";
import FilterNav from "./FilterNav";

import "./FiltersMobile.scss";

const FiltersMobile = ({
  getCurrentCategorySlug,
  addFilter,
  changePrice,
  minPrice, maxPrice,
  filterList
}: any) => {
  const [openFilter, setOpenFilter] = useState(false);
  const showFilter = () => {
    let backdrop = document.getElementsByClassName(
      "Backdrop"
    )[0] as unknown as HTMLElement;
    let filterMenu = document.getElementById(
      "Filter-Nav"
    ) as unknown as HTMLElement;
    if (backdrop) {
      backdrop.style.backgroundColor = "#979292";
      backdrop.style.zIndex = "700";
      backdrop.style.display = "block";
    }
    if (filterMenu) {
      filterMenu.style.display = "block";
      // console.log("Poslije dodavanja razreda:", filterMenu.className);
      setOpenFilter(true);
    }
  };
  const closeFilter = () => {
    let backdrop = document.getElementsByClassName(
      "Backdrop"
    )[0] as unknown as HTMLElement;
    let filterMenu = document.getElementById(
      "Filter-Nav"
    ) as unknown as HTMLElement;
    if (backdrop) {
      backdrop.style.backgroundColor = "transparent";
      backdrop.style.zIndex = "0";
      backdrop.style.display = "none";
    }
    if (filterMenu) {
      filterMenu.style.zIndex = "0";
      filterMenu.style.display = "none";
      setOpenFilter(false);
    }
  };
  return (
    <>
      <Button className="Filter-Mobile-Button" onClick={showFilter}>
        Filtriraj
      </Button>

      {openFilter === true ? (
        <OutsideAlerter close={closeFilter}>
          <FilterNav
            openBurger={openFilter}
            closeBurger={closeFilter}
            getCurrentCategorySlug={getCurrentCategorySlug}
            addFilter={addFilter}
            changePrice={changePrice}
            minPrice={minPrice}
            maxPrice={maxPrice}
            filterList={filterList}
          />
        </OutsideAlerter>
      ) : (
        // <OutsideAlerter close={closeFilter}>
          <FilterNav
            openBurger={openFilter}
            closeBurger={closeFilter}
            getCurrentCategorySlug={getCurrentCategorySlug}
            addFilter={addFilter}
            changePrice={changePrice}
            minPrice={minPrice}
            maxPrice={maxPrice}
            filterList={filterList}
          />
        // </OutsideAlerter>
      )}
    </>
  );
};
export default FiltersMobile;
