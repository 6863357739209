import { Field } from "formik";
import { CheckBoxField, InputField } from "../../../components";

import { useEffectDeviceAndOrientation } from "../../../utils/hooks";
import { useTranslation } from "react-i18next";

const OrderPageStep3 = ({
  errors,
  touched,
  values,
  isSubmitting,
  formValid,
  setFieldValue,
  validateField,
  handleCustomChange,
}: any) => {
  const [device, orientation] = useEffectDeviceAndOrientation();
  const { t } = useTranslation("order", { useSuspense: false });

  return (
    <div className="Form-Box">
      <div className="Form2">
        <div
          className={
            (device === "mobile" && orientation === "portrait") ||
            orientation === "portrait"
              ? "Formik-Group-Mobile"
              : "Formik-Group"
          }
        >
          <div className="Select-Field">
            <label
              className="Select-Field-Label"
              htmlFor={"step3.method_of_payment"}
            >
              {t("nacin_placanja")}
            </label>
            <Field
              className="Select-Field-Selector"
              as="select"
              name="step3.method_of_payment"
              id="step3.method_of_payment"
              onChange={(event: any) => {
                handleCustomChange(event, setFieldValue);
              }}
            >
              <option
                className="Select-Option"
                value="placanje_prilikom_preuzimanja"
              >
                {t("placanje_prilikom_preuzimanja")}
              </option>
              {/* <option value="placanje_karticom">
                {t("placanje_karticom")}
              </option> */}
            </Field>
          </div>
        </div>
        <div className="Order-Button-Div">
        {!formValid.step3 ? (
            <button type="submit" className="Order-Button">
              Nastavi
            </button>
          ) : (
            <label>Sačuvano</label>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderPageStep3;
