import { CustomCarousel } from "../../../../components";
import { useEffectDeviceAndOrientation } from "../../../../utils/hooks";

import BanerCard from "./BanerCard";
import "./Baners.scss";

import { useSelector } from "react-redux";

const Baners = () => {
  const [device, orientation] = useEffectDeviceAndOrientation();
  const baners = useSelector((store: { baners: any }) => store.baners);
  return (
    <div className="Baners-Box">
      <CustomCarousel
        device_type={device}
        items_computer={3}
        items_tablet={2}
        items_mobile={2}
        slides_computer={1}
        slides_tablet={1}
        slides_mobile={1}
        partial_computer={0}
        partial_tablet={0}
        partial_mobile={0}
        show_dots={false}
        arrow_computer={true}
        arrow_tablet={true}
        arrow_mobile={false}
      >

        {baners.list
          // .slice(0, 5)
          .map(
            (
              baner: { title: String; description: String; picture: String },
              id: number
            ) => {
              return (
                <BanerCard
                  style={{ width: "100%", height: "100%" }}
                  title={baner.title}
                  description={baner.description}
                  picture={baner.picture}
                  device={device}
                  orientation={orientation}
                  key={id}
                />
              );
            }
          )}
      </CustomCarousel>
    </div>
  );
};
export default Baners;
