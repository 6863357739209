import { Navigate } from 'react-router-dom';
import { getUserDetailFromToken, isLogin } from '../services/LocalStorage/LocalStorage.service';
import { toast } from 'react-toastify';
import { showToast } from '../utils/helpers/toastHelper';

const ProtectedRoute = ({ children, valid_roles, logged }) => {

  if (logged === isLogin()) {
    if (logged) {
      var commonArray = [];
      if (isLogin()) {
        let { roles } = getUserDetailFromToken();
        for (var i = 0; i < roles.length; i++) {
          for (var j = 0; j < valid_roles.length; j++) {
            if (roles[i] === valid_roles[j]) { // If the item is common in both arrays
              commonArray.push(roles[i]); // Push common items to the array
            }
          }
        }

      }
      // Create an array for common items in another two arrays.
      // console.log(commonArray)
      if (commonArray.length === 0) {
        showToast("Nemate privilegije za datu stranicu", 'warn')
        return <Navigate to='/' />; //ili /error
      } else {
        return children;
      }
    }
    return children;
  } else {
    if (logged) {
      return <Navigate to='/login' />;
    }
    return <Navigate to='/' />;
  }
};
export default ProtectedRoute;
