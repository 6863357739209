import { Link, useParams } from "react-router-dom";

// https://github.com/DonAdam2/react-language-router-v6
const LinkLanguage = ({ to, children, ...props }: any) => {
  // const { t } = useTranslation();
  const { lang } = useParams();
  const language = lang === undefined ? "": "/" + lang;
  const path = language + to;
  
  // console.log(path)

  return (
    <Link
      to={`${path}`}
      className={props.className}
      style={props.style}
      onClick={props.action}
      title={props.title}
    >
      {children}
    </Link>
  );
};
export default LinkLanguage;
