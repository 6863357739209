import { Link } from "react-router-dom";
import "./SocialNetworkLink.scss";
import { SocialNetworkType } from "./ContactPageData";

const SocialNetworkLink = ({ label, link, icon }: SocialNetworkType) => {
  return (
    <Link target="_blank" rel="noopener noreferrer" to={link}>
      <label className="Label-Block Pointer">
        {icon}
        &nbsp;{label}
      </label>
    </Link>
  );
};
export default SocialNetworkLink;
