import { Key } from "react";
import { PhotoDisplayHandler } from "../../../../components";
import LinkLanguage from "../../../../locales/LinkLanguage";

import "./DropdownSubMenu.scss";

const DropdownSubMenu = ({
  cat,
  item,
  itemKey,
  setVisibility,
  setVisibilitySubMenu,
}: any) => {
  return (
    <li key={item.slug} className="Dropdown-Menu-Nav2-Item">
      <div className="Dropdown-Menu-Nav2-Item-Label" onClick={() => {
            setVisibility(false);
            setVisibilitySubMenu(false);
          }}>
        <div className="Dropdown-Menu-Nav2-Item-Label-Icon" >
          <PhotoDisplayHandler
            img_name={item.img_path}
            // dir="categories"
            calculate={false}
            img_class_name="Dropdown-Menu-Nav2-Item-Label-Icon-Img"
            img_alt={item.ac_name}
            width="25px"
            height="25px"
          />
        </div>
        <LinkLanguage
          className="Dropdown-Menu-Nav2-Item-Label-Link"
          to={`/articles/${cat}/${item.slug}`}
          
        >
          {item.ac_name}
        </LinkLanguage>
      </div>
      <ul className="Dropdown-Menu-Nav2-Item-Links">
        {item.subcategories &&
          item.subcategories.map((z: any, c: Key | null | undefined) => (
            <li key={z.slug} className="Dropdown-Menu-Nav2-Item-Link" onClick={() => {
              setVisibility(false);
              setVisibilitySubMenu(false);
            }}>
              <LinkLanguage
                className="Dropdown-Menu-Nav2-Item-Link"
                to={`/articles/${cat}/${item.slug}/${z.slug}`}
                key={z.slug}
              >
                {z.ac_name}
              </LinkLanguage>
            </li>
          ))}
      </ul>
    </li>
  );
};

export default DropdownSubMenu;
