import { useState } from "react";
// import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space } from "antd";
import {
  MenuItem,
  SortOrder,
} from "../../../../../types/articlesPage/SortOrderEnum";
import "./SortDropdown.scss";

const SortDropdown = ({ order, changeOrder, ...props }: any) => {
  // const { t } = useTranslation();
  const [currentLabel, setCurrentLabel] = useState("Sortiraj");

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    changeOrder(e.key);
    const selectedLabel =
      sortOrderItems.find((item) => item.key === e.key)?.label || "Sortiraj";
    setCurrentLabel(selectedLabel);
  };
  const sortOrderItems: MenuItem[] = [
    // {
    //   label: "Sortiraj",
    //   key: SortOrder.Default,
    // },
    {
      label: "Cijena Rastuće",
      key: SortOrder.PriceAsc,
    },
    {
      label: "Cijena Opadajuće",
      key: SortOrder.PriceDesc,
    },
    {
      label: "Naziv Rastuće",
      key: SortOrder.NameAsc,
    },
    {
      label: "Naziv Opadajuće",
      key: SortOrder.NameDesc,
    },
    {
      label: "Ocjena Rastuće",
      key: SortOrder.RateAsc,
    },
    {
      label: "Popularnost",
      key: SortOrder.PopularAsc,
    },
  ];
  const items: MenuProps["items"] = sortOrderItems;

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown
      menu={menuProps}
      arrow={{ pointAtCenter: true }}
      placement="bottom"
      className="Sort-Dropdown"
    >
      <Button>
        <Space>
          {currentLabel}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
export default SortDropdown;
