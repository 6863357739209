import { Checkbox } from "antd";
import "./FilterItem.scss";

const FilterItem = ({ filterKey, items, onCheckboxChange }: any) => {
  const getOptions = () => {
    const options = [];
    for (let i in items) {
      options.push({
        label: (
          <div className="Filters-List-Item-Checkbox">
            {items[i].value_label}
          </div>
        ),
        value: items[i].value_label,
        key: items[i].value_key,
        defaultChecked: items[i].value_active
      });
    }
    return options;
  };

  const getDefaultValues = () => {
    return getOptions()
      .filter(option => option.defaultChecked)
      .map(option => option.value);
  };
  return (
    <div className="Filters-List-Item">
      <Checkbox.Group
        options={getOptions()}
        defaultValue={getDefaultValues()} 
        onChange={(e) => onCheckboxChange(e, filterKey)}
        className="Filters-List-Item-Checkbox-Group"
      />
    </div>
  );
};
export default FilterItem;
