import { useTranslation } from "react-i18next";
import { CheckBoxField, InputField } from "../../../components";
import { useEffectDeviceAndOrientation } from "../../../utils/hooks";
import { Field } from "formik";

const OrderPageStep2 = ({
  errors,
  touched,
  values,
  isSubmitting,
  formValid,
  setFieldValue,
  validateField,
  handleCustomChange,
}: any) => {
  const [device, orientation] = useEffectDeviceAndOrientation();
  const { t } = useTranslation("order", { useSuspense: false });
  return (
    <div className="Form-Box">
      <div className="Form2">
        <div
          className={
            (device === "mobile" && orientation === "portrait") ||
            orientation === "portrait"
              ? "Formik-Group-Mobile"
              : "Formik-Group"
          }
        >
          <div className="Select-Field">
            <label className="Select-Field-Label" htmlFor="step2.receiving_way">
              {t("nacin_dostave")}
            </label>
            <Field
              className="Select-Field-Selector"
              as="select"
              name="step2.receiving_way"
              id="step2.receiving_way"
              onChange={(event: any) => {
                handleCustomChange(event, setFieldValue);
              }}
            >
              <option value="na_kucnu_adresu">{t("na_kucnu_adresu")}</option>
              <option value="na_adresu_infocompa">
                {t("na_adresu_infocompa")}
              </option>
            </Field>
            {/* {touched.step2?.receiving_way && errors.step2?.receiving_way && (
            <div className="error">{errors.step2.receiving_way}</div>
          )} */}
          </div>
        </div>
        {values.step2.receiving_way === "na_kucnu_adresu" && (
          <>
            <div
              className={
                (device === "mobile" && orientation === "portrait") ||
                orientation === "portrait"
                  ? "Formik-Group-Mobile"
                  : "Formik-Group"
              }
            >
              <div>
                <InputField
                  name="step2.state"
                  label={t("drzava")}
                  required={
                    values.step2.receiving_way === "na_kucnu_adresu"
                      ? true
                      : false
                  }
                  onChange={(event: any) => {
                    handleCustomChange(event, setFieldValue);
                  }}
                />
              </div>
              <div>
                <InputField
                  name="step2.city"
                  label={t("grad")}
                  required={
                    values.step2.receiving_way === "na_kucnu_adresu"
                      ? true
                      : false
                  }
                  onChange={(event: any) => {
                    handleCustomChange(event, setFieldValue);
                  }}
                />
              </div>
            </div>
            <div
              className={
                (device === "mobile" && orientation === "portrait") ||
                orientation === "portrait"
                  ? "Formik-Group-Mobile"
                  : "Formik-Group"
              }
            >
              <div>
                <InputField
                  name="step2.zip"
                  label={t("postanski_broj")}
                  required={
                    values.step2.receiving_way === "na_kucnu_adresu"
                      ? true
                      : false
                  }
                  onChange={(event: any) => {
                    handleCustomChange(event, setFieldValue);
                  }}
                />
              </div>
              <div>
                <InputField
                  name="step2.address"
                  label={t("ulica")}
                  required={
                    values.step2.receiving_way === "na_kucnu_adresu"
                      ? true
                      : false
                  }
                  onChange={(event: any) => {
                    handleCustomChange(event, setFieldValue);
                  }}
                />
              </div>
            </div>
            <div
              className={
                (device === "mobile" && orientation === "portrait") ||
                orientation === "portrait"
                  ? "Formik-Group-Mobile"
                  : "Formik-Group"
              }
            >
              <div>
                <InputField
                  name="step2.address_addition"
                  label={t("ulica_dodatno")}
                  required={false}
                  onChange={(event: any) => {
                    handleCustomChange(event, setFieldValue);
                  }}
                />
              </div>
              <div></div>
            </div>
            <div className="Formik-Group-Mobile-Left">
              <label>
                * Cijena poštarine je besplatna za iznos preko 200&nbsp;KM
              </label>
              <label>
                * Cijena poštarine za iznos do 200KM iznosi 8&nbsp;KM
              </label>
              
            </div>
          </>
        )}
        <div className="Order-Button-Div">
          {!formValid.step2 ? (
            <button type="submit" className="Order-Button">
              Nastavi
            </button>
          ) : (
            <label>Sačuvano</label>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderPageStep2;
