import customAxiosInterceptor from "../customAxiosInterceptor";

export const searchTermThunk = async (searchTerm: string, thunkAPI: any) => {
  try {
    const response = await customAxiosInterceptor.get(`/search?search_term=${searchTerm}`);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};
