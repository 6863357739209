import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import AppRouter from "./routing/AppRouter";
import { store } from "./store";
import i18n from "./locales/i18n";
import { LoadingOutlined } from "@ant-design/icons";
import "./index.scss";

export default function App() {
  return (
    <Suspense fallback={<LoadingOutlined />}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </I18nextProvider>
    </Suspense>
  );
}
