import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchBlogBySlug } from "../../../store/blogs/blogs.slice";
import { formatDate } from "../../../utils/helpers/dateTime";
import PhotoGallery from "../../../components/photo/PhotoGallery";
import ShareIcons from "../../../components/shareIcons/ShareIcons";
import { setSEO } from "../../../utils/helpers/seo-function";
import "./BlogDetail.scss";
import { BASE_URL } from "../../../config/constants";

const BlogDetail = () => {
  const { blog } = useParams();
  const { pathname } = useLocation();
  const { blogItem } = useSelector((store: any) => store.blogs);
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (blog) {
      dispatch(fetchBlogBySlug(blog));
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    if (blogItem.is_fetch === true) {
      setSEO({
        title:
        blogItem.data.post_title !== null ? blogItem.data.post_title : "",
        description:
        blogItem.data.post_description !== null
            ? blogItem.data.post_description
            : "",
        keywords:
        blogItem.data.seo_keywords !== null ? blogItem.data.seo_keywords : [],
        url: BASE_URL + pathname,
        image: blogItem.data.images.length !== 0 ? blogItem.data.images[0] : "",
        type: "blogs",
      });
    }
  }, [blogItem.is_fetch]);


  return (
    <div className="Page">
      <div className="Page-Content">
        {blogItem.is_fetch && (
          <div className="Blog-Detail">

            <h1 className="Page-Title">{blogItem.data.post_title}</h1>
            <label className="Blog-Detail-Date">
              {formatDate(blogItem.data.created_at)}
            </label>
            <p>{blogItem.data.post_description}</p>
            <div className="Blog-Detail-Image">
              {blogItem.data.images.length !== 0 && (
                <PhotoGallery images={blogItem.data.images} type={"blog"} />
              )}
            </div>
            <div
              className="Blog-Detail-Content"
              dangerouslySetInnerHTML={{ __html: blogItem.data.post_content }}
            />

            {blogItem.data.video_url && (
              <div className="Blog-Detail-Videos">
                <iframe
                  title={blogItem.data.video_url}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  src={blogItem.data.video_url}
                  className="Blog-Detail-Video"
                ></iframe>
              </div>
            )}
            <div className="Blog-Detail-Share">
              <ShareIcons
                url={pathname}
                title={blogItem.data.post_title}
                summary={blogItem.data.post_description}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default BlogDetail;
