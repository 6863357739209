import { Collapse, Tooltip } from "antd";

import type { CollapseProps } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { SliderMarks } from "antd/es/slider";
import { DeleteOutlined } from "@ant-design/icons";

import FilterItem from "./FilterItem";
import PriceItem from "./PriceItem";

import "./Filters.scss";
const Filters = ({
  getCurrentCategorySlug,
  addFilter,
  changePrice,
  minPrice,
  maxPrice,
  filterList,
}: any) => {


  const getFilters = () => {
    const filters: CollapseProps["items"] = [];
    filterList.data.forEach((f: any) => {
      if (f.filter_type === "check") {
        filters.push({
          key: f.filter_key,
          label: <div className="Filters-List-Label">{f.filter_label}</div>,
          children: (
            <FilterItem
              onCheckboxChange={onCheckboxChange}
              filterKey={f.filter_key}
              items={f.filter_children}
            />
          ),
          extra: getDelete(f.filter_key),
        });
      } else {

        let marks: { [key: number]: string }  = {};
        f.filter_children.forEach((child: any) => {
          marks[child.value_number] = `${child.value_number} KM`;
        });
        const minDefault = f.filter_children[0].value_number;
        const maxDefault = f.filter_children[1].value_number;
        filters.push({
          key: f.filter_key,
          label: <div className="Filters-List-Label">{f.filter_label}</div>,
          children: (
            <PriceItem
              minDefault={minDefault}
              maxDefault={maxDefault}
              changePrice={changePrice}
              minPrice={minPrice}
              maxPrice={maxPrice}
              marks={marks}
            />
          ),
          // extra: getDelete(filtersList[f].filter_key),
        });
      }
    });

    return filters;
  };

  const onCheckboxChange = (
    checkedValues: CheckboxValueType[],
    filterKey: number
  ) => {

    let filter = filterList.data.find(
      (item: any) => item.filter_key === filterKey
    );
    addFilter(filter?.filter_label, checkedValues);
  };

  const onChangeCollapse = (key: string | string[]) => {

  };


  const getDelete = (item: any) => (
    <Tooltip placement="top" title={"Obriši filtere"}>
      <DeleteOutlined
        onClick={(event) => {
          event.stopPropagation();
          addFilter(item, []);
        }}
      />
    </Tooltip>
  );

  const getActiveKey = () => {
    let activeKeyList: string[] = [];
    filterList.data.forEach((f: any) => {
      if (f.filter_active) {
        activeKeyList.push(f.filter_key);
      }
    });
    return activeKeyList;
  };

  return (
    <div className="Filters">
      {/* {filterList.is_fetch && ( */}
        <Collapse
          defaultActiveKey={getActiveKey()}
          onChange={onChangeCollapse}
          expandIconPosition={"end"}
          items={getFilters()}
          className="Filters-List"
        />
      {/* )} */}
    </div>
  );
};
export default Filters;
