import { Key, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingOutlined, MenuOutlined } from "@ant-design/icons";

import { AppDispatch } from "../../../../store";
import { fetchMenu } from "../../../../store/articleCategory/articleCategory.slice";

import { PhotoDisplayHandler } from "../../../../components";
import LinkLanguage from "../../../../locales/LinkLanguage";
import DropdownSubMenu from "./DropdownSubMenu";

import "./DropdownMenu.scss";
import { useEffectDeviceAndOrientation } from "../../../../utils/hooks";
import { useEffectDeviceWidth } from "../../../../utils/hooks/useEffectDeviceAndOrientation";

const DropdownMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [deviceWidth] = useEffectDeviceWidth();
  const [visibility, setVisibility] = useState(false);
  const [visibilitySubMenu, setVisibilitySubMenu] = useState(false);
  const [category, setCategory] = useState({
    slug: "",
    subcategories: [],
  });
  const [selectedCategory, setSelectedCategory] = useState({
    slug: "",
    clicked: false,
    subcategories: [],
  });
  const menu = useSelector((store: any) => store.articleCategory);
  const dispatch: (arg: any) => AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchMenu(null));
  }, [dispatch]);

  const showDropdown = (e: any) => {
    setVisibility(true);
  };
  const hideDropdown = (e: any) => {
    setVisibility(false);
    setCategory({
      slug: "",
      subcategories: [],
    });
    if (selectedCategory.clicked === false) {
      setVisibilitySubMenu(false);
      setSelectedCategory({ slug: "", clicked: false, subcategories: [] });
    }
  };

  const showSubMenu = (e: any, x: any) => {
    setCategory({
      slug: x.slug,
      subcategories: x.subcategories,
    });
    setVisibilitySubMenu(true);
  };
  const hideSubMenu = (e: any) => {
    setCategory({
      slug: "",
      subcategories: [],
    });
    if (selectedCategory.clicked === false) {
      setVisibilitySubMenu(false);
      setSelectedCategory({ slug: "", clicked: false, subcategories: [] });
    }
  };
  const selectSubMenu = (e: any, x: any) => {
    if (selectedCategory.clicked === false) {
      setSelectedCategory({
        slug: x.slug,
        clicked: true,
        subcategories: x.subcategories,
      });
    } else if (
      selectedCategory.clicked === true &&
      selectedCategory.slug !== x.slug
    ) {
      setSelectedCategory({
        slug: x.slug,
        clicked: true,
        subcategories: x.subcategories,
      });
    } else if (
      selectedCategory.clicked === true &&
      selectedCategory.slug === x.slug
    ) {
      setSelectedCategory({ slug: "", clicked: false, subcategories: [] });
      navigate("/articles/" + x.slug);
      setVisibility(false);
      setVisibilitySubMenu(false);
    } else {
      setSelectedCategory({ slug: "", clicked: false, subcategories: [] });
    }
    setCategory({
      slug: x.slug,
      subcategories: x.children,
    });
  };

  return (
    <nav
      className="Dropdown-Menu"
      onMouseEnter={showDropdown}
      onMouseLeave={hideDropdown}
    >
      <button className="Dropdown-Menu-Button">
        <MenuOutlined className="Dropdown-Menu-Button-Icon" />
        {deviceWidth > 850 && (
          <h1 className="Dropdown-Menu-Button-Label">{t("proizvodi")}</h1>
        )}
      </button>
      {visibility && (
        <ul className="Dropdown-Menu-Nav" onMouseLeave={hideSubMenu}>
          {menu.is_fetch === false ? (
            <LoadingOutlined />
          ) : (
            <div className="Dropdown-Menu-Nav-Div">
              {menu.article_category.map(
                (item: any, y: Key | null | undefined) => (
                  <li
                    className={`Dropdown-Menu-Nav-Item ${item.slug}`}
                    key={item.slug}
                  >
                    <div
                      className="Dropdown-Menu-Nav-Item-Label"
                      onMouseEnter={(e) => showSubMenu(e, item)}
                      onClick={(e) => selectSubMenu(e, item)}
                    >
                      <div className="Dropdown-Menu-Nav-Item-Label-Icon">
                        <PhotoDisplayHandler
                          img_name={item.img_path}
                          // dir="categories"
                          calculate={false}
                          img_class_name="Dropdown-Menu-Nav-Item-Label-Icon-Img"
                          img_alt={item.ac_name}
                          width="25px"
                          height="25px"
                        />
                      </div>
                      <LinkLanguage
                        className="Dropdown-Menu-Nav-Item-Label-Link"
                        to={location.pathname}
                      >
                        {item.ac_name}
                      </LinkLanguage>
                    </div>
                    {visibilitySubMenu && (
                      <ul className="Dropdown-Menu-Nav2">
                        {selectedCategory.clicked
                          ? selectedCategory.subcategories &&
                            selectedCategory.subcategories.map(
                              (z: any, c: Key | null | undefined) => (
                                <DropdownSubMenu
                                  cat={selectedCategory.slug}
                                  item={z}
                                  itemKey={z.slug}
                                  key={z.slug}
                                  setVisibility={setVisibility}
                                  setVisibilitySubMenu={setVisibilitySubMenu}
                                />
                              )
                            )
                          : category &&
                            category.subcategories.map(
                              (z: any, c: Key | null | undefined) => (
                                <DropdownSubMenu
                                  cat={category.slug}
                                  item={z}
                                  itemKey={z.slug}
                                  key={z.slug}
                                  setVisibility={setVisibility}
                                  setVisibilitySubMenu={setVisibilitySubMenu}
                                />
                              )
                            )}
                      </ul>
                    )}
                  </li>
                )
              )}
            </div>
          )}
        </ul>
      )}
    </nav>
  );
};

export default DropdownMenu;
