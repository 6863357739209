import { CalendarFilled, MailFilled, PhoneFilled } from "@ant-design/icons";
import { GridBox } from "../../../components";
import { Key } from "react";
import { getBusinessHours, getDays } from "../../../utils/helpers/dateTime";
import { useTranslation } from "react-i18next";
import { TiLocation } from "react-icons/ti";

import "./ContactPage.scss";

const ContactBox = ({ company }: any) => {
  const { t } = useTranslation();
  return (
    <div className="Contact-Info-Box">
      <h1 style={{ fontWeight: "bold" }}>{company.store_name}</h1>
      <GridBox type={3}>
        {/* Radno vreme */}
        <div
          className="Contact-Info-Box-Item"
          style={{ gridRowStart: "1", gridRowEnd: "2" }} //za raspored u grid view 
        >
          <h2>
            <CalendarFilled />
            &nbsp;{t("footer.radno_vrijeme") || "Radno vrijeme"}:
          </h2>
          {company.business_hours.map((x: any, y: Key | null | undefined) => (
            <div key={y}>
              <label className="Label-Block">{getDays(x)}</label>
              <label className="Label-Block">{getBusinessHours(x)}</label>
            </div>
          ))}
        </div>
        {/* Brojevi telefona */}
        <div className="Contact-Info-Box-Item">
          <h2>
            <PhoneFilled />
            &nbsp;{t("footer.brojevi_telefona") || "Brojevi telefona"}:
          </h2>
          {company.telephones.map((x: any, y: Key | null | undefined) => (
            <div key={y}>
              <a href={"tel:" + x.tel_num}>
                <label className="Label-Block">{x.owner}</label>
                <label className="Label-Block" style={{ cursor: "pointer" }}>
                  {x.tel_num}
                </label>
              </a>
            </div>
          ))}
        </div>
        {/* Lokacije */}
        <div className="Contact-Info-Box-Item">
          <h2>
            <TiLocation />
            &nbsp;{t("footer.lokacija") || "Lokacije"}:
          </h2>
          <label className="Label-Block">

            {company.address.street_name}, broj{" "}
            {company.address.street_number}
          </label>
          <label className="Label-Block">
            {company.address.zip} {company.address.city_name}
          </label>
          <label className="Label-Block">
            {company.address.region_name}
          </label>
          <label className="Label-Block">
            {company.address.state_name}
          </label>
        </div>
        {/* Email */}
        <div className="Contact-Info-Box-Item">
          <h2>
            <MailFilled />
            &nbsp;{t("footer.email") || "Email"}:
          </h2>
          {company.emails.map((x: any, y: Key | null | undefined) => (
            <div key={y}>
              <a href={"mailto:" + x.email}>
                <label className="Label-Block">{x.owner}</label>
                <label className="Label-Block" style={{ cursor: "pointer" }}>
                  {x.email}
                </label>
              </a>
            </div>
          ))}
        </div>
      </GridBox>
    </div>
  );
};

export default ContactBox;
