import { useDispatch, useSelector } from "react-redux";
import { Key, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";


import { CustomCarousel, PhotoDisplayHandler } from "../../../../components";
import { AppDispatch } from "../../../../store";
import { fetchSliders } from "../../../../store/sliders/sliders.slice";

import "./Sliders.scss";
import { useEffectDeviceAndOrientation } from "../../../../utils/hooks";

const Sliders = () => {
  const [device, orientation] = useEffectDeviceAndOrientation();
  const { list, is_fetch } = useSelector(
    (store: { sliders: any }) => store.sliders
  );
  const dispatch: (arg: any) => AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSliders());
  }, [dispatch]);
  return (
    <div className="Sliders-Box">
      {is_fetch === false ? (
        <LoadingOutlined />
      ) : (
        <CustomCarousel
          device_type={device}
          items_computer={1}
          items_tablet={1}
          items_mobile={1}
          slides_computer={1}
          slides_tablet={1}
          slides_mobile={1}
          partial_computer={0}
          partial_tablet={0}
          partial_mobile={0}
          show_dots={true}
          arrow_computer={true}
          arrow_tablet={true}
          arrow_mobile={true}
        >
          {list && list.map(
              (
                item: {
                  img_mobile: string;
                  img_desktop: string;
                  img_tablet: string;
                  url: string;
                  seo_keywords: string[];
                },
                id: Key | null | undefined
              ) => (
                <a href={item.url} target="_blank" key={id} title={`Go to ${item.seo_keywords.join(", ")}`} rel="noopener noreferrer">
                  <PhotoDisplayHandler
                    img_name={
                      device === "mobile"
                        ? item.img_mobile
                        : device === "mobile" && orientation === "landscape"
                        ? item.img_tablet
                        : item.img_desktop
                    }
                    calculate={false}
                    img_class_name="Slider-Img"
                    img_alt={item.seo_keywords.join(", ")}
                    width="100%"
                    height="100%"
                  />
                </a>
              )
            )}
        </CustomCarousel>
      )}
    </div>
  );
};
export default Sliders;
