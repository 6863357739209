import { getUserDetailFromToken } from "../../services/LocalStorage/LocalStorage.service";
import customAxiosInterceptor from "../customAxiosInterceptor";

// Fetch user 
// GET https://api.infocomp.ba/api/user/{id}
export const fetchUserThunk = async (thunkAPI) => {
  try {
    const { id } = getUserDetailFromToken();
    const response = await customAxiosInterceptor.get("/user/" + id);  
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};
// TODO PREGLEDATI GDE SE OVE METODE KORISTE
// DELETE https://api.infocomp.ba/api/user/{id}
export const deleteUserThunk = async (thunkAPI, { payload }) => {
  try {
    const response = await customAxiosInterceptor.delete("/user/" + payload);
    return response.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

// GET https://api.infocomp.ba/api/user/role
export const fetchUserRoleThunk = async (thunkAPI) => {
  try {
    const response = await customAxiosInterceptor.get("/user/role");
    return response.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

