import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { searchTermThunk } from "./search.actions";

export const searchTerm = createAsyncThunk(
  "search/searchTerm",
  searchTermThunk
);

export const searchInitialState = {
    is_loading: false,
    error: false,
    data: {},
  };

const SearchSlice = createSlice({
  name: "search",
  initialState: searchInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchTerm.fulfilled, (state, { payload }) => {
        // console.log("uspesno");
        // console.log(payload);
        return { ...state, is_loading: false, data: payload, error: false };

      })
      .addCase(searchTerm.pending, (state) => {
        // console.log("pending");
        return { ...state, is_loading: true, error: false };
      })
      .addCase(searchTerm.rejected, (state) => {
        // console.log("odbijeno");
        return { ...state, error: true, is_loading: false };
      });
  },
});

export default SearchSlice.reducer;
