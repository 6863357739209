import customAxiosInterceptor from "../customAxiosInterceptor";

// Fetch recent articles  
// GET https://api.infocomp.ba/api/slider
export const fetchRecentArticlesThunk = async (thunkAPI) => {
  try {
    const response = await customAxiosInterceptor.get("/article");
    return response.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

export const fetchFilteredArticlesThunk = async (data, thunkAPI) => {
  try {
    // console.log(data.article_filters)
    const response = await customAxiosInterceptor.get("/article/page", {
      params: {
        ac_slug: data.ac_slug,
        page: data.page,
        take: data.take,
        order: data.order,
        min_price: data.min_price,
        max_price: data.max_price,
        article_filters: data.article_filters
      }
    });
    // console.log(response.data)
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

export const fetchFiltersListThunk = async (data, thunkAPI) => {
  try {
    const response = await customAxiosInterceptor.get("/article/filters", { params: {ac_slug: data.ac_slug} });
    return { data: response.data.data, url_filters: data.url_filters};
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};