import customAxiosInterceptor, { checkForUnauthorizedResponse } from "../customAxiosInterceptor";

// POST https://api.infocomp.ba/api/auth/login
export const loginThunk = async (data, thunkAPI) => {
  try {
    const response = await customAxiosInterceptor.post("/auth/login", data);
    return { request_data: data, response_data: response.data };
  } catch (error) {
    checkForUnauthorizedResponse(error);
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

// POST https://api.infocomp.ba/api/auth/register
export const registerThunk = async (data, thunkAPI) => {
  try {
    const response = await customAxiosInterceptor.post("/auth/register", data);
    // console.log(response)
    return {request_data: data, response_data: response.data};
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

// GET https://api.infocomp.ba/api/auth/verify?token=uuid_token
export const verifyThunk = async (token, thunkAPI) => {
  try {
    // console.log("token")
    // console.log(token)

    const response = await customAxiosInterceptor.get("/auth/verify", { params: { token: token } });
    // console.log("ispis rezultata")
    // console.log(response)
    // return response.data;
    // return thunkAPI
    return thunkAPI.fulfillWithValue(response.data);
  } catch (error) {
    // console.log(error)
    return thunkAPI.rejectWithValue(error);
  }
};

// GET https://api.infocomp.ba/api/auth/verify/resend?email=adresa@email.com&password=dfsafa
export const resendVerifyThunk = async (data, thunkAPI) => {
  try {
    // console.log("podaci")
    // console.log(data)

    const response = await customAxiosInterceptor.get("/auth/verify/resend", { params: { email: data.email, password: data.password } });
    // console.log("ispis rezultata")
    // console.log(response)
    // return response.data;
    // return thunkAPI
    return thunkAPI.fulfillWithValue(response.data);
  } catch (error) {
    // console.log(error)
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
};