import LinkLanguage from "../../locales/LinkLanguage";

import "./BreadcrumbLink.scss";

const BreadcrumbLink = ({ label, path, title }: any) => {
  return (
    <LinkLanguage className="Breadcrumb-Link" to={path} key={label} title={title}>
      {label}
    </LinkLanguage>
  );
};
export default BreadcrumbLink;
