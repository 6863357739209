import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteUserThunk, fetchUserRoleThunk, fetchUserThunk } from "./user.actions";
import { toast } from "react-toastify";
import { addUserToLocalStorage, getUserDetailFromToken, removeUserFromLocalStorage } from "../../services/LocalStorage/LocalStorage.service";
import { showToast } from "../../utils/helpers/toastHelper";

export const fetchUser = createAsyncThunk('user/fetchUser', fetchUserThunk);
export const deleteUser = createAsyncThunk('user/deleteUser', deleteUserThunk);
export const fetchUserRole = createAsyncThunk('user/fetchUserRole', fetchUserRoleThunk);

const userDetails = {
    id: null,
    email: "",
    user_firstname: "",
    user_lastname: "",
    penalty_num: null,
    email_verified_at: "",
    created_at: "",
    address: {
        id: null,
        state_name: "",
        region_name: "",
        city_name: "",
        location: "",
        zip: "",
        street_name: "",
        street_number: ""
    },
    roles: []
}

const newUser = {
    email: "",
    user_firstname: "",
    user_lastname: "",
    password: "",
    password_confirm: ""
}
const initialState = {
    user_details: userDetails,
    is_loading: false,
    new_user: newUser,
    verification: {
        is_loading: false,
        verified: false
    }
}

const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logout: (state) => {
            // console.log("nesto")
            removeUserFromLocalStorage();
            return { ...state, is_loading: false, user_details: userDetails };

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.fulfilled, (state, { payload }) => {
                return { ...state, is_loading: false, user_details: payload };
            })
            .addCase(fetchUser.pending, (state) => {
                return { ...state, is_loading: true };
            })
            .addCase(fetchUser.rejected, (state) => {
                return { ...state, is_loading: false, user_details: userDetails };
            })
            .addCase(deleteUser.fulfilled, (state, { payload }) => {
                logout();
            })
            .addCase(deleteUser.pending, (state) => {
                return { ...state, is_loading: true };
            })
            .addCase(deleteUser.rejected, (state) => {
                showToast("Greska prilikom brisanja", "error")
                return { ...state, is_loading: false };
            })
            .addCase(fetchUserRole.fulfilled, (state, { payload }) => {
                state.is_loading = false;
                state.user_details.roles = payload;
            })
            .addCase(fetchUserRole.pending, (state) => {
                return { ...state, is_loading: true };
            })
            .addCase(fetchUserRole.rejected, (state) => {
                showToast("Greska prilikom dobavljanja rola", "error")
                return { ...state, is_loading: false, user_details: userDetails };
            });

    }

});


export const { logout } = UserSlice.actions;
export default UserSlice.reducer;

