import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  ApartmentOutlined,
  ContactsOutlined,
  HomeOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserOutlined,
  UserAddOutlined
} from "@ant-design/icons";
import {
  MdOutlineSolarPower,
} from "react-icons/md";
import { BsPostcard } from "react-icons/bs";
import { GrCatalog } from "react-icons/gr";

import { isLogin } from "../../../services/LocalStorage/LocalStorage.service";
import { logout } from "../../../store/user/user.slice";
import LinkLanguage from "../../../locales/LinkLanguage";
import { useEffectDeviceAndOrientation } from "../../../utils/hooks";
import { IoInformationCircleOutline } from "react-icons/io5";

const MenuLink = ({ action }: any) => {
  const { t } = useTranslation();
  const [device, orientation] = useEffectDeviceAndOrientation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const logoutUser = async () => {
    dispatch(logout());
    navigate("/login");
  };

  const getMenu = () => {
    if (device === "computer" && orientation === "landscape") {
      return "Toolbar";
    } else if (device === "computer" && orientation === "portrait") {
      return "Burger";
    } else if (device === "mobile") {
      return "Burger";
    } else {
      return "Burger";
    }
  };
  return (
    <>
      {(device === "mobile" ||
        (device === "computer" && orientation === "portrait")) && (
        /* proizvodi */
        <li className={`${getMenu()}-Menu-Item`}>
          <LinkLanguage
            className={`${getMenu()}-Menu-Item-Label`}
            to="/articles"
            action={action}
          >
            <ApartmentOutlined className="Burger-Menu-Item-Icon" />
            {t("proizvodi")}
          </LinkLanguage>
        </li>
      )}
      {/* pocetna */}
      <li className={`${getMenu()}-Menu-Item`}>
        <LinkLanguage
          className={`${getMenu()}-Menu-Item-Label`}
          to="/"
          action={action}
        >
          {getMenu() === "Burger" && (
            <HomeOutlined className="Burger-Menu-Item-Icon" />
          )}
          {t("pocetna")}
        </LinkLanguage>
      </li>
      {/* o nama */}
      <li className={`${getMenu()}-Menu-Item`}>
        <LinkLanguage
          className={`${getMenu()}-Menu-Item-Label`}
          to="/about"
          action={action}
        >
          {getMenu() === "Burger" && (
            <IoInformationCircleOutline className="Burger-Menu-Item-Icon" />
          )}
          {t("o_nama")}
        </LinkLanguage>
      </li>
      {/* akcije TODO*/}
      {/* <li className={`${getMenu()}-Menu-Item`}>
        <LinkLanguage
          className={`${getMenu()}-Menu-Item-Label`}
          to="/actions"
          action={action}
        >
          {getMenu() === "Burger" && (
            <TbShoppingCartDiscount className="Burger-Menu-Item-Icon" />
          )}
          {t("akcije")}
        </LinkLanguage>
      </li> */}
      {/* solarni paneli */}
      <li className={`${getMenu()}-Menu-Item`}>
        <LinkLanguage
          className={`${getMenu()}-Menu-Item-Label`}
          to="/solar-panels"
          action={action}
        >
          {getMenu() === "Burger" && (
            <MdOutlineSolarPower className="Burger-Menu-Item-Icon" />
          )}
          {t("solarni_paneli")}
        </LinkLanguage>
      </li>
      {/* blog */}
      <li className={`${getMenu()}-Menu-Item`}>
        <LinkLanguage
          className={`${getMenu()}-Menu-Item-Label`}
          to="/blogs"
          action={action}
        >
          {getMenu() === "Burger" && (
            <BsPostcard className="Burger-Menu-Item-Icon" />
          )}
          {t("blog")}
        </LinkLanguage>
      </li>
      {/* partneri */}
      <li className={`${getMenu()}-Menu-Item`}>
        <LinkLanguage
          className={`${getMenu()}-Menu-Item-Label`}
          to="/partners"
          action={action}
        >
          {getMenu() === "Burger" && (
            <TeamOutlined className="Burger-Menu-Item-Icon" />
          )}
          {t("partneri")}
        </LinkLanguage>
      </li>
      {/* katalog */}
      <li className={`${getMenu()}-Menu-Item`}>
        <LinkLanguage
          className={`${getMenu()}-Menu-Item-Label`}
          to="/catalog"
          action={action}
        >
          {getMenu() === "Burger" && (
            <GrCatalog className="Burger-Menu-Item-Icon" />
          )}
          {t("katalog")}
        </LinkLanguage>
      </li>
      {/* kontakt */}
      <li className={`${getMenu()}-Menu-Item`}>
        <LinkLanguage
          className={`${getMenu()}-Menu-Item-Label`}
          to="/contact"
          action={action}
        >
          {getMenu() === "Burger" && (
            <ContactsOutlined className="Burger-Menu-Item-Icon" />
          )}
          {t("kontakt")}
        </LinkLanguage>
      </li>

      {(device === "mobile" ||
        (device === "computer" && orientation === "portrait")) && (
        <>
          {/* {isLogin() && (
            <li className={`${getMenu()}-Menu-Item`}>
              <LinkLanguage
                className={`${getMenu()}-Menu-Item-Label`}
                to="/profile"
                action={action}
              >
                <UserOutlined className="Burger-Menu-Item-Icon" />
                {t("profil")}
              </LinkLanguage>
            </li>
          )} */}

          {isLogin() ? (
            <li className={`${getMenu()}-Menu-Item`}>
              <div
                className={`${getMenu()}-Menu-Item-Label`}
                onClick={() => logoutUser()}
              >
                <LogoutOutlined className="Burger-Menu-Item-Icon" />
                {t("odjavi_se")}
              </div>
            </li>
          ) : (
            <>
              <li className={`${getMenu()}-Menu-Item`}>
                <LinkLanguage
                  className={`${getMenu()}-Menu-Item-Label`}
                  to="/login"
                  action={action}
                >
                  <UserOutlined className="Burger-Menu-Item-Icon" />
                  {t("prijavi_se")}
                </LinkLanguage>
              </li>
              <li className={`${getMenu()}-Menu-Item`}>
                <LinkLanguage
                  className={`${getMenu()}-Menu-Item-Label`}
                  to="/registration"
                  action={action}
                >
                  <UserAddOutlined className="Burger-Menu-Item-Icon" />
                  {t("registruj_se")}
                </LinkLanguage>
              </li>
            </>
          )}
        </>
      )}
    </>
  );
};
export default MenuLink;
