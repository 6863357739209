import customAxiosInterceptor from "../customAxiosInterceptor";

export const newOrderThunk = async (data, thunkAPI) => {
  // todo add cart
    try {
      const response = await customAxiosInterceptor.post("/order", data);
      
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  };