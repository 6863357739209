import { useParams } from "react-router-dom";
import LinkLanguage from "../../../../locales/LinkLanguage";
import { PhotoDisplayHandler } from "../../../../components";
import "./CategoryItem.scss";

const CategoryItem = ({ item }: any) => {
  let { cat1, cat2, cat3 } = useParams();

  const getName = () => {
    if (cat3 !== undefined) {
      return "nista";
    } else if (cat2 !== undefined) {
      return item.ac_name;
    } else if (cat1 !== undefined) {
      return item.ac_name;
    } else {
      return item.ac_name;
    }
  };
  const getDirectory = () => {
    if (cat3 !== undefined) {
      return "products";
    } else if (cat2 !== undefined) {
      return "product_types";
    } else if (cat1 !== undefined) {
      return "categories";
    } else {
      return "categories";
    }
  };

  const getPath = () => {
    let path = "/articles";
    if (cat3 !== undefined) {
      path += "/" + cat1 + "/" + cat2 + "/" + cat3;
    } else if (cat2 !== undefined) {
      path += "/" + cat1 + "/" + cat2 + "/" + item.slug;
    } else if (cat1 !== undefined) {
      path += "/" + cat1 + "/" + item.slug;
    } else {
      path += "/" + item.slug;
    }
    return path;
  };

  return (
    <div className="Category-Item">
      <div className="Category-Item-Label-Border">
        <LinkLanguage className="Category-Item-Label" to={getPath()}>
          <div className="Category-Item-Label-Box">
            <div className="Category-Item-Label-Icon">
              <PhotoDisplayHandler
                img_name={item.img_path}
                // dir={getDirectory()}
                calculate={false}
                img_class_name="Category-Item-Label-Icon-Img"
                img_alt={getName()}
                width="40px"
                height="40px"
              />
            </div>
            <div className="Category-Item-Label-Link">{getName()}</div>
          </div>
        </LinkLanguage>
      </div>
    </div>
  );
};
export default CategoryItem;
