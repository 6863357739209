// access_token
export const addUserToLocalStorage = (access_token) => {
  localStorage.setItem("access_token", JSON.stringify(access_token));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("access_token");
  // window.location.reload();
};

export const getTokenFromLocalStorage = () => {
  const result = localStorage.getItem("access_token");
  const access_token = result ? JSON.parse(result) : null;
  return access_token;
};
// user detail from token
export const getUserDetailFromToken = () => {
  const access_token = getTokenFromLocalStorage();
  if (access_token) {
    const tokenPayload = access_token.split(".")[1];
    const decodedPayload = JSON.parse(atob(tokenPayload));
    return { id: decodedPayload.id, roles: decodedPayload.roles };
  }
  return { id: null, roles: [] };
};
// is login
export const isLogin = () => {
  const result = localStorage.getItem("access_token");
  return result ? true : false;
};
// language
export const addLanguageToLocalStorage = (language) => {
  localStorage.setItem("i18nextLng", JSON.stringify(language));
};

export const removeLanguageFromLocalStorage = () => {
  localStorage.removeItem("i18nextLng");
};

export const getLanguageFromLocalStorage = () => {
  const result = localStorage.getItem("i18nextLng");
  return result ? result : "sr";
};

//cart
export const addCartToLocalStorage = (cart) => {
  localStorage.removeItem("cart");
  localStorage.setItem("cart", JSON.stringify(cart));
};
export const getCartFromLocalStorage = (cart) => {
  const result = localStorage.getItem("cart");
  return result ? JSON.parse(result) : null;
};
export const removeCartFromLocalStorage = () => {
  localStorage.removeItem("cart");
};
