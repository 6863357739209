import { PhotoDisplayHandler } from "../../../components";
import LinkLanguage from "../../../locales/LinkLanguage";
import { useEffectDeviceAndOrientation } from "../../../utils/hooks";
import "./SearchForm.scss";

const SearchItem = ({ data, hideDropdown, isCategory }: any) => {

  return (
    <LinkLanguage
      // className={`Header-Logo-${type}`}
      className="Search-Article-Item"
      to={
        isCategory ? "/articles" + data.path : "/article/" + data.article_slug
      }
      action={hideDropdown}
      // style={
      //   device === "mobile" || orientation === "vertical"
      //     ? { justifyContent: "center" }
      //     : { justifyContent: "left" }
      // }
    >
      <PhotoDisplayHandler
        img_name={data.background_image}
        calculate={false}
        img_class_name=""
        img_alt={isCategory ? data.ac_name : data.article_name}
        width={isCategory ? "50px" : "100px"}
        height={isCategory ? "50px" : "100px"}
      />
      <div className="Search-Article-Item-Info">
        <div className="Search-Article-Item-Name">
          {isCategory ? data.ac_name : data.article_name}
        </div>
        {!isCategory && (
          <div className="Search-Article-Item-Price-Item">
            {data.article_price.on_sale && (
              <div className="Search-Article-Item-Old-Price">
                {data.article_price.old_price}&nbsp;KM
              </div>
            )}

            {/* <div className="Search-Article-Item-Discount">{data.article_price.discount}&nbsp;%</div> */}
            <div className="Search-Article-Item-Discount-Price">
              {data.article_price.price}&nbsp;KM
            </div>
          </div>
        )}
      </div>
    </LinkLanguage>
  );
};
export default SearchItem;
