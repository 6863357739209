import { useTranslation } from "react-i18next";
import { CheckBoxField, InputField } from "../../../components";

import { useEffectDeviceAndOrientation } from "../../../utils/hooks";
import LinkLanguage from "../../../locales/LinkLanguage";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import CartTable from "../cart/CartTable";
import { ErrorMessage, Field } from "formik";

const OrderPageStep4 = ({
  errors,
  touched,
  values,
  isSubmitting,
  formValid,
  setFieldValue,
  validateField,
  handleCustomChange,
  handleCheckboxChange,
  updateFormValid,
}: any) => {
  const [device, orientation] = useEffectDeviceAndOrientation();
  const { t } = useTranslation("order", { useSuspense: false });
  const cart = useSelector((store: any) => store.cart);

  return (
    <div className="Form-Box">
      <div className="Form-Info">
        {/* <h2 className="Form-Info-Item-Label Title">{t("informacije_o_isporuci")}</h2> */}
        <div className="Form-Info-Item">
          <h2 className="Form-Info-Item-Label">
            Podaci korisnika koji zakljucuje kupovinu
          </h2>

          <p className="Form-Info-Item-Paragraf">
            {t("ime")}:&nbsp;{values.step1.first_name}
          </p>
          <p className="Form-Info-Item-Paragraf">
            {t("prezime")}:&nbsp;{values.step1.last_name}
          </p>
          <p className="Form-Info-Item-Paragraf">
            {t("telefon")}:&nbsp;{values.step1.telephone}
          </p>
          <p className="Form-Info-Item-Paragraf">
            {t("email")}:&nbsp;{values.step1.email}
          </p>
          {values.step1.person === true && (
            <p className="Form-Info-Item-Paragraf">
              {t("naziv_kompanije")}:&nbsp;{values.step1.company_name}
            </p>
          )}
        </div>

        <div className="Form-Info-Item">
          <h2 className="Form-Info-Item-Label">{t("nacin_dostave")}</h2>
          <p className="Form-Info-Item-Paragraf">
            {t(values.step2.receiving_way)}
          </p>
        </div>
        {values.step2.receiving_way === "na_kucnu_adresu" && (
          <div className="Form-Info-Item">
            <h2 className="Form-Info-Item-Label">Adresa isporuke</h2>

            <p className="Form-Info-Item-Paragraf">
              {values.step2.state},&nbsp;{values.step2.city},&nbsp;
              {values.step2.zip},&nbsp;{values.step2.address},&nbsp;
              {values.step2.address_addition}
            </p>
          </div>
        )}
        <div className="Form-Info-Item">
          <h2 className="Form-Info-Item-Label">{t("nacin_placanja")}</h2>
          <p className="Form-Info-Item-Paragraf">
            {t(values.step3.method_of_payment)}
          </p>
        </div>

        <div className="Form-Info-Item">
          <h2 className="Form-Info-Item-Label">Uslovi i odredbe</h2>
          <p className="Form-Info-Item-Paragraf">
            <LinkLanguage
              to={`/terms-and-conditions`}
              title="Uslovi i odredbe"
              className="Form-Info-Item-Paragraf-Link"
            >
              <IoDocumentTextOutline />
              Uslovi i odredbe
            </LinkLanguage>
          </p>
          <label>
            <Field
              type="checkbox"
              name="step4.agreement"
              onChange={(event: any) =>
                handleCheckboxChange(event, setFieldValue)
              }
            />
            {t("saglasnost")}
          </label>
          <ErrorMessage
            name="step4.agreement"
            component="div"
            className="Input-Field-Error"
          />
        </div>

        <div className="Form-Info-Item">
          <h2 className="Form-Info-Item-Label">Sadrzaj korpe</h2>
          {/* <p className="Form-Info-Item-Paragraf">fasdfa</p> */}
          <div className="Cart-Table-Order">
            <CartTable cartList={cart.list} view={true} />
          </div>
        </div>
        <div className="Form-Info-Item">
          <label className="Label-Block">* Cijene su sa uračunatim PDV-om</label>
          <label className="Label-Block">* Popust se ne obračunava na iznos poštarine</label>
        </div>

        <InputField
          name="step4.description"
          label={t("dodatna_napomena")}
          maxLength={500}
          onChange={(event: any) => {
            handleCustomChange(event, setFieldValue);
          }}
        />
        <div className="Order-Button-Div">
          {formValid.step4 ? (
            <label>Sačuvano</label>
          ) : (
            <button type="submit" className="Order-Button">
              Nastavi
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderPageStep4;
