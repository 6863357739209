import { API_IMAGES_URL } from "../../config/constants";

export const getUrlFromPhoto = (photoName:string, webpSupport:any, closestWidth:number, is_thumbnail:boolean) => {

    let filename = photoName;
    let lastIndex = filename.lastIndexOf('.');
    let extension = filename.substring(lastIndex + 1);
    let baseName = filename.substring(0, lastIndex);
    // console.log(baseName)
    // console.log(filename)
    if(is_thumbnail){
        if (webpSupport === null || baseName === null || extension === null) {
            return "nista";
        } else if (webpSupport === true && baseName !== null && extension !== null) {
            // console.log(API_IMAGES_URL + dirName + '/' + baseName + '.webp')
            return API_IMAGES_URL + baseName + '_160.webp';
        } else if (webpSupport === false && baseName !== null && extension !== null) {
            return API_IMAGES_URL + baseName + '_160.' + extension;
        } else {
            return "nista";
        }
    }else{
        if (webpSupport === null || closestWidth === 0 || baseName === null || extension === null) {
            return "nista";
        } else if (webpSupport === true && closestWidth !== 0 && baseName !== null && extension !== null) {
            // console.log(API_IMAGES_URL + dirName + '/' + baseName + '.webp')
            return API_IMAGES_URL + baseName + '_' + closestWidth + '.webp';
        } else if (webpSupport === false && closestWidth !== 0 && baseName !== null && extension !== null) {
            return API_IMAGES_URL + baseName + '_' + closestWidth + '.' + extension;
        } else {
            return "nista";
        }
    }
    
}
