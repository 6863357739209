
import LinkLanguage from "../../locales/LinkLanguage";
import { useEffectDeviceAndOrientation } from "../../utils/hooks";

import "./Logo.scss";

const Logo = ({type}:any) => {
  const [device, orientation] = useEffectDeviceAndOrientation();
  return (
    <LinkLanguage
      className={`Header-Logo-${type}`}
      to="/"
      style={
        device === "mobile" || orientation === "vertical"
          ? { justifyContent: "center" }
          : { justifyContent: "left" }
      }
    >
      {/* Desktop ili mobilni voditi racuna */}
      <img
        className="Logo-Link"
        alt="Logo Infocomp"
        src={require(`../../assets/Logo/desktop-logo.svg`).default}
      />
    </LinkLanguage>
  );
};
export default Logo;
