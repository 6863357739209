import { ErrorMessage, useField } from "formik";

import "./InputField.scss";

const InputField = ({ name, label, required, ...props }: any) => {
  const [field, meta] = useField(name);
  return (
    <div className="Input-Field">
      <label className="Input-Field-Label" htmlFor={field.name}>
        {label}
        {required === true ? (
          <label className="Input-Field-Label-Red">&nbsp;*</label>
        ) : (
          ""
        )}
      </label>
      <input
        // TODO NE POSTOJI EFFECT
        maxLength={props.maxLength ? props.maxLength : 100}
        className={`${
          meta.error && meta.touched
            ? "Input-Field-Box Input-Field-Box-Effect"
            : "Input-Field-Box"
        }`}
        autoComplete={name} //TODO CHECK
        {...field}
        {...props}
      />
      <ErrorMessage
        name={field.name}
        component="div"
        className="Input-Field-Error"
      />
    </div>
  );
};

export default InputField;
