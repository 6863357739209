import { useEffect, useState } from "react";

/* FUNKCIJA KOJA PROVERAVA EKRAN DA LI JE MOBILNI ILI DESKTOP 
I ORIENTACIJU DA LI JE PORTRAIT ILI LANDSCAPE */
export const useEffectDeviceAndOrientation = () => {
    
    const getDevice = () => {
        const hasTouch = !!navigator.maxTouchPoints;
        const highResolution = window.devicePixelRatio > 1;
        const width = window.innerWidth;
        if (hasTouch && highResolution && width >= 1024) {
            return "computer";  // Assuming touch-enabled computers have higher resolutions and wider screens
        } else if (hasTouch && width >= 768 && width < 1024) {
            // return "tablet";
            return "mobile";
        } else if (hasTouch && width < 768) {
            return "mobile";
        } else {
            return "computer";  // Default for non-touch devices with wider screens
        }
    }
    const [device, setDevice] = useState(getDevice());
    const [orientation, setOrientation] = useState(window.innerHeight > window.innerWidth ? "portrait" : "landscape");

    const handleResize = () => {
        setDevice(getDevice());
        setOrientation(window.innerHeight > window.innerWidth ? "portrait" : "landscape");
        // dispatch(putToDeviceDataAction({ device: device, orientation: orientation }))
    };

    useEffect(() => {
        window.addEventListener("DOMContentLoaded", handleResize);
        window.addEventListener("load", handleResize);
        window.addEventListener("click", handleResize);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("DOMContentLoaded", handleResize);
            window.addEventListener("load", handleResize);
            window.removeEventListener("click", handleResize);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return [device, orientation];
};

export const useEffectDeviceWidth = () => {
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    // console.log(window.innerWidth);

    const handleResize = () => {
        setDeviceWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("DOMContentLoaded", handleResize);
        window.addEventListener("click", handleResize);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("DOMContentLoaded", handleResize);
            window.removeEventListener("click", handleResize);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return [deviceWidth];

}