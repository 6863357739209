import { format } from 'date-fns';

// DATUMI
export const DATE_FORMAT = 'dd.MM.yyyy';
// export const TIME_FORMAT = 'HH:mm:ss';
// export const DATE_TIME_FORMAT = `dd.MM.yyyy HH:mm:ss`;

// FUNKCIJA KOJA PARSIRA RADNO VREME I PRIKAZUJE DANE U NEDELJI
export const getDays = (x: any) => {
  return x.week_day_name;
};

// FUNKCIJA KOJA PARSIRA RADNO VREME I PRIKAZUJE U FORMATU 00:00-00:00
export const getBusinessHours = (x: any) => {
  let businessHours = "";
  if (x.working_status === false) {
    businessHours += x.workng_status_name;
  } else {
    businessHours +=
      x.open_hour.split(":")[0] +
      ":" +
      x.open_hour.split(":")[1] +
      " - " +
      x.close_hour.split(":")[0] +
      ":" +
      x.close_hour.split(":")[1];
  }
  return businessHours;
};

export const formatDate = (date: any) : string => {
  return format(new Date(date), DATE_FORMAT);
};
