
import "../InfoPages.scss";

const AboutPage = () => {
  return (
    <div className="Page">
      <div className="Page-Content Contact">
        <h1 className="Page-Title">
          Dobrodošli u <strong>Infocomp d.o.o.</strong>
        </h1>

        <p className="Paragraf">
          Vašeg pouzdanog partnera za sve potrebe u svijetu tehnologije.
          Smješteni u srcu Bijeljine, na adresi ulica{" "}
          <strong>27. marta broj 13</strong>, već više od 10 godina kontinuirano
          gradimo našu misiju - pružiti vrhunsku uslugu i kvalitetne proizvode
          našim klijentima.
        </p>
        <p className="Paragraf">
          Sa ponosom ističemo našu tradiciju rasta i posvećenosti kvalitetu, što
          je rezultiralo našim statusom kao lidera u domenu prodaje tonera,
          elektronske zaštite od krađe - zujalica, računara i računarske opreme.
        </p>
        <p className="Paragraf">
          Takođe, naša stručna ekipa pruža{" "}
          <strong>vrhunski servis računara i printera </strong>
          kako bi vaše poslovanje bilo neprekidno i bezbrižno.
        </p>
        <p className="Paragraf">
          Uspostavili smo više kanala distribucije naše robe poput: maloprodaje,
          veleprodaje, online prodaje. Uz to smo česti učesnici na tenderima.
        </p>
        <p className="Paragraf">
          Uz glavnu lokaciju u Bijeljini, sa zadovoljstvom vam predstavljamo i
          našu{" "}
          <strong>
            podružnicu u Sarajevu, na adresi Jošanička 55, 71320 Vogošća
          </strong>
          , što nam omogućava da još bolje zadovoljimo potrebe naših klijenata
          širom Bosne i Hercegovine.
        </p>
        <p className="Paragraf">
          Jedan od naših glavnih proizvoda za koje smo zastupnik i distributer
          su zamjenski <strong>toneri kompanije Star Ink</strong>, koji su
          sinonim za kvalitet i pouzdanost.
        </p>
        <p className="Paragraf">
          Takođe, sa ponosom nudimo prodaju i ugradnju{" "}
          <strong>sigurnosnih kamera </strong> kako bismo povećali sigurnost
          vaših stambenih i poslovnih objekata.
        </p>
        <p className="Paragraf">
          Nudimo i prodaju i instalaciju <strong>solarnih panela</strong> sa
          poštovanjem svih procedura i zakonskih regulative po sistemu “ključ u
          ruke”.
        </p>
        <p className="Paragraf">
          Hvala vam što ste odabrali Infocomp d.o.o. kao vašeg partnera u
          tehnološkim potrebama. Vaša satisfakcija je naš najveći motiv.
        </p>
      </div>
    </div>
  );
};
export default AboutPage;
