import CatalogComponent from "../../../../components/catalog/CatalogComponent";

const CatalogPage = () => {
  return (
    <div className="Page">
      <div className="Page-Content">
        <div
          style={{
            width: "98% !important",
            // height: "600px",
            margin: "1% 0",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <CatalogComponent />
        </div>
      </div>
    </div>
  );
};
export default CatalogPage;
