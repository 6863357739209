import { Key, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ApartmentOutlined,
  CalendarFilled,
  ContactsOutlined,
  LoadingOutlined,
  MailFilled,
  PhoneFilled,
  UserAddOutlined,
} from "@ant-design/icons";
import { TiLocation } from "react-icons/ti";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa6";
import {
  IoDocumentTextOutline,
  IoInformationCircleOutline,
} from "react-icons/io5";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import { AppDispatch } from "../../store";
import { fetchCompanyInfo } from "../../store/companyInfo/companyInfo.slice";
import { isLogin } from "../../services/LocalStorage/LocalStorage.service";
import LinkLanguage from "../../locales/LinkLanguage";

import "./Footer.scss";
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  TIKTOK_LINK,
} from "../content/contact/ContactPageData";
import { getBusinessHours, getDays } from "../../utils/helpers/dateTime";

const Footer = () => {
  const { t } = useTranslation("dashboard", { useSuspense: false });

  const { company, companies } = useSelector((store: any) => store.companyInfo);
  const dispatch: (arg: any) => AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanyInfo());
  }, [dispatch]);

  return (
    <footer className="Footer">
      <div className="Footer-Box">
        {/* FIRST COLUMN */}
        <div className="Footer-Item">
          <div className="Footer-Item-Title">
            {t("footer.kontakt_informacije") || ""}
          </div>
          {company.is_fetch === false ? (
            <LoadingOutlined />
          ) : (
            <div className="Footer-Item-Box">
              <div className="Footer-Item-Element">
                <div className="Footer-Item-Element-Icon">
                  <TiLocation className="Footer-Icon" />
                </div>
                <div className="Footer-Item-Element-Label">
                  {/* {companies} */}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="https://www.google.com/maps/place/27.+marta+13,+Bijeljina+76300,+Bosnia+and+Herzegovina/@44.7544416,19.2166907,21z/data=!4m5!3m4!1s0x475be8e3887f3c17:0x39f8b6c578272fdf!8m2!3d44.7545326!4d19.2165754?hl=en-US"
                  >
                    <label className="Footer-Item-Element-Text Footer-Link">
                      {company.address.street_name}, broj{" "}
                      {company.address.street_number}
                    </label>
                    <label className="Footer-Item-Element-Text Footer-Link">
                      {company.address.zip}, {company.address.city_name}
                    </label>
                    <label className="Footer-Item-Element-Text Footer-Link">
                      {company.address.region_name}
                    </label>
                    <label className="Footer-Item-Element-Text Footer-Link">
                      {company.address.state_name}
                    </label>
                  </Link>
                  {/* <br /> */}
                  {/* todo zakucano, za sada sklonjeno, dok se ne doda adresa */}
                  {/* <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="https://www.google.com/maps/place/Jo%C5%A1ani%C4%8Dka+55,+Vogo%C5%A1%C4%87a+71320,+Bosnia+and+Herzegovina/@43.8995317,18.3412797,17z/data=!4m6!3m5!1s0x4758cc682abddda7:0x1a7a9b6c8e7e9b0d!8m2!3d43.8995279!4d18.3438546!16s%2Fg%2F11csmcrbv1!5m1!1e4?entry=ttu"
                  >
                    <label className="Footer-Item-Element-Text Footer-Link">
                      Jošanička, broj 55
                    </label>
                    <label className="Footer-Item-Element-Text Footer-Link">
                      71320 Vogošća, Sarajevo
                    </label>
                    <label className="Footer-Item-Element-Text Footer-Link">
                      {company.address.state_name}
                    </label>
                  </Link> */}
                </div>
              </div>
              <div className="Footer-Item-Element">
                <div className="Footer-Item-Element-Icon">
                  <PhoneFilled className="Footer-Icon" />
                </div>
                <div className="Footer-Item-Element-Label">
                  {company.telephones.map(
                    (x: any, y: Key | null | undefined) => (
                      <div className="Footer-Item-Element-Text" key={y}>
                        <a
                          href={"tel:" + x.tel_num}
                          className="Footer-Item-Element-Text2 Footer-Padding"
                        >
                          <label className="Footer-Item-Element-Text2-Label">
                            {x.owner}
                          </label>
                          <label className="Footer-Item-Element-Text2-Label">
                            {x.tel_num}
                          </label>
                        </a>
                        <br />
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="Footer-Item-Element">
                <div className="Footer-Item-Element-Icon">
                  <MailFilled className="Footer-Icon" />
                </div>
                <div className="Footer-Item-Element-Label">
                  {company.emails.map((x: any, y: Key | null | undefined) => (
                    <div
                      className="Footer-Item-Element-Text Footer-Padding"
                      key={y}
                    >
                      <a
                        href={"mailto:" + x.email}
                        className="Footer-Item-Element-Text2"
                      >
                        <label className="Footer-Item-Element-Text2-Label">
                          {x.owner}
                        </label>
                        <label className="Footer-Item-Element-Text2-Label">
                          {x.email}
                        </label>
                      </a>
                      <br />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* SECOND COLUMN */}
        <div className="Footer-Item">
          <div className="Footer-Item-Title">
            {t("footer.radno_vrijeme") || ""}
          </div>
          {company.is_fetch === false ? (
            <LoadingOutlined />
          ) : (
            <div className="Footer-Item-Box">
              <div className="Footer-Item-Element">
                <div className="Footer-Item-Element-Icon">
                  <CalendarFilled className="Footer-Icon" />
                </div>
                <div className="Footer-Item-Element-Label">
                  {company.business_hours.map(
                    (x: any, y: Key | null | undefined) => (
                      <div
                        className="Footer-Item-Element-Text2 Footer-Padding"
                        key={y}
                      >
                        <label className="Footer-Item-Element-Text2-Label">
                          {getDays(x)}
                        </label>
                        <label className="Footer-Item-Element-Text2-Label">
                          {getBusinessHours(x)}
                        </label>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* THIRD COLUMN */}
        <div className="Footer-Item">
          <div className="Footer-Item-Title">
            {t("footer.drustvene_mreze") || ""}
          </div>
          <div className="Footer-Item-Box">
            <div className="Footer-Item-Element">
              <div className="Footer-Item-Element-Icon">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={FACEBOOK_LINK}
                >
                  <FaFacebook className="Footer-Icon" />
                </Link>
              </div>
              <div className="Footer-Item-Element-Label">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={FACEBOOK_LINK}
                >
                  <div className="Footer-Item-Element-Text">Facebook</div>
                </Link>
              </div>
            </div>
            <div className="Footer-Item-Element">
              <div className="Footer-Item-Element-Icon">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={INSTAGRAM_LINK}
                >
                  <FaInstagram className="Footer-Icon" />
                </Link>
              </div>
              <div className="Footer-Item-Element-Label">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={INSTAGRAM_LINK}
                >
                  <div className="Footer-Item-Element-Text">Instagram</div>
                </Link>
              </div>
            </div>
            <div className="Footer-Item-Element">
              <div className="Footer-Item-Element-Icon">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={TIKTOK_LINK}
                >
                  <FaTiktok className="Footer-Icon" />
                </Link>
              </div>
              <div className="Footer-Item-Element-Label">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={TIKTOK_LINK}
                >
                  <div className="Footer-Item-Element-Text">TikTok</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* FOURTH COLUMN */}
        <div className="Footer-Item">
          <div className="Footer-Item-Title">
            {t("footer.preporucene_stranice") || ""}
          </div>
          <div className="Footer-Item-Box">
            <LinkLanguage className="Footer-Item-Element-Text2" to="/articles">
              <ApartmentOutlined className="Footer-Icon" />
              {t("footer.proizvodi") || ""}
            </LinkLanguage>
            <LinkLanguage className="Footer-Item-Element-Text2" to="/about">
              <IoInformationCircleOutline className="Footer-Icon" />
              {t("footer.o_nama") || ""}
            </LinkLanguage>

            {/* <LinkLanguage className="Footer-Item-Element-Text2" to="/actions">
            <TbShoppingCartDiscount className="Footer-Icon" />
              {t("footer.akcije") || ""}
            </LinkLanguage>
            <LinkLanguage
              className="Footer-Item-Element-Text2"
              to="/solar-panels"
            >
            <MdOutlineSolarPower className="Footer-Icon" />
              {t("footer.solarni_paneli") || ""}
            </LinkLanguage>
            <LinkLanguage className="Footer-Item-Element-Text2" to="/blogs">
            <BsPostcard className="Footer-Icon" />
              {t("footer.blog") || ""}
            </LinkLanguage>
            <LinkLanguage className="Footer-Item-Element-Text2" to="/partners">
            <TeamOutlined className="Footer-Icon" />
              {t("footer.partneri") || ""}
            </LinkLanguage>
            <LinkLanguage className="Footer-Item-Element-Text2" to="/catalog">
            <GrCatalog className="Footer-Icon" />
              {t("footer.katalog") || ""}
            </LinkLanguage> */}

            <LinkLanguage className="Footer-Item-Element-Text2" to="/contact">
              <ContactsOutlined className="Footer-Icon" />
              {t("footer.kontakt") || ""}
            </LinkLanguage>
            <LinkLanguage
              className="Footer-Item-Element-Text2"
              to="/private-policy"
            >
              <MdOutlinePrivacyTip className="Footer-Icon" />
              {t("footer.politika_privatnosti") || ""}
            </LinkLanguage>
            <LinkLanguage
              className="Footer-Item-Element-Text2"
              to="/terms-and-conditions"
            >
              <IoDocumentTextOutline className="Footer-Icon" />
              {t("footer.uslovi_i_odredbe") || ""}
            </LinkLanguage>
            <LinkLanguage className="Footer-Item-Element-Text2" to="/faq">
              <AiOutlineQuestionCircle className="Footer-Icon" />
              {t("footer.najcesca_pitanja") || ""}
            </LinkLanguage>
            {!isLogin() && (
              <>
                <LinkLanguage
                  className="Footer-Item-Element-Text2"
                  to="/registration"
                >
                  <UserAddOutlined className="Footer-Icon" />
                  {t("footer.registruj_se") || ""}
                </LinkLanguage>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="Footer-Box">
        <center>
          Copyright <b>INFOCOMP &copy;</b> {new Date().getFullYear()} Sva prava
          zadržana!
        </center>
      </div>
    </footer>
  );
};
export default Footer;
