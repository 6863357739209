import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";
import { BreadcrumbLink } from "../../../../components";
import "./BreadcrumbArticles.scss";
import { useEffectDeviceAndOrientation } from "../../../../utils/hooks";


const BreadcrumbArticles = ({ cat1, cat2, cat3, menu, getBreadcrumbsLabel }: any) => {
  const { t } = useTranslation();
  const [device, orientation] = useEffectDeviceAndOrientation();

  const getItems = () => {
    let list = [
      {
        title: <BreadcrumbLink label={t("pocetna")} path={"/"} title={t("pocetna")}/>,
      }
    ];
    let path1 = "/articles/" + cat1;
    let path2 = "/articles/" + cat1 + "/" + cat2;
    let path3 = "/articles/" + cat1 + "/" + cat2 + "/" + cat3;
    const {label1, label2, label3} = getBreadcrumbsLabel();

    if(cat3){
      list.push({ title: <BreadcrumbLink label={device === "computer" && orientation === "landscape" ? t("proizvodi") : "..."} path={"/articles"} title={t("proizvodi")} /> })
      list.push({ title: <BreadcrumbLink label={device === "computer" && orientation === "landscape" ? label1 : "..."} path={path1} title={label1}/> });
      list.push({ title: <BreadcrumbLink label={label2} path={path2} title={label2}/> });
      return list;
    }
    if(cat2){
      list.push({ title: <BreadcrumbLink label={device === "computer" && orientation === "landscape" ? t("proizvodi") : "..."} path={"/articles"} title={t("proizvodi")} /> })
      list.push({ title: <BreadcrumbLink label={label1} path={path1} title={label1}/> });
      return list;
    }
    if(cat1){
      list.push({ title: <BreadcrumbLink label={t("proizvodi")} path={"/articles"} title={t("proizvodi")} /> })
    }else{
      list = []
    }

    return list;
  };

  return (
    <div className="Breadcrumb-Articles">
      {menu.is_fetch === true && <Breadcrumb items={getItems()} />}
    </div>
  );
};
export default BreadcrumbArticles;
