import {  createSlice } from "@reduxjs/toolkit";
import { addCartToLocalStorage, getCartFromLocalStorage } from "../../services/LocalStorage/LocalStorage.service";
// import { toast } from 'react-toastify';

export const cartInitialState = getCartFromLocalStorage() != null ?
    getCartFromLocalStorage() :
    {
        loading: false,
        list: [],
        meta: {},
        total_price: 0.00,
        total_count: 0,
        total_item_count: 0,
        total_discount: 0, //% popust ako ima neki kod ili na ukupno sve 
        final_price: 0.00 //konacna cena sa popustom na sve 
    }

const CartSlice = createSlice({
    name: "cart",
    initialState: cartInitialState,
    reducers: {
        addArticle: (state, { payload }) => {
            // console.log(payload)
            let old_price = parseFloat(payload.old_price);
            let discount = parseInt(payload.discount);
            let price = parseFloat(payload.price);
            const slug = payload.slug;
            const name = payload.name;
            const image = payload.image;
            let quantity = payload.quantity ? payload.quantity : 1;

            let total_add_price = price * quantity;

            let exist = false;
            state.list.forEach((item) => {
                if (item.slug === payload.slug) {
                    // console.log("postoji")
                    item.old_price = old_price;
                    item.discount = discount;
                    item.price = price;
                    // item.slug = slug;
                    // item.name = name;
                    // item.image = image;
                    item.count += quantity;
                    item.total_price = item.price * item.count;
                    exist = true;
                }
            })

            if (!exist) {
                // console.log("ne postoji")
                state.list.push({
                    old_price: old_price,
                    discount: discount,
                    price: price,
                    slug: slug,
                    name: name,
                    image: image,
                    count: quantity,
                    total_price: price * quantity,
                })
                state.total_item_count += 1;
            }
            state.total_price += total_add_price;
            state.total_count += quantity;
            // TODO trenutno nista
            state.total_discount = 0 //ako korisnik ima neki kod
            state.final_price = state.total_price - state.total_price * state.total_discount / 100;

            addCartToLocalStorage(state)

        },
        removeArticle: (state, { payload }) => {

            const index = state.list.findIndex(item => item.slug === payload.item_slug);
            if (index !== -1) {
                if (payload.all_items) {
                    state.total_price -= state.list[index].price * state.list[index].count;
                    state.total_count -= state.list[index].count;
                    if (state.total_discount !== 0) {
                        state.final_price = state.total_price - state.total_price * state.total_discount / 100;
                    } else {
                        state.final_price = state.total_price;
                    }
                    state.list.splice(index, 1);
                    state.total_item_count -= 1;
                } else {
                    state.total_price -= state.list[index].price;
                    state.total_count -= 1;
                    if (state.total_discount !== 0) {
                        state.final_price = state.total_price - state.total_price * state.total_discount / 100;
                    } else {
                        state.final_price = state.total_price;
                    }
                    if (state.list[index].count === 1) {
                        // brisanje elementa iz niza   
                        state.list.splice(index, 1);
                        state.total_item_count -= 1;
                    } else {
                        state.list[index].count -= 1;
                        state.list[index].total_price = state.list[index].price;
                    }
                }

            }
            addCartToLocalStorage(state)
        },
        resetCart: (state) => {
            // Resetovanje pojedinačnih delova state-a
            state.loading = false;
            state.list = [];
            state.meta = {};
            state.total_price = 0.00;
            state.total_count = 0;
            state.total_item_count = 0;
            state.total_discount = 0;
            state.final_price = 0.00;

            // Snimanje resetovanog state-a u lokalni storage
            addCartToLocalStorage(state);
        },

    },


});


export const { addArticle, removeArticle, resetCart } = CartSlice.actions;
export default CartSlice.reducer;