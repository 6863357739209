
// FUNKCIJA KOJA DODAJE SEO NA SVAKU STRANICU

import { API_IMAGES_URL } from "../../config/constants";

// stavlja predefinisane vrednosti ako nema nista
const baseTitle = "Infocomp";
const baseDescription = "Velika ponuda od preko 10.000 artikala - desktop računari, laptop računari, telefoni, TV i kućni aparati. Mogućnost kupovine na rate.";
const baseKeywords = ["desktop računari", "laptop računari", "telefoni", "TV", "kućni aparati"];
const baseUrl = "https://infocomp.ba/";
const baseImage = "%PUBLIC_URL%/whole-logo-512-512.png";
const baseType = "website";
export const setSEO = ({ title = baseTitle, description = baseDescription, keywords = baseKeywords, url = baseUrl, image = baseImage, type = baseType, ...props }) => {
    title = (title !== null && title !== "" ? title : baseTitle) + " | Infocomp";
    description = description !== null && description !== "" ? description : baseDescription;
    keywords = keywords !== null && keywords.length !== 0 ? keywords.join() : baseKeywords.join();
    url = url !== null && url !== "" ? url : baseUrl;
    image = image !== null && image !== "" ? API_IMAGES_URL + image : baseImage;
    type = type !== null && type !== "" ? type : baseType;

    document.title = title;
    document.querySelector('meta[name="title"]').content = title;
    document.querySelector('meta[name="description"]').content = description;
    document.querySelector('meta[name="keywords"]').content = keywords;
    
    // Open Graph / Facebook
    document.querySelector('meta[property="og:title"]').content = title;
    document.querySelector('meta[property="og:description"]').content = description;
    document.querySelector('meta[property="og:url"]').content = url;
    document.querySelector('meta[property="og:image"]').content = image;
    document.querySelector('meta[property="og:type"]').content = type;

    // Twitter
    document.querySelector('meta[property="twitter:title"]').content = title;
    document.querySelector('meta[property="twitter:description"]').content = description;
    document.querySelector('meta[property="twitter:image"]').content = image;
    document.querySelector('meta[property="twitter:url"]').content = url;

    // Meta React Helmet
    document.querySelector('title[data-react-helmet="true"]').content = title;
    document.querySelector('meta[data-react-helmet="true"][name="title"]').content = title;
    document.querySelector('meta[data-react-helmet="true"][name="description"]').content = description;
    document.querySelector('meta[data-react-helmet="true"][name="keywords"]').content = keywords;
    document.querySelector('meta[data-react-helmet="true"][property="og:site_name"]').content = url;
    document.querySelector('meta[data-react-helmet="true"][property="og:url"]').content = url;
    document.querySelector('meta[data-react-helmet="true"][property="og:title"]').content = title;
    document.querySelector('meta[data-react-helmet="true"][property="og:description"]').content = description;
    document.querySelector('meta[data-react-helmet="true"][property="og:type"]').content = type;
    document.querySelector('meta[data-react-helmet="true"][property="og:image"]').content = image;
    
}



