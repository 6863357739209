import customAxiosInterceptor from "../customAxiosInterceptor";

// Fetch article detail /api/article/{slug}
export const fetchArticleDetailThunk = async (articleSlug, thunkAPI) => {
  try {
    const response = await customAxiosInterceptor.get(`/article/${articleSlug}`);
    return response.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};
// /api/article/{slug}/article-attribute
export const fetchArticleAttributeThunk = async (articleSlug, thunkAPI) => {
  try {
    const response = await customAxiosInterceptor.get(`/article/${articleSlug}/article-attribute`);
    return response.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};