import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import { Key, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

import { MapMarker } from "../../../components";
import {
  CONTACT_SEO,
  LOCATION_COORDINATES,
  SOCIAL_NETWORKS,
  SocialNetworkType,
} from "./ContactPageData";

import "./ContactPage.scss";
import { setSEO } from "../../../utils/helpers/seo-function";
import ContactBox from "./ContactBox";
import { fetchCompaniesInfo } from "../../../store/companyInfo/companyInfo.slice";
import { AppDispatch } from "../../../store";
import SocialNetworkLink from "./SocialNetworkLink";

// TODO skroz izmeniti zbog dve lokacije
const ContactPage = () => {
  const { t } = useTranslation();
  const dispatch: (arg: any) => AppDispatch = useDispatch();
  const { companies } = useSelector((store: any) => store.companyInfo);
  const [mapCenter, setMapCenter] = useState(LOCATION_COORDINATES);
  const [mapZoom, setMapZoom] = useState(17);

  useEffect(() => {
    dispatch(fetchCompaniesInfo());
  }, [dispatch]);

  setSEO(CONTACT_SEO);

  return (
    <div className="Page">
      <div className="Page-Content Contact">

        <div className="Map-Box">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAP_KEY || "",
              // libraries: "places",
            }}
            defaultCenter={mapCenter}
            center={mapCenter}
            defaultZoom={mapZoom}
            zoom={mapZoom}
            // onChange={({ center, zoom, bounds, marginBounds }) => {
            //   setMapCenter(center);
            //   setMapZoom(zoom)
            // }}
            // onChange={(e) => handleMapChange(e)}
            // yesIWantToUseGoogleMapApiInternals
            // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            <MapMarker
              lat={mapCenter.lat}
              lng={mapCenter.lng}
              text="Infocomp Bijeljina"
            />
            {/* <MapMarker
              lat={mapCenter.lat}
              lng={mapCenter.lng}
              text="Infocomp Sarajevo"
            /> */}
          </GoogleMapReact>
        </div>
        <div className="Contact-Box">
          {companies.is_fetch ? (
            companies.data.map((company: any, key: Key) => (
              <ContactBox company={company} key={key} />
            ))
          ) : (
            <LoadingOutlined />
          )}

          {/* Drustvene mreze */}
          <div className="Contact-Info-Box-Item">
            <h2>{t("footer.drustvene_mreze")}:</h2>
            {SOCIAL_NETWORKS.map((item: SocialNetworkType, key: Key) => (
              <SocialNetworkLink
                label={item.label}
                link={item.link}
                icon={item.icon}
                key={key}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactPage;
