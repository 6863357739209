import { message } from "antd";

// FUNKCIJA ZA SKRAĆIVANJE TEKSTA U ODNOSU NA NEKU DUŽINU. NA KRAJU DODAJE ...
export const truncateString = (str: string, length: number) => {
  const ending = "...";

  if (length == null) {
    length = 100;
  }

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

// kopiranje teksta u medjuspremnik 
export function copyToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "uspješno" : "neuspješno";
    // console.log("Kopiranje teksta je bilo " + msg);
    message.success("Kopiran link");
  } catch (err) {
    console.error("Greška pri kopiranju", err);
  }
  document.body.removeChild(textArea);
}

export const textToParagraphs = (text: string) => {
  const sentences = text.split('. ');
  const paragraphs = sentences.map(sentence => `<p>${sentence.trim()}</p>`);
  return paragraphs.join('\n');
}