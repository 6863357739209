import { BrowserRouter, Routes, Route } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute.js";
import {
  Dashboard,
  ErrorPage,
  HomePage,
  Login,
  Registration,
  VerifyPage,
  UserDetail,
  CartPage,
  ArticleDetail,
  BlogPage,
  BlogDetail,
  ContactPage,
  ArticlesCategoriesPage,
  CatalogPage,
  AboutPage,
  ActionsPage,
  PrivacyPolicy,
  TermsAndConditions,
  FAQ,
  OrderPage,
} from "../pages/index.js";
import { BreadcrumbProvider } from "../components/breadcrumb/BreadcrumbProvider";

const AppRouter = () => {
  // TODO 2 LANG
  // const DEFAULT_LANG = "en";
  // const SUPPORTED_LANGS = ["sr", "en"];

  // const useUrlLang = () => {
  //   // INFO: if `langMatch = null`, there was no match, meaning we're on a default-language route.
  //   const langMatch = useMatch(`/:lang(${SUPPORTED_LANGS.join("|")})`);

  //   const lang = langMatch?.params.lang ?? DEFAULT_LANG;
  //   const langUrlPrefix = lang === DEFAULT_LANG ? "" : `/${lang}`;
  //   const path = langMatch?.path ?? "/";

  //   return { lang, langUrlPrefix, path };
  // };
  //path={`/:lang(${SUPPORTED_LANGS.join("|")})/`}

  return (
    <BrowserRouter>
      <BreadcrumbProvider>
        <Routes>
          <Route path="/" element={<Dashboard />} errorElement={<ErrorPage />}>
            <Route index element={<HomePage />} errorElement={<ErrorPage />} />
            <Route
              path="/error"
              element={<ErrorPage />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/login"
              element={
                <ProtectedRoute valid_roles={[]} logged={false}>
                  <Login />
                </ProtectedRoute>
              }
            />
            <Route
              path="/registration"
              element={
                <ProtectedRoute valid_roles={[]} logged={false}>
                  <Registration />
                </ProtectedRoute>
              }
            />
            <Route
              path="/verify"
              element={
                <ProtectedRoute valid_roles={[]} logged={false}>
                  <VerifyPage />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/profile"
              element={
                <ProtectedRoute
                  valid_roles={[
                    "ROLE_ADMIN",
                    "ROLE_CUSTOMER",
                    "ROLE_MODERATOR",
                    "ROLE_CONTENT_MODERATOR",
                  ]}
                  logged={true}
                >
                  <UserDetail />
                </ProtectedRoute>
              }
            /> */}
            {/* unutar cartPage treba ispitati rolu */}
            <Route path="/cart" element={<CartPage />} />
            <Route path="/order" element={<OrderPage />} />

            {/* unutar ove stranice ulogovani imaju neke privilegije */}
            <Route path="/articles" element={<ArticlesCategoriesPage />} />
            <Route
              path="/articles/:cat1"
              element={<ArticlesCategoriesPage />}
            />
            <Route
              path="/articles/:cat1/:cat2"
              element={<ArticlesCategoriesPage />}
            />
            <Route
              path="/articles/:cat1/:cat2/:cat3"
              element={<ArticlesCategoriesPage />}
            />

            <Route path="/article/:articleSlug" element={<ArticleDetail />} />

            <Route path="/solar-panels" element={<BlogPage />} />
            <Route path="/solar-panels/:blog" element={<BlogDetail />} />

            <Route path="/blogs" element={<BlogPage />} />
            <Route path="/blogs/:blog" element={<BlogDetail />} />

            <Route path="/partners" element={<BlogPage />} />
            <Route path="/partners/:blog" element={<BlogDetail />} />

            <Route path="/actions" element={<ActionsPage />} />
            {/* info pages */}
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/catalog" element={<CatalogPage />} />
            <Route path="/private-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/faq" element={<FAQ />} />

            <Route path="*" element={<HomePage />} />
          </Route>
        </Routes>
      </BreadcrumbProvider>
    </BrowserRouter>
  );
};

export default AppRouter;
