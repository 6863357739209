import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  XIcon,
} from "react-share";

import "./ShareIcons.scss";
import { Avatar } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../utils/helpers/strings";
import { BASE_URL } from "../../config/constants";

// const TikTokShareButton = ({ url, title }: { url: string; title: string }) => (
//   <a
//     href={`https://www.tiktok.com/share?url=${encodeURIComponent(
//       url
//     )}&title=${encodeURIComponent(title)}`}
//     target="_blank"
//     rel="noopener noreferrer"
//     className="Contact-Info-Social-Networks"
//   >
//     <Avatar icon={<FaTiktok />} className="tiktok-icon" />
//   </a>
// );
const CopyLink = ({ url }: { url: string }) => (
  <Avatar
    icon={<CopyOutlined />}
    className="copy-icon"
    onClick={() => {
      // navigator.clipboard.writeText(url); //ovo ne radi
      copyToClipboard(url);
    }}
  />
);

const ShareIcons = ({
  url,
  title,
  summary,
}: {
  url: string;
  title: string;
  summary: string;
}) => {
  // console.log(url);
  // console.log(title);
  url = BASE_URL + url;
  // console.log(url)

  return (
    <div>
      <div className="Share-Icons-Label">Podijeli putem:</div>
      <div className="Share-Icons-List">
        <FacebookShareButton
          url={url}
          title={title}
          // hashtag={title}
          // about={summary}
          className="Contact-Info-Social-Networks"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title={title}
          // via="TwitterHandle" // Opciono, Twitter korisničko ime bez @ simbola
          hashtags={["infocomp", title]} // Opciono, niz hashtagova
          className="Contact-Info-Social-Networks"
        >
          {/* <TwitterIcon size={32} round /> */}
          <XIcon size={32} round />
        </TwitterShareButton>
        <ViberShareButton
          url={url}
          title={title}
          className="Contact-Info-Social-Networks"
        >
          <ViberIcon size={32} round />
        </ViberShareButton>
        <WhatsappShareButton
          url={url}
          title={title}
          about={summary}
          separator=":: "
          className="Contact-Info-Social-Networks"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <TelegramShareButton
          url={url}
          title={title} // Telegram koristi 'title' kao opis
          className="Contact-Info-Social-Networks"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <LinkedinShareButton
          url={url}
          title={title}
          summary={summary}
          source={BASE_URL}
          className="Contact-Info-Social-Networks"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        {/* todo Pinterest */}

        {/* <PinterestShareButton
          url={url}
          title={title}
          description={summary}
          className="Contact-Info-Social-Networks"
          media="url do slike koju zelim da delim"
        >
          <PinterestIcon size={32} round />
        </PinterestShareButton> */}
        {/* todo TikTok */}
        {/* <TikTokShareButton url={url} title={title} /> */}

        <RedditShareButton
          url={url}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className="Contact-Info-Social-Networks"
        >
          <RedditIcon size={32} round />
        </RedditShareButton>
        <EmailShareButton
          url={url}
          subject={title}
          body={summary}
          separator=" Više detalja možete videti na linku: "
          className="Contact-Info-Social-Networks"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
        <CopyLink url={url} />
      </div>
    </div>
  );
};
export default ShareIcons;
