import { Key, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons";
// import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../../store";
import { fetchMenu } from "../../../../store/articleCategory/articleCategory.slice";

import LinkLanguage from "../../../../locales/LinkLanguage";
import { PhotoDisplayHandler } from "../../../../components";

import "./MobileMenu.scss";


const MobileMenu = ({ action }: any) => {
  // const { t } = useTranslation();
  const { is_fetch, article_category } = useSelector(
    (store: { articleCategory: any }) => store.articleCategory
  );
  const dispatch: (arg: any) => AppDispatch = useDispatch();
  // const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(fetchMenu(null));
  }, [dispatch]);

  const [openItem, setOpenItem] = useState(false);
  const [openSubItem, setOpenSubItem] = useState(false);
  const [itemClicked, setItemClicked] = useState(null);
  const [subItemClicked, setSubItemClicked] = useState(null);

  const handleOpenItem = (slug: any) => {
    // console.log("ispis => " + slug);
    if (itemClicked === slug) {
      setOpenItem(!openItem);
      action();
    } else {
      setOpenItem(true);
    }
    setItemClicked(slug);
  };

  const handleOpenSubItem = (slug1: any, slug2: any) => {
    // console.log("ispis item => " + slug1);
    // console.log("ispis sub item => " + slug2);
    if (subItemClicked === slug2) {
      setOpenSubItem(!openSubItem);
      action();
    } else {
      setOpenSubItem(true);
    }
    setSubItemClicked(slug2);
  };
  // TODO SREDITI KOMPONENTU - ON CLICK NA MENI
  // imam negde scroll po x osi -> sredjeno nekako
  return (
    <div className="Mobile-Menu">
      {is_fetch === false ? (
        <LoadingOutlined />
      ) : (
        article_category.map((item: any, k: Key | null | undefined) => (
          <div className="Mobile-Menu" key={item.slug}>
            <LinkLanguage
              className="Mobile-Menu-Link"
              to={`/articles/${item.slug}`}
              //   action={action}
              action={() => handleOpenItem(item.slug)}
              
            >
              <div className="Mobile-Menu-Item">
                <div className="Mobile-Menu-Item-Icon">
                  <PhotoDisplayHandler
                    img_name={item.img_path}
                    // dir="categories"
                    calculate={false}
                    img_class_name="Mobile-Menu-Item-Photo"
                    img_alt={item.ac_name}
                    width="25px"
                    height="25px"
                  />
                </div>
                <div className="Mobile-Menu-Item-Label">{item.ac_name}</div>
                <div className="Mobile-Menu-Item-Button">
                  {openItem && itemClicked === item.slug ? (
                    <UpOutlined />
                  ) : (
                    <DownOutlined />
                  )}
                </div>
              </div>
            </LinkLanguage>
            {openItem && itemClicked === item.slug && (
              <div className="Mobile-Menu-Sub-Item">
                {item.subcategories.map((child: any, ck: Key | null | undefined) =>
                  !child.subcategories ? (
                    <div className="Mobile-Menu-Sub-Item" key={child.slug}>
                      <LinkLanguage
                        className="Mobile-Sub-Menu-Link"
                        // to="/"
                        to={`/articles/${item.slug}/${child.slug}`}
                        //   action={action}
                        action={() => handleOpenSubItem(item.slug, child.slug)}
                       
                      >
                        <div className="Mobile-Menu-Item">
                          <div className="Mobile-Menu-Item-Icon">
                            <PhotoDisplayHandler
                              img_name={child.img_path}
                              // dir="categories"
                              calculate={false}
                              img_class_name="Mobile-Menu-Item-Photo"
                              img_alt={child.ac_name}
                              width="25px"
                              height="25px"
                            />
                          </div>
                          <div className="Mobile-Menu-Item-Label">
                            {child.ac_name}
                          </div>
                          <div className="Mobile-Menu-Item-Button">
                            {openSubItem && subItemClicked === child.slug ? (
                              <UpOutlined />
                            ) : (
                              <DownOutlined />
                            )}
                          </div>
                        </div>
                      </LinkLanguage>
                      {openSubItem && subItemClicked === child.slug && (
                        <div className="Mobile-Menu-Sub-Item">
                          {child.subcategories.map(
                            (final: any, fk: Key | null | undefined) => (
                              <LinkLanguage
                                className="Mobile-Menu-Link"
                                to={`/articles/${item.slug}/${child.slug}/${final.slug}`}
                                action={action}
                                key={final.slug}
                              >
                                <div className="Mobile-Menu-Item-Type">
                                  <div className="Mobile-Menu-Item-Type-Icon">
                                    <PhotoDisplayHandler
                                      img_name={final.img_path}
                                      // dir="product_types"
                                      calculate={false}
                                      img_class_name="Mobile-Menu-Item-Photo"
                                      img_alt={final.ac_name}
                                      width="25px"
                                      height="25px"
                                    />
                                  </div>
                                  <div className="Mobile-Menu-Item-Type-Label">
                                    {final.ac_name}
                                  </div>
                                </div>
                              </LinkLanguage>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <LinkLanguage
                      className="Mobile-Menu-Link"
                      to={`/articles/${item.slug}/${child.slug}`}
                      action={action}
                      key={child.slug}
                    >
                      <div className="Mobile-Menu-Item-Type">
                        <div className="Mobile-Menu-Item-Type-Icon">
                          <PhotoDisplayHandler
                            img_name={child.img_path}
                            // dir="categories"
                            calculate={false}
                            img_class_name="Mobile-Menu-Item-Photo"
                            img_alt={child.ac_name}
                            width="25px"
                            height="25px"
                          />
                        </div>
                        <div className="Mobile-Menu-Item-Type-Label">
                          {child.ac_name}
                        </div>
                      </div>
                    </LinkLanguage>
                  )
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default MobileMenu;
