import type { PaginationProps } from "antd";
import { Pagination } from "antd";

import "./PaginationComponent.scss";

const PaginationComponent = ({
  current,
  totalItems,
  pageSize,
  changePageSize,
}: any) => {
  const onChange: PaginationProps["onChange"] = (page, size) => {
    changePageSize(page, size);
  };
  // const showTotal: PaginationProps["showTotal"] = (total) => (
  //   <label>Ukupno {total}</label>
  // );
  // if (totalItems <= pageSize ){
  //   return <></>
  // }
  return (
    <div className="Pagination">
      {/* current: Trenutna stranica.
      total: Ukupan broj stavki.
      pageSize: Broj stavki po stranici.
      onChange: Funkcija koja se poziva kada se promijeni stranica. 
      Ova funkcija treba da prima novu stranicu i veličinu stranice kao argumente. */}
      <Pagination
        current={current}
        onChange={onChange}
        total={totalItems}
        defaultPageSize={12}
        pageSizeOptions={["12", "24", "48", "96"]}
        pageSize={pageSize}
        showSizeChanger={true}
        onShowSizeChange={(page, size) => {
          changePageSize(page, size);
        }}
        // showQuickJumper
        // locale={{ jump_to: "Idi na", page: "Stranicu" }}
        // showTotal={showTotal}
      />
    </div>
  );
};
export default PaginationComponent;
