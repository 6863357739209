import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchBlogs } from "../../../store/blogs/blogs.slice";
import { PaginationComponent, PhotoDisplayHandler } from "../../../components";
import { truncateString } from "../../../utils/helpers/strings";

import "./BlogPage.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/helpers/dateTime";

const BlogPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { loading, list } = useSelector((store: any) => store.blogs);
  const dispatch: any = useDispatch();

  const getTitle = () => {
    let path = pathname.replace("/", "");
    let title = t("blog");
    switch (path) {
      case "solar-panels":
        title = t("solarni_paneli");
        break;
      case "blogs":
        title = t("blog");
        break;
      case "partners":
        title = t("partneri");
        break;
    }
    return title;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  const changePageSize = (page: any, take: any) => {
    setCurrentPage(page);
    setPageSize(take);
  };
  useEffect(() => {
    changePageSize(1, 12);
  }, [pathname]);

  useEffect(() => {
    let path = pathname.replace("/", "");
    let name = "";
    switch (path) {
      case "solar-panels":
        name = t("solarni_paneli");
        break;
      case "blogs":
        name = "blog";
        break;
      case "partners":
        name = "partneri";
        break;
    }

    dispatch(
      // TODO ORDER polje zakucano, trenutno nista
      fetchBlogs({
        page: currentPage,
        take: pageSize,
        order: "ASC",
        post_type: name,
      })
    );
  }, [dispatch, currentPage, pageSize, pathname]);

  return (
    <div className="Page">
      <div className="Page-Content">
        {loading === false ? (
          <>
            <div className="Page-Line">
              <h1 className="Page-Title">{getTitle()}</h1>
            </div>
            {list.data.length !== 0 ? (
              <>
                <div className="Blog-Container">
                  {list.data.map((post: any, y: any) => (
                    <div
                      className="Blog-Item"
                      key={post.slug}
                      onClick={() => navigate(`${pathname}/${post.slug}`)}
                    >
                      <div className="Blog-Box-Item">
                        <div className="Blog-Item-Image-Box">
                          <PhotoDisplayHandler
                            img_name={post.background_image}
                            calculate={false}
                            img_class_name="Blog-Item-Image"
                            img_alt={post.post_title}
                            width="96%"
                            height="auto"
                          />
                        </div>
                        <div className="Blog-Item-Info-Box">
                          <div className="Blog-Item-Info">
                            <div className="Blog-Item-Info-Title">
                              {post.post_title}
                            </div>
                            <div className="Blog-Item-Info-Text">
                              {truncateString(post.post_description, 150)}
                            </div>
                            <div className="Blog-Item-Info-Date">
                              {formatDate(post.created_at)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <PaginationComponent
                    current={currentPage}
                    setCurrent={setCurrentPage}
                    totalItems={list.meta.item_count}
                    pageSize={pageSize}
                    changePageSize={changePageSize}
                  />
                </div>
              </>
            ) : (
              <label>Trenutno nema objava.</label>
            )}
          </>
        ) : (
          <LoadingOutlined />
        )}
      </div>
    </div>
  );
};
export default BlogPage;
