import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMenuThunk } from "./articleCategory.actions";
// import { toast } from 'react-toastify';

export const fetchMenu = createAsyncThunk('articleCategory/fetchMenu', fetchMenuThunk);

export const menuInitialState = {
    is_fetch: false,
    article_category: [],
    active_category: null
}

const ArticleCategorySlice = createSlice({
    name: "articleCategory",
    initialState: menuInitialState,
    reducers: {
        addActiveCategory: (state, { payload }) => {
            state.active_category = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenu.fulfilled, (state, { payload }) => {
                // console.log(payload)
                // payload.map((item)=> {console.log(item.pc_name)})
                state.article_category = payload;
                state.is_fetch = true;
                // return { ...state, loading: false, article_category: payload };
            })
            .addCase(fetchMenu.pending, (state) => {
                return { ...state, is_fetch: false };
            })
            .addCase(fetchMenu.rejected, (state) => {
                return { ...state, is_fetch: false };
            });
    }

});



export default ArticleCategorySlice.reducer;