import { LoadingOutlined } from "@ant-design/icons";

import PaginationComponent from "../../../../components/pagination/PaginationComponent";

import ArticleCard from "../../article/ArticleCard";

import "./Articles.scss";

const Articles = ({
  currentPage,
  totalItems,
  pageSize,
  changePageSize,
  filteredArticles,
}: any) => {
  return (
    <div className="Articles">
      {filteredArticles.is_fetch === false ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="Aricles-Container">
            {filteredArticles.list.length === 0 ? (
              <label>Nema takvih proizvoda</label>
            ) : (
              filteredArticles.list.map((article: any, id: number) => {
                return (
                  <div className="Aricle-Item" key={article.article_slug}>
                    <ArticleCard article={article} />
                  </div>
                );
              })
            )}
          </div>
          <PaginationComponent
            current={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            changePageSize={changePageSize}
          />
        </>
      )}
    </div>
  );
};
export default Articles;
