import customAxiosInterceptor from "../customAxiosInterceptor";
import {
  CompanyResponse,
  ErrorResponse,
} from "./CompanyInfoType";

// Fetch store information
// GET https://api.infocomp.ba/api/store/1/info
export const fetchCompanyInfoThunk = async (_: void, thunkAPI: any) => {
  try {
    const response = await customAxiosInterceptor.get<CompanyResponse>(
      "/store/1/info"
    );

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue((error.response.data as ErrorResponse).msg);
  }
};

// Fetch store information
// GET https://api.infocomp.ba/api/store/info
export const fetchCompaniesInfoThunk = async (_: void, thunkAPI: any) => {
  try {
    const response = await customAxiosInterceptor.get<CompanyResponse[]>(
      "/store/info"
    );
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue((error.response.data as ErrorResponse).msg);
  }
};
