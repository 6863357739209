
import "./ActionsPage.scss";

const ActionsPage = () => {

  return (
    <div className="Page">
      <div className="Page-Content Contact">
        <h1 className="Page-Title">Stranica u izradi</h1>
        {/* TODO proizvodi na akciji, nije ni miroslav uradio */}
      </div>
    </div>
  );
};
export default ActionsPage;
