import { Form, Formik } from "formik";
import { object, string } from "yup";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  resendVerify,
  verify,
} from "../../../store/authentication/authentication.slice";
import { InputField } from "../../../components";

import "../../../components/form/FormikForm.scss";
import "./VerifyPage.scss";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const VerifyPage = () => {
  const dispatch: any = useDispatch();
  let query = useQuery();
  const token = query.get("token") ? query.get("token") : null;
  const navigate = useNavigate();
  const { verified, is_loading } = useSelector(
    (store: any) => store.auth.verification
  );
  const [clickedResend, setClickedResend] = useState(false);
  const [checkedToken, setCheckedToken] = useState(false);

  const ResendValidation = object().shape({
    email: string().required("Unesite email").email("Email mora biti validan"),
    password: string().min(8, "Najmanje 8 cifara").required("Unesite lozinku"),
  });

  const handleResend = (values: any, { setSubmitting }: any) => {
    // console.log(values);
    dispatch(resendVerify(values)).then(() => {
      setSubmitting(false);
      navigate("/login");
    });
  };
  useEffect(() => {
    if (checkedToken === false) {
      // setCheckedToken(true)
      dispatch(verify(token)).then(() => setCheckedToken(true));
    }
  }, [dispatch, checkedToken, token]);

  return (
    <div className="Form-Box">
      <div className="Form">
        {!is_loading && <h1 className="Form-Title">Verifikacija</h1>}
        {!is_loading && verified ? (
          <div className="Verify-Page-Item">
            <CheckCircleOutlined className="Verify-Page-Icon Success" />
            <label className="Verify-Page-Label Success">
              Uspešna verifikacija{" "}
            </label>
            <button
              onClick={() => navigate("/login")}
              className="Verify-Page-Button"
            >
              Prijavi se
            </button>
          </div>
        ) : !clickedResend ? (
          <div className="Verify-Page-Item">
            <CloseCircleOutlined className="Verify-Page-Icon Failed" />
            <label className="Verify-Page-Label Failed">
              Neuspešna verifikacija
            </label>
            <button
              onClick={() => setClickedResend(!clickedResend)}
              className="Verify-Page-Button"
            >
              Pošalji ponovo
            </button>
          </div>
        ) : (
          <div className="Verify-Page-Item">
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={ResendValidation}
              onSubmit={handleResend}
            >
              {(formikProps) => (
                // withFormikDevtools(formikProps,
                <Form className="Formik-Form">
                  <InputField name="email" label="Email" required={true} />
                  <InputField
                    name="password"
                    label="Lozinka"
                    type="password"
                    required={true}
                  />
                  <button
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    className="Form-Button"
                  >
                    Pošalji ponovo
                  </button>
                </Form>
                //  )
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};
export default VerifyPage;
