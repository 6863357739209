export enum SortOrder {
  NameAsc = "name_asc",
  NameDesc = "name_desc",
  PriceAsc = "price_asc",
  PriceDesc = "price_desc",
  RateAsc = "rate_asc",
  PopularAsc = "popular_asc",
  Default = "default"
}

export interface MenuItem {
  label: string;
  key: SortOrder;
}
