import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa6";
import { BASE_URL } from "../../../config/constants";

export const FACEBOOK_LINK =
  "https://www.facebook.com/profile.php?id=61554759416892&mibextid=LQQJ4d";
export const INSTAGRAM_LINK = "https://www.instagram.com/infocomp_doo/?hl=en";
export const TIKTOK_LINK = "https://www.tiktok.com/@infocomp_doo";

export type SocialNetworkType = {
  label: string;
  link: string;
  icon: React.ReactNode;
};
export const SOCIAL_NETWORKS = [
  {
    label: "Facebook",
    link: FACEBOOK_LINK,
    icon: <FaFacebook />,
  },
  {
    label: "Instagram",
    link: INSTAGRAM_LINK,
    icon: <FaInstagram />,
  },
  {
    label: "TikTok",
    link: TIKTOK_LINK,
    icon: <FaTiktok />,
  },
];

export const LOCATION_COORDINATES = {
  //44.754240293259826, 19.21593051459994
  lat: 44.754240293259826,
  lng: 19.21593051459994,
};

export const CONTACT_SEO = {
  title: "Kontakt",
  description:
    "27. mart, broj 12 76310, Bijeljina, Republika Srpska, Bosna i Hercegovina | Jošanička, broj 55, 71320 Vogošća, Sarajevo, Bosna i Hercegovina | office@infocomp.ba | ",
  keywords: [
    "infocomp",
    "bijeljina",
    "sarajevo",
    "prodaja",
    "lokacije",
    "kontakt",
  ],
  url: BASE_URL + "/contact",
  image: "",
  type: "kontakt",
};
