import { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";

import DropdownMenu from "./toolbar/DropdownMenu";
import Toolbar from "./toolbar/Toolbar";
import OutsideAlerter from "./burger/OutsideAlerter";
import Burger from "./burger/Burger";


import "./Menu.scss";
import { useEffectDeviceAndOrientation } from "../../../utils/hooks";


const Menu = () => {
  const [device, orientation] = useEffectDeviceAndOrientation();

  const getMenu = () => {
    if (device === "computer" && orientation === "landscape") {
      return "Dropdown";
    } else if (device === "computer" && orientation === "portrait") {
      return "Burger";
    } else if (device === "mobile") {
      return "Burger";
    } else {
      return "Burger";
    }
  };

  const [openBurger, setOpenBurger] = useState(false);
  const showBurger = () => {
    let backdrop = document.getElementsByClassName("Backdrop")[0] as unknown as HTMLElement;
    let burgerMenu = document.getElementsByClassName("Burger-Nav")[0] as unknown as HTMLElement;
    backdrop.style.backgroundColor = "#979292";
    backdrop.style.zIndex = "500";
    backdrop.style.display = "block";
    burgerMenu.style.zIndex = "200";
    setOpenBurger(true);
  };
  const closeBurger = () => {
    let backdrop = document.getElementsByClassName("Backdrop")[0] as unknown as HTMLElement;
    let burgerMenu = document.getElementsByClassName("Burger-Nav")[0] as unknown as HTMLElement;
    backdrop.style.backgroundColor = "transparent";
    burgerMenu.style.zIndex = "0";
    backdrop.style.zIndex = "-5";
    backdrop.style.display = "none";
    setOpenBurger(false);
  };

  return (
    <div className={getMenu()}>
      {getMenu() === "Dropdown" ? (
        <DropdownMenu />
      ) : (
        <div className="Burger-Button" onClick={showBurger}>
          <MenuOutlined className="Burger-Button-Icon" />
        </div>
      )}
      {getMenu() === "Dropdown" ? (
        <Toolbar />
      ) : openBurger === true ? (
        <OutsideAlerter close={closeBurger}>
          <Burger openBurger={openBurger} closeBurger={closeBurger} />
        </OutsideAlerter>
      ) : (
        <Burger openBurger={openBurger} />
      )}
    </div>
  );
};
export default Menu;
