import { useTranslation } from "react-i18next";
import LinkLanguage from "../../../../locales/LinkLanguage";
import "../InfoPages.scss";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <div className="Page">
      <div className="Page-Content Contact">
        <h1 className="Page-Title">{t("uslovi_i_odredbe")}</h1>

        <h2 className="Page-Subtitle">Uvod</h2>
        <p className="Paragraf">
          Preduzeće Infocomp DOO putem ovog online servisa pruža mogućnost
          kupovine tehničkih artikala, audio i video opreme, bijele tehnike,
          računara i računarske opreme, te srodnih artikala. Pored navedenog,
          ova usluga obezbjeđuje pružanje informacija, korisničku podršku, te
          sprovođenje finansijskih transakcija. Korisnici usluga ovog web sajta
          dužni su isti koristiti na način koji ne ozleđuje ili materijalno
          oštećuje Vas, nas ili neko treće lice. Svaka neodgovorna ili nelegalna
          upotreba može biti propraćena sudskom tužbom.
        </p>

        <h2 className="Page-Subtitle">Pravila korišćenja</h2>
        <p className="Paragraf">
          Svi opštevažeći zakoni Bosne i Hercegovine važe na e-prodavnici
          www.infocomp.ba. Pored navedenog, korisnik usluga e-prodavnice je
          upoznat da postoje određena pravila koja važe za sve korisnike i
          specifična su za ovu e-prodavnicu. Naš servis zadržava pravo izmjene,
          dodavanja ili brisanja ovih pravila i ostalog sadržaja bez prethodne
          najave. Pravila postaju važeća onog momenta kada budu objavljena na
          našem sajtu.
        </p>

        <h2 className="Page-Subtitle">Osnovni uslovi korišćenja</h2>
        <p className="Paragraf">
          Pri registraciji ili slanju narudžbe dužni ste da se koristite tačnim
          i istinitim podacima. Naš webshop štiti Vaše privatne podatke
          (privatni email, Vaše ime i prezime i ostale podatke). Upisivanjem
          podataka u registracionu / narudžbenu formu saglasni ste da pristup ovim
          podacima ima osoblje našeg servisa uz prava upotrebe, definisana kroz
          &nbsp;
          <em>
            <LinkLanguage to="/terms-and-conditions">
              Uslove korišćenja
            </LinkLanguage>
          </em>
          &nbsp;i&nbsp;
          <em>
            <LinkLanguage to="/private-policy">
              {t("politika_privatnosti")}
            </LinkLanguage>
          </em>
          . Član našeg tima će, nakon primljene narudžbe, pozvati upisani
          telefonski broj u svrhu potvrde iste. Od trenutka potvrde narudžbe,
          naš servis preuzima obavezu za isporuku naručene robe preko vlastitih
          transportnih sredstava ili ovlašćenih kurirskih službi u BiH.
        </p>
        <h2 className="Page-Subtitle">Autorska prava</h2>
        <p className="Paragraf">
          Pri korišćenju webshop-a obavezni ste poštovati Zakon o autorskim
          pravima i srodne Zakone koji važe u Bosni i Hercegovini. Autorska
          prava se odnose na tekstualni sadržaj, slike, logoe, ilustracije i sav
          ostali sadržaj koji je podložan autorskim pravima. Korištenjem
          podataka u druge svrhe, kao i kopiranjem, prepisom, distribuiranjem
          podataka, bez odobrenja od strane našeg osoblja, postupate protivno
          Zakonu o autorskom pravu i srodnim pravima u BiH, te podliježete
          sankcijama.
        </p>
        <h2 className="Page-Subtitle">Cijene</h2>
        <p className="Paragraf">
          Sve cijene na e-prodavnici www.infocomp.ba su izražene u
          Konvertibilnim markama (KM). Cijena je određena za svaki proizvod
          pojedinačno, osim za grupisane proizvode na kojima je ovo jasno
          naznačeno. Sve cijene su izražane sa uračunatim PDV-om i/ili popustom.
        </p>
        <h2 className="Page-Subtitle">Sankcionisanje</h2>
        <p className="Paragraf">
          Zadržavamo pravo da bez prethodne najave iz upotrebe usluga našeg
          portala trajno isključimo naloge korisnika koji djeluju protivno
          uslovima korišćenja na bilo koji način. Svako kopiranje sadržaja,
          objavljivanje eksplicitnog sadržaja, lobiranje, reklamiranje i ostalo
          neprihvatljivo ponašanje može biti sankcionisano prethodnim pravilom.
          Nepoštovanje navedenih pravila može biti propraćeno sudskom tužbom.
        </p>
        <br/>
      </div>
    </div>
  );
};
export default TermsAndConditions;
