
import MenuLink from "../MenuLink";

import "./Toolbar.scss";

const Toolbar = () => {
  return (
    <div className="Toolbar-Nav">
      <ul className="Toolbar-Menu">
        <MenuLink/>
      </ul>
    </div>
  );
};
export default Toolbar;
