import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchBlogsThunk, fetchBlogBySlugThunk } from "./blogs.actions";
// import { toast } from 'react-toastify';

export const fetchBlogs = createAsyncThunk('blogs/fetchBlogs', fetchBlogsThunk);
export const fetchBlogBySlug = createAsyncThunk('blogs/fetchBlogBySlug', fetchBlogBySlugThunk);

export const blogsInitialState = {
    loading: false,
    list: {
        data: [],
        totalItems: null,
        totalPages: null
    },
    blogItem: {
        is_fetch: false,
        data: {}
    }

}

const BlogsSlice = createSlice({
    name: "blogs",
    initialState: blogsInitialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogs.fulfilled, (state, { payload }) => {
                // console.log(payload)
                return { ...state, loading: false, list: payload };
            })
            .addCase(fetchBlogs.pending, (state) => {
                return { ...state, loading: true };
            })
            .addCase(fetchBlogs.rejected, (state) => {
                return { ...state, loading: false };
            })
            .addCase(fetchBlogBySlug.fulfilled, (state, { payload }) => {
                // console.log(payload)
                return {
                    ...state, blogItem: {
                        is_fetch: true,
                        data: payload
                    }
                };
            })
            .addCase(fetchBlogBySlug.pending, (state) => {
                return {
                    ...state, blogItem: {
                        is_fetch: false,
                        data: {}
                    }
                };
            })
            .addCase(fetchBlogBySlug.rejected, (state) => {
                return {
                    ...state, blogItem: {
                        is_fetch: false,
                        data: {}
                    }
                };
            })
            ;
    }

});



export default BlogsSlice.reducer;