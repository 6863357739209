import { useTranslation } from "react-i18next";
import "../InfoPages.scss";

const FAQ = () => {
  const { t } = useTranslation();
  return (
    <div className="Page">
      <div className="Page-Content Contact">

        <h1 className="Page-Title">{t("najcesca_pitanja")}</h1>
        {/* <ul className="Order-List">
          <li className="Order-List-Item">
          Kako se registrovati na infocomp.ba?
          <p className="Paragraf">dfasdfasfa</p>
          </li>
          <li className="Order-List-Item">
          Kako se registrovati na infocomp.ba?
          <p className="Paragraf">dfasdfasfa</p>
          </li>
        </ul> */}

        <h2 className="Page-Subtitle">
          1. Kako se registrovati na infocomp.ba?
        </h2>
        <p className="Paragraf">
          Sam proces registracije je vrlo jednostavan i kratak. Klik na polje
          “registruj se” u donjem desnom uglu na sajtu. Od podataka potrebno je
          da unesete(ime, prezime, email i vašu lozinku). Nakon toga će vam
          stići mejl potvrde o registraciji.{" "}
        </p>

        <h2 className="Page-Subtitle">2. Mogu li kupovati bez registracije?</h2>
        <p className="Paragraf">
          Možete, samo dodajte željeni artikal u korpu te nakon toga kliknete na
          polje “Vaša korpa" u gornjem desnom dijelu ekrana, te nakon toga na
          “Plaćanje". Tu unesite vaše podatke, ne zaboravite nikako ime i
          prezime te broj telefona, nakon čega će vas naše kolege pozvati da
          potvrde narudžbu. Molimo Vas za provjeru ispravnosti podatak prije
          nego što naručite artikle.
        </p>

        <h2 className="Page-Subtitle">
          3. Kako da znamo da li je naša narudžba uspješna?
        </h2>
        <p className="Paragraf">
          Nakon svake uspješne narudžbe, automatsku potvrdu o narudžbi dobićete
          na vaš e-mail, gdje će pisati i tačan kod (code) narudžbe. Ako niste
          dobili potvrdu na mail, narudžba nije uspješna. Sve artikle možete
          naručiti i putem telefonskog broja: +387 55 220 420.
        </p>

        <h2 className="Page-Subtitle">4. Gdje se nalazimo?</h2>
        <p className="Paragraf">
          Naš maloprodajni objekat se nalazi u Bijeljini u ulici 27. marta broj
          13. Radno vrijeme maloprodajnog objekta je radnim danima od 08-16h, a
          subotom od 08-15h.
        </p>
        <h2 className="Page-Subtitle">
          5. Mogu li naručivati iz drugih država?
        </h2>
        <p className="Paragraf">
          NE! Infocomp d.o.o. šalje pakete u isporuku ISKLJUČIVO na teritoriji
          Bosne i Hercegovine. Kupac koji će preuzeti i platiti artikl mora se
          nalaziti na teritoriji Bosne i Hercegovine.
        </p>

        <h2 className="Page-Subtitle">6. Kako se vrši plaćanje?</h2>
        <p className="Paragraf">
          Plaćanje nakon kupovine u našem online shopu vršite prilikom
          preuzimanja artikla na adresi koju ste naznačili u narudžbi. Takođe,
          postoji mogućnost i kartičnog plaćanja.
        </p>

        <h2 className="Page-Subtitle">7. Koji je minimalni iznos narudžbe?</h2>
        <p className="Paragraf">
          Minimalnog iznosa za narudžbe nema, ali Vi kao kupac plaćate dostavu,
          osim ako je ukupan iznos narudžbe preko 200KM, tada troškove dostave
          snosi Infocomp d.o.o.
        </p>

        <h2 className="Page-Subtitle">8. Kako otkazati narudžbu?</h2>
        <p className="Paragraf">
          Ako vam pošiljka već nije poslana, možete je otkazati na broj telefona
          +387 55 220 420 svakim radnim danom od 08-16h.
        </p>

        <h2 className="Page-Subtitle">9. Koliko traje dostava?</h2>
        <p className="Paragraf">
          Ako artikal koji ste poručili imamo na stanju, rok za isporuku je od
          1-3 dana. U koliko za naznačeni period ne primite naručeni paket,
          kontaktirajte nas na broj telefona +387 55 220 420, da bismo
          ustanovili i što prije riješili problem.
        </p>
        <br/>
        <p>
          <p>
            <strong>
              ZA SVA DODATNA PITANJA KONTAKTIRAJTE NAS NA EMAIL:
              marketing@infocomp.ba
            </strong>
          </p>
          <p>
            <strong>ILI NA BROJ TELEFONA: +387 55 220 420,</strong>
          </p>
          <p>
            <strong>NAŠ TIM ĆE SE POTRUDITI U ŠTO KRAĆEM DA VAM DA TRAŽENE INFORMACIJE!</strong>
          </p>
        </p>
        <br/>
      </div>
    </div>
  );
};
export default FAQ;
