
import { Key} from "react";
import { LoadingOutlined } from "@ant-design/icons";

import CategoryItem from "./CategoryItem";

import "./CategoriesPage.scss";

const CategoriesPage = ({cat1, cat2, cat3, menu, getCurrentCategoryName}:any) => {

  const getList = () => {
    if (cat3 !== undefined) {
      return [];
    } else if (cat2 !== undefined) {
      return menu.article_category.filter((x: any) => x.slug === cat1)[0]
        ? menu.article_category
            .filter((x: any) => x.slug === cat1)[0]
            .subcategories.filter((y: any) => y.slug === cat2)[0]
          ? menu.article_category
              .filter((x: any) => x.slug === cat1)[0]
              .subcategories.filter((y: any) => y.slug === cat2)[0]
              .subcategories
          : []
        : [];
    } else if (cat1 !== undefined) {
      return menu.article_category.filter((x: any) => x.slug === cat1)[0]
        ? menu.article_category.filter((x: any) => x.slug === cat1)[0]
            .subcategories
        : [];
    } else {
      return menu ? menu.article_category : [];
    }
  };

  return (
    <>
      {menu.is_fetch === false ? (
        <LoadingOutlined />
      ) : (
        <>
          <h1 className="Page-Title">{getCurrentCategoryName()}</h1>
          <div className="Categories">
            <div className="Categories-Box">
              {getList().map((item: any, y: Key | null | undefined) => (
                <CategoryItem key={item.slug} item={item} />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default CategoriesPage;
