import customAxiosInterceptor from "../customAxiosInterceptor";
import { FetchSlidersResponse, Slider, ErrorResponse } from "./SliderType";

// Fetch sliders
// GET https://api.infocomp.ba/api/slider
export const fetchSlidersThunk = async (
  _: void,
  thunkAPI: any
): Promise<Slider[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
  try {
    const response = await customAxiosInterceptor.get<FetchSlidersResponse>(
      "/slider"
    );
    return response.data.data;
  } catch (error: any) {
    const errorResponse = error.response.data as ErrorResponse;
    return thunkAPI.rejectWithValue(errorResponse.msg);
  }
};
