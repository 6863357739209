import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearStoreThunk } from "./basic.actions";
import { toast } from 'react-toastify';
import { showToast } from "../../utils/helpers/toastHelper";

export const clearStore = createAsyncThunk('basic/clearStore', clearStoreThunk);


const initialState = {
    webpSupport: null
}

const BasicSlice = createSlice({
    name: "basic",
    initialState,
    reducers: {
        clearValues: (state, { payload }) => {
            state.basic = initialState;
            // removeUserFromLocalStorage();

            // console.log("ispis")
            // if (payload) {
            //     showToast(payload, "info")
            // }

        },
        putImageWebpSupport: (state, { payload }) => {
            state.webpSupport = payload;

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(clearStore.rejected, () => {
                showToast("Desila se greska", "error")
            })
            .addCase(clearStore.fulfilled, (state) => {
                state = initialState;
                showToast("Uspesno pozvana metoda", "info")

            });
    }

});


export const { clearValues, putImageWebpSupport } = BasicSlice.actions;
export default BasicSlice.reducer;

