import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchArticleDetailThunk,
  fetchArticleAttributeThunk,
} from "./articleDetail.actions";

export const fetchArticleDetail = createAsyncThunk(
  "articleDetail/fetchSArticleDetail",
  fetchArticleDetailThunk
);
export const fetchArticleAttribute = createAsyncThunk(
  "articleDetail/fetchSArticleAttribute",
  fetchArticleAttributeThunk
);

const initialState = {
  articleDetail: {
    data: [],
    is_fetch: false,
  },
  articleAttribute: {
    data: [],
    is_fetch: false,
  },
};

const ArticleDetailSlice = createSlice({
  name: "articleDetail",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticleDetail.fulfilled, (state, { payload }) => {
        return { ...state, articleDetail: { is_fetch: true, data: payload } };
      })
      .addCase(fetchArticleDetail.pending, (state) => {
        return { ...state, articleDetail: { is_fetch: false, data: [] } };
      })
      .addCase(fetchArticleDetail.rejected, (state) => {
        return { ...state, articleDetail: { is_fetch: true, data: [] } };
      })
      .addCase(fetchArticleAttribute.fulfilled, (state, { payload }) => {
        return { ...state, articleAttribute: { is_fetch: true, data: payload }};
      })
      .addCase(fetchArticleAttribute.pending, (state) => {
        return { ...state, articleAttribute: { is_fetch: false, data: [] } };
      })
      .addCase(fetchArticleAttribute.rejected, (state) => {
        return { ...state, articleAttribute: { is_fetch: true, data: [] } };
      });
  },
});

export default ArticleDetailSlice.reducer;
