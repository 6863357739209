import { useContext } from "react";
import { BreadcrumbContext } from "./BreadcrumbProvider";
import { Breadcrumb } from "antd";
import "./Breadcrumbs.scss";

const Breadcrumbs = () => {
  const context = useContext(BreadcrumbContext);

  if (!context) {
    return null;
  }

  const { breadcrumbs } = context;

  if (breadcrumbs !== undefined) {
    return (
      <div className="Page">
        <div className="Breadcrumbs-Content">
          <Breadcrumb items={breadcrumbs} className="Breadcrumbs" />
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default Breadcrumbs;
