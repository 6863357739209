import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSlidersThunk } from "./sliders.actions";
import { SlidersState, Slider } from "./SliderType";

export const fetchSliders = createAsyncThunk<Slider[], void, { rejectValue: string }>(
  "sliders/fetchSliders",
  fetchSlidersThunk
);

const initialState: SlidersState = {
  list: [],
  is_fetch: false,
};

const SlidersSlice = createSlice({
  name: "sliders",
  initialState,
  reducers: {
    clearValues: (state) => {
      state.list = initialState.list;
      state.is_fetch = initialState.is_fetch;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSliders.fulfilled, (state, { payload }) => {
        return { ...state, is_fetch: true, list: payload };
      })
      .addCase(fetchSliders.pending, (state) => {
        return { ...state, is_fetch: false };
      })
      .addCase(fetchSliders.rejected, (state) => {
        return { ...state, is_fetch: false };
      });
  },
});

export const { clearValues } = SlidersSlice.actions;
export default SlidersSlice.reducer;
