import { useState } from "react";
import { InputNumber, Slider } from "antd";

import "./PriceItem.scss";

const PriceItem = ({ minDefault, maxDefault, changePrice, marks, minPrice, maxPrice }: any) => {
  const [min, setMin] = useState(minDefault)
  const [max, setMax] = useState(maxDefault)
  const [priceSlider, setPriceSlider] = useState([minPrice ? minPrice : minDefault, maxPrice ? maxPrice : maxDefault]);
  const handlePrice = (price:any)=> {
    if(price[0] === minDefault && price[1] === maxDefault){
      changePrice([null, null])
    }else if(price[0] === minDefault){
      changePrice([null, price[1]])
    }else if(price[1] === maxDefault){
      changePrice([price[0], null])
    }else{
      changePrice(price)
    }
  }
  const onChangeSlider = (e: any) => {
    setPriceSlider(e);
    
  };
  const onSliderComplete = (e:any)=> {
    handlePrice(e)
  }
  const onInputPriceLowerChange = (e: any) => {
    setPriceSlider([e.target.value, priceSlider[1]]);
    handlePrice([e.target.value, priceSlider[1]])
  };
  const onInputPriceHigherChange = (e: any) => {
    setPriceSlider([priceSlider[0], e.target.value]);
    handlePrice([priceSlider[0], e.target.value])
  };
  return (
    <div className="Price-Box">
      <Slider
        range
        marks={marks}
        min={min}
        max={max}
        className="Price-Slider"
        defaultValue={[min, max]}
        value={[priceSlider[0], priceSlider[1]]}
        onChange={onChangeSlider}
        onChangeComplete={onSliderComplete}
      />
      <div
        className="Price-Inputs"
      >
        <div className="Price-Min">
          <label className="Price-Input-Label">Min</label>
          <InputNumber
            min={min}
            max={max}
            defaultValue={min}
            className="Price-Input"
            value={priceSlider[0]}
            onPressEnter={onInputPriceLowerChange}
          />
        </div>
        <div className="Price-Max">
          <label className="Price-Input-Label">Max</label>
          <InputNumber
            min={min}
            max={max}
            
            className="Price-Input"
            defaultValue={max}
            value={priceSlider[1]}
            onPressEnter={onInputPriceHigherChange}
          />
        </div>
      </div>
    </div>
  );
};
export default PriceItem;
