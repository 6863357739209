import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CustomCarousel = ({
  device_type,
  items_computer,
  items_tablet,
  items_mobile,
  slides_computer,
  slides_tablet,
  slides_mobile,
  children,
  partial_computer,
  partial_tablet,
  partial_mobile,
  show_dots,
  arrow_computer,
  arrow_tablet,
  arrow_mobile,
}: any) => {
  const responsive = {
    computer: {
      breakpoint: { max: 3000, min: 1024 },
      items: items_computer,
      partialVisibilityGutter: partial_computer,
      slidesToSlide: slides_computer,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: items_tablet,
      paritialVisibilityGutter: partial_tablet,
      slidesToSlide: slides_tablet,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: items_mobile,
      partialVisibilityGutter: partial_mobile,
      slidesToSlide: slides_mobile,
    },
  };

  const removeArrow = () => {
    let list: string[] = ["computer", "tablet", "mobile"];
    if (arrow_computer) {
      let index = list.indexOf("computer");
      if (index > -1) {
        list.splice(index, 1);
      }
    }
    if (arrow_tablet) {
      let index = list.indexOf("tablet");
      if (index > -1) {
        list.splice(index, 1);
      }
    }
    if (arrow_mobile) {
      let index = list.indexOf("mobile");
      if (index > -1) {
        list.splice(index, 1);
      }
    }
    return list;
  };
  return (
    <Carousel
      // swipeable={false}
      draggable={false}
      showDots={show_dots}
      responsive={responsive}
      ssr={true}
      infinite={true}
      // autoPlay={device !== "mobile"? true : false }
      autoPlay={true}
      autoPlaySpeed={6000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={removeArrow()}
      deviceType={device_type}
      // dotListClass="custom-dot-list-style"
      itemClass="image-item"
      partialVisible
    >
      {children}
    </Carousel>
  );
};
export default CustomCarousel;
