import { createContext, useState, useEffect, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbLink from "./BreadcrumbLink";
import { useTranslation } from "react-i18next";

interface BreadcrumbContextType {
  breadcrumbs: any;
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(
  undefined
);

const BreadcrumbProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation("breadcrumbs", { useSuspense: false });
  const [breadcrumbs, setBreadcrumbs] = useState<any>([]);
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    if (
      currentPath === "/" ||
      currentPath === "/login" ||
      currentPath === "/registration" ||
      currentPath === "/verify" ||
      currentPath === "/order" ||
      currentPath.includes("/article")
    ) {
      setBreadcrumbs(undefined);
    } else {
      const pathSegments = currentPath.split("/").filter(Boolean);
      const newBreadcrumbs = pathSegments.map((segment, index, array) => {
        const path = `/${array.slice(0, index + 1).join("/")}`;
        return {
          title: (
            <BreadcrumbLink
              label={t(segment.replace(/-/g, " "))}
              path={path}
              title={t(segment.replace(/-/g, " "))}
            />
          ),
        };
      });
      setBreadcrumbs([
        {
          title: (
            <BreadcrumbLink label={t("home")} path={"/"} title={t("home")} />
          ),
        },
        ...newBreadcrumbs,
      ]);
    }
  }, [location]);

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbs }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};
export { BreadcrumbContext, BreadcrumbProvider };
