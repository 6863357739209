import { useEffect, useState } from "react";
import InfocompKatalog2024 from "../../assets/Catalog/InfocompKatalogBezSolara2024.pdf";

import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import "react-pdf/dist/esm/Page/TextLayer.css";
import "./CatalogComponent.scss";
import { LeftCircleOutlined, LoadingOutlined, RightCircleOutlined } from "@ant-design/icons";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

// Potrebno je postaviti radnički PDF.js skriptu
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CatalogComponent = () => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(500);
  const [height, setHeight] = useState(500);

  // Skeleton Loader Komponenta
  const SkeletonLoader = () => (
    <div
      className="skeleton-loader"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <LoadingOutlined/>
    </div>
  );

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setLoading(false);
  }
  function onPageLoadSuccess(page: any) {
    const { width, height } = page.originalWidth ? page : page._pageInfo.view;
    setWidth(width);
    setHeight(height);
    // console.log(width + " " + height)
    setLoading(false);
    // Izračunajte skaliranje na osnovu dimenzija stranice i kontejnera
    // Na primer: setScale(kontejnerWidth / width);
  }

  function changePage(offset: any) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
    setLoading(true);
  }

  function goToPrevPage() {
    changePage(-1);
  }

  function goToNextPage() {
    changePage(1);
  }

  useEffect(() => {
    const handleResize = () => {
      const currentScale = window.innerWidth < 768 ? 0.6 : 0.9;
      setScale(currentScale);
    };

    // Inicijalno postavljanje i listener za resize
    handleResize();
    window.addEventListener("resize", handleResize);

    // Očistite listener kada komponenta nije više montirana
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Catalog-Component">
      <div className="Catalog-Navigation">
        <p className="Catalog-Label">
          Stranica {pageNumber} od {numPages}
        </p>
        <div className="Catalog-Buttons">
          <button
            disabled={pageNumber <= 1}
            onClick={goToPrevPage}
            className="Catalog-Button"
          >
            <LeftCircleOutlined />
          </button>
          <button
            disabled={pageNumber >= numPages}
            onClick={goToNextPage}
            className="Catalog-Button"
          >
            <RightCircleOutlined />
          </button>
        </div>
      </div>
      {loading && <SkeletonLoader />}
      <div className="Catalog-Document">
        <Document
          file={InfocompKatalog2024}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={() => setLoading(false)}
          loading={<div></div>}
        >
          <Page
            pageNumber={pageNumber}
            key={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={scale}
            onLoadSuccess={onPageLoadSuccess}
            onLoadError={() => setLoading(false)}
            loading={<div></div>}
          />
        </Document>
      </div>

      <div className="Catalog-Navigation">
        <p className="Catalog-Label">
          Stranica {pageNumber} od {numPages}
        </p>
        <div className="Catalog-Buttons">
          <button
            disabled={pageNumber <= 1}
            onClick={goToPrevPage}
            className="Catalog-Button"
          >
            <LeftCircleOutlined />
          </button>
          <button
            disabled={pageNumber >= numPages}
            onClick={goToNextPage}
            className="Catalog-Button"
          >
            <RightCircleOutlined />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CatalogComponent;
