import { Avatar, Space, Table, TableProps, Tag, Tooltip } from "antd";
import "./CartTable.scss";
import { PhotoDisplayHandler } from "../../../components";
import LinkLanguage from "../../../locales/LinkLanguage";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { addArticle, removeArticle } from "../../../store/cart/cart.slice";
import { showToast } from "../../../utils/helpers/toastHelper";

const CartTable = ({ cartList, view }: any) => {
  const dispatch: any = useDispatch();
  interface DataType {
    key: string;
    slug: string;
    name: string;
    image: string;
    price: number;
    discount: number;
    total_price: number;
    count: number;
    old_price: number;
  }

  const addToCart = (slug: any) => {
    // console.log(slug);
    let data = getData().filter((i) => i.slug === slug)[0];
    dispatch(
      addArticle({
        price: data.price,
        discount: data.discount,
        old_price: data.old_price,
        slug: data.slug,
        name: data.name,
        image: data.image,
        quantity: 1,
      })
    );

    // showToast(`Proizvod dodat u korpu`, "success");
  };

  const removeFromCart = (slug: any) => {
    // console.log(slug);
    dispatch(
      removeArticle({
        item_slug: slug,
        all_items: false,
      })
    );
    // showToast(`Proizvod uklonjen iz korpe`, "warn");
  };

  const removeAllFromCart = (slug: any) => {
    // console.log(slug);
    dispatch(
      removeArticle({
        item_slug: slug,
        all_items: true,
      })
    );
    // showToast(`Proizvod uklonjen iz korpe`, "warn");
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Slika",
      dataIndex: "image",
      key: "image",
      render: (image_path) => (
        <div >
          <PhotoDisplayHandler
            img_name={image_path}
            calculate={false}
            img_class_name="CartImage"
            img_alt={image_path}
            width=""
            height=""
          />
        </div>
      ),
    },
    {
      title: "Naziv artikla",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <LinkLanguage
          to={`/article/${record.slug}`}
          className="Cart-Table-Label"
        >
          {record.name}
        </LinkLanguage>
      ),
    },
    // {
    //   title: "Cijena",
    //   dataIndex: "old_price",
    //   key: "old_price",
    //   render: (_, record) => (
    //     <label className="Cart-Table-Label">
    //       {Number.isNaN(record.old_price) || record.old_price === null
    //         ? record.price.toFixed(2)
    //         : record.old_price.toFixed(2)}
    //       &nbsp;KM
    //     </label>
    //   ),
    // },
    // {
    //   title: "Popust",
    //   key: "discount",
    //   dataIndex: "discount",
    //   render: (_, record) => (
    //     <label className="Cart-Table-Label">{record.discount}&nbsp;%</label>
    //   ),
    // },
    // {
    //   title: "Cijena sa popustom",
    //   dataIndex: "price",
    //   key: "price",
    //   render: (_, record) => (
    //     <label className="Cart-Table-Label">
    //       {record.price.toFixed(2)}&nbsp;KM
    //     </label>
    //   ),
    // },
    {
      title: "Kol.",
      key: "count",
      render: (_, record) => (
        <div className="Box-Items-Horizontal-Center ">
          {!view && (
            <Tooltip title="Izbaci iz korpe">
              <button
                onClick={() => removeFromCart(record.slug)}
                className="Round-Button"
              >
                <Avatar
                  icon={<MinusOutlined />}
                  size={"small"}
                  className="Button-Avatar-Gray"
                ></Avatar>
              </button>
            </Tooltip>
          )}

          <label className="Cart-Table-Label">{record.count}</label>
          {!view && (
            <Tooltip title="Dodaj u korpu">
              <button
                onClick={() => addToCart(record.slug)}
                className="Round-Button"
              >
                <Avatar
                  icon={<PlusOutlined />}
                  size={"small"}
                  className="Button-Avatar-Gray"
                />
              </button>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Ukupno",
      dataIndex: "total_price",
      key: "total_price",
      render: (_, record) => (
        <label className="Cart-Table-Label">
          {record.total_price.toFixed(2)}&nbsp;KM
        </label>
      ),
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => {
        return !view ? (
          <div className="Box-Items-Horizontal-Center ">
            <Tooltip title="Izbaci iz korpe">
              <button
                onClick={() => removeAllFromCart(record.slug)}
                className="Round-Button"
              >
                <Avatar
                  icon={<DeleteOutlined />}
                  size={"small"}
                  className="Button-Avatar"
                ></Avatar>
              </button>
            </Tooltip>
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  const getData = () => {
    let data: DataType[] = [];
    cartList.forEach((item: any) => {
      data.push({
        key: item.slug,
        slug: item.slug,
        name: item.name,
        price: item.price,
        total_price: item.total_price,
        image: item.image,
        discount: item.discount,
        count: item.count,
        old_price: item.old_price,
      });
    });
    return data;
  };

  return (
    <Table
      columns={columns}
      dataSource={getData()}
      size="small"
      pagination={false}
      className="custom-table" 
    />
  );
};
export default CartTable;
