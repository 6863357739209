import { configureStore } from '@reduxjs/toolkit';
import BasicSlice from './basic/basic.slice';
import CompanyInfoSlice from './companyInfo/companyInfo.slice';
import ArticleCategory from './articleCategory/articleCategory.slice';
import BanersSlice from './baners/baners.slice';
import SlidersSlice from './sliders/sliders.slice';
import UserSlice from './user/user.slice';
import BlogsSlice from './blogs/blogs.slice';
import AuthSlice from './authentication/authentication.slice';
import ArticlesSlice from './articles/articles.slice';
import ArticleDetailSlice from './articleDetail/articleDetail.slice';
import CartSlice from './cart/cart.slice';
import OrderSlice from './order/order.slice';
import SearchSlice from './search/search.slice';

export const store = configureStore({
  reducer: {
    basic: BasicSlice,
    companyInfo : CompanyInfoSlice,
    articleCategory: ArticleCategory,
    baners: BanersSlice,
    sliders: SlidersSlice,
    user: UserSlice,
    blogs: BlogsSlice,
    auth: AuthSlice,
    articles: ArticlesSlice,
    articleDetail: ArticleDetailSlice,
    cart: CartSlice,
    order: OrderSlice,
    search: SearchSlice
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
