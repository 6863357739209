import { Tag } from "antd";
import "./AppliedFilters.scss";
import { truncateString } from "../../../../../utils/helpers/strings";

const AppliedFilters = ({
  appliedFilters,
  addFilter,
  changePrice,
  minPrice,
  maxPrice,
  priceRange,
}: any) => {
  const handleClose = (filter: any) => {
    addFilter(filter.filter_label, []);
  };


  return (
    <div className="Applied-Filters">
      {/* https://ant.design/components/tag */}
      {appliedFilters.length !== 0 &&
        appliedFilters.map((filter: any) => {
          let text = filter.filter_label + ": ";
          filter.filter_values.forEach((value: any, key: any) => {
            key += 1;
            if (key === filter.filter_values.length) {
              text += value;
            } else {
              text += value + ", ";
            }
          });
          return (
            <Tag
              color="default"
              bordered={false}
              closable
              onClose={() => handleClose(filter)}
              key={filter.filter_label}
            >
              {truncateString(text, 50)}
            </Tag>
          );
        })}
      {(minPrice || maxPrice) && (
        <Tag
          color="default"
          bordered={false}
          closable
          onClose={() => changePrice([null, null])}
          key={minPrice}
        >
          Cijena: {minPrice ? minPrice : priceRange.minPrice} - {maxPrice ? maxPrice : priceRange.maxPrice} KM
        </Tag>
      )}
    </div>
  );
};
export default AppliedFilters;
