import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CompanyInfoState, CompanyInfoType } from "./CompanyInfoType";
import {
  fetchCompanyInfoThunk,
  fetchCompaniesInfoThunk,
} from "./companyInfo.actions";

export const fetchCompanyInfo = createAsyncThunk<CompanyInfoType, void, { rejectValue: string }>(
  "companyInfo/fetchCompanyInfo",
  fetchCompanyInfoThunk
);
export const fetchCompaniesInfo = createAsyncThunk<CompanyInfoType[], void, { rejectValue: string }>(
  "companyInfo/fetchCompaniesInfo",
  fetchCompaniesInfoThunk
);

export const companyInfo: CompanyInfoType = {
  is_fetch: false,
  store_name: "Infocomp",
  store_type_name: "Prodavnica",
  address: {},
  emails: [],
  telephones: [],
  business_hours: [],
};
export const companiesInfoInitialState: CompanyInfoState = {
  companies: {
    data: [],
    is_fetch: false,
  },
  company: companyInfo,
};

const CompanyInfoSlice = createSlice({
  name: "companyInfo",
  initialState: companiesInfoInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyInfo.fulfilled, (state, { payload }) => {
        state.company = {
          is_fetch: true,
          ...payload,
        };
      })
      .addCase(fetchCompanyInfo.pending, (state) => {
        state.company.is_fetch = false;
      })
      .addCase(fetchCompanyInfo.rejected, (state) => {
        state.company.is_fetch = true;
      })
      .addCase(fetchCompaniesInfo.fulfilled, (state, { payload }) => {
        return {
          ...state,
          companies: {
            is_fetch: true,
            data: payload,
          },
        };
      })
      .addCase(fetchCompaniesInfo.pending, (state) => {
        state.companies.is_fetch = false;
      })
      .addCase(fetchCompaniesInfo.rejected, (state) => {
        state.companies.is_fetch = true;
      });
  },
});

export default CompanyInfoSlice.reducer;
