import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import PhotoGallery from "../../../components/photo/PhotoGallery";

import "./ArticleDetail.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchArticleAttribute,
  fetchArticleDetail,
} from "../../../store/articleDetail/articleDetail.slice";
import {
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { addArticle } from "../../../store/cart/cart.slice";
import { BreadcrumbLink } from "../../../components";
import { useTranslation } from "react-i18next";
import { Avatar, Breadcrumb, InputNumber, Tooltip } from "antd";
import ShareIcons from "../../../components/shareIcons/ShareIcons";
import { setSEO } from "../../../utils/helpers/seo-function";

import "../../../utils/helpers/ToastHelper.scss";
import { showToast } from "../../../utils/helpers/toastHelper";
import { useEffectDeviceAndOrientation } from "../../../utils/hooks";
import { BASE_URL } from "../../../config/constants";
import { IoCall } from "react-icons/io5";

const ArticleDetail = () => {
  const { t } = useTranslation();
  const [device, orientation] = useEffectDeviceAndOrientation();
  const { pathname } = useLocation();
  const dispatch: any = useDispatch();
  let { articleSlug } = useParams();
  const { articleDetail, articleAttribute } = useSelector(
    (store: any) => store.articleDetail
  );
  const [count, setCount] = useState<number>(1);
  const navigate = useNavigate();

  const handleCount = (value: number | null) => {
    if (value !== null) {
      setCount(Math.floor(Number(value)));
    } else {
      setCount(1);
    }
  };

  const addToCart = (e: any) => {
    dispatch(
      addArticle({
        price: articleDetail.data.article_price.price,
        discount: articleDetail.data.article_price.discount,
        old_price: articleDetail.data.article_price.old_price,
        slug: articleDetail.data.article_slug,
        name: articleDetail.data.article_name,
        image: articleDetail.data.images[0],
        quantity: count,
      })
    );
    showToast(`Proizvod dodat u korpu`, "success");
  };

  useEffect(() => {
    if (articleSlug !== "" && articleSlug) {
      dispatch(fetchArticleDetail(articleSlug));
      dispatch(fetchArticleAttribute(articleSlug));
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    if (articleDetail.is_fetch === true) {
      setSEO({
        title:
          articleDetail.data.article_name !== null
            ? articleDetail.data.article_name
            : "",
        description:
          articleDetail.data.article_description !== null
            ? articleDetail.data.article_description
            : "",
        keywords:
          articleDetail.data.seo_keywords !== null
            ? articleDetail.data.seo_keywords
            : [],
        url: BASE_URL + pathname,
        image:
          articleDetail.data.images[0] !== null
            ? articleDetail.data.images[0]
            : "",
        type: "article",
      });
    }
  }, [articleDetail.is_fetch]);

  const getBreadcrumbItems = () => {
    let list = [
      {
        title: (
          <BreadcrumbLink
            label={t("pocetna")}
            path={"/"}
            title={t("pocetna")}
          />
        ),
      },
      {
        title: (
          <BreadcrumbLink
            label={
              device === "computer" && orientation === "landscape"
                ? t("proizvodi")
                : "..."
            }
            path={"/articles"}
            title={t("proizvodi")}
          />
        ),
      },
    ];
    if (articleDetail.is_fetch === true) {
      for (let i = 0; i < articleDetail.data.breadcrumb.length; i++) {
        if (
          i === articleDetail.data.breadcrumb.length - 1 ||
          (device === "computer" && orientation === "landscape")
        ) {
          list.push({
            title: (
              <BreadcrumbLink
                label={articleDetail.data.breadcrumb[i].ac_name}
                path={`/articles${articleDetail.data.breadcrumb[i].path}`}
                title={articleDetail.data.breadcrumb[i].ac_name}
              />
            ),
          });
        } else {
          list.push({
            title: (
              <BreadcrumbLink
                label={"..."}
                path={`/articles${articleDetail.data.breadcrumb[i].path}`}
                title={articleDetail.data.breadcrumb[i].ac_name}
              />
            ),
          });
        }
      }
    }

    return list;
  };

  return (
    <div className="Page">
      <div className="Page-Content">
        <Breadcrumb items={getBreadcrumbItems()} />
        {articleDetail.is_fetch ? (
          <div className="Article-Detail-Box">
            <div className="Article-Detail-Photo">
              <PhotoGallery
                images={articleDetail.data.images}
                type={"article"}
              />
            </div>
            <div className="Article-Detail-Info">
              <h1 className="Article-Title">
                {articleDetail.data.article_name}
              </h1>
              {articleDetail.data.vendor.vendor_url !== null && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={articleDetail.data.vendor.vendor_url}
                >
                  <label
                    className="Vendor-Link"
                    title={articleDetail.data.vendor.vendor_name}
                  >
                    {articleDetail.data.vendor.vendor_name}
                  </label>
                </Link>
              )}

              <p className="Article-Description">
                {articleDetail.data.article_description}
              </p>
              {/* <p className="Article-Description" dangerouslySetInnerHTML={{ __html: textToParagraphs(data.article_description) }}></p>  */}
              {articleDetail.data.article_price.is_visible ? (
                <>
                  {articleDetail.data.article_price.on_sale && (
                    <label className="Article-Old-Price">
                      {articleDetail.data.article_price.old_price} KM
                    </label>
                  )}
                  <h2 className="Article-Price">
                    {articleDetail.data.article_price.price} KM
                  </h2>
                  {articleDetail.data.article_price.on_sale && (
                    <label className="Article-Discount-Price">
                      Ušteda{" "}
                      {(
                        articleDetail.data.article_price.old_price -
                        articleDetail.data.article_price.price
                      ).toFixed(2)}{" "}
                      KM
                    </label>
                  )}
                  <label className="Label-Block">
                    *Cijene su sa uračunatim PDV-om
                  </label>
                  <div>
                    {/* <label>Izaberi količinu:</label> */}
                    <div className="Box-Items-Horizontal-Center ">
                      <button
                        onClick={() => {
                          if (count > 0) {
                            setCount(count - 1);
                          }
                        }}
                        className="Round-Button"
                        disabled={count === 0}
                      >
                        <Avatar
                          icon={<MinusOutlined />}
                          size={"large"}
                          className="Button-Avatar"
                        ></Avatar>
                      </button>

                      <InputNumber
                        className="Add-To-Chart-Label"
                        size="large"
                        min={1} //max={100}  ??
                        step={1}
                        value={count}
                        onChange={handleCount}
                        precision={0}
                      />
                      <button
                        onClick={() => setCount(count + 1)}
                        className="Round-Button"
                      >
                        <Avatar
                          icon={<PlusOutlined />}
                          size={"large"}
                          className="Button-Avatar"
                        />
                      </button>

                      <Tooltip title="Dodaj u korpu">
                        <button
                          onClick={addToCart}
                          className={
                            count === 0 ? "Disabled-Gray-Button" : "Gray-Button"
                          }
                          disabled={count === 0}
                        >
                          Dodaj u korpu
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </>
              ) : (
                <div className="Box-Items-Horizontal-Center ">
                  <label className="Label-Block">Cijena na upit &nbsp;</label>
                  <Tooltip title="Kontaktiraj nas">
                    <button
                      onClick={() => navigate("/contact")}
                      className="Gray-Button"
                    >
                      <IoCall />
                      &nbsp; Kontaktirajte nas
                    </button>
                  </Tooltip>
                </div>
              )}

              <ShareIcons
                url={pathname}
                title={articleDetail.data.article_name}
                summary={articleDetail.data.article_description}
              />
            </div>
          </div>
        ) : (
          <LoadingOutlined />
        )}
        {articleAttribute.is_fetch ? (
          <div className="Article-Detail-Parameters">
            <h2>Specifikacije i detalji:</h2>

            <div className="Article-Detail-Parameters-List">
              {articleAttribute.data.map((attribute: any, y: any) => (
                <div className="Article-Detail-Parameters-List-Item" key={y}>
                  <div>
                    <label>{attribute.attribute_name}:</label>
                  </div>
                  <div>
                    <label>
                      {attribute.attribute_value
                        ? attribute.attribute_value
                        : "-"}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <LoadingOutlined />
        )}
      </div>
    </div>
  );
};
export default ArticleDetail;
