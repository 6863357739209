import "./GridBox.scss";

const GridBox = ({ children, type = 1}: any) => {
  // console.log(type)
  return (
    <div className={type === 1 ? "Grid-Box-Card1" : type === 3 ? "Grid-Box-Card3": "Grid-Box-Card2"}>
        {children}
    </div>
  );
};
export default GridBox;