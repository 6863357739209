import axios from 'axios';
import { getLanguageFromLocalStorage, getTokenFromLocalStorage } from '../services/LocalStorage/LocalStorage.service';
import { API_BASE_URL } from '../config/constants';

const customAxiosInterceptor = axios.create({
  baseURL: API_BASE_URL,
});

customAxiosInterceptor.interceptors.request.use((config) => {
  const access_token = getTokenFromLocalStorage();
  const lng = getLanguageFromLocalStorage();
  // console.log(lng)
  if (access_token) {
    config.headers['Authorization'] = `Bearer ${access_token}`;
  }
  config.headers['Accept-Language'] = lng;
  return config;
});

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
    if (error.response.status === 401) {
      // thunkAPI.dispatch(clearStore());
      // console.error(error.response.data.message)
      return thunkAPI.rejectWithValue('Unauthorized! Logging Out...');
    }
    return thunkAPI.rejectWithValue(error.response.data.message);
  };


export default customAxiosInterceptor;