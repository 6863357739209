import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  LogoutOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  getCartFromLocalStorage,
  isLogin,
} from "../../services/LocalStorage/LocalStorage.service";
import { logout } from "../../store/user/user.slice";

import LanguageSelector from "../../locales/LanguageSelector";
import LinkLanguage from "../../locales/LinkLanguage";
import Logo from "./Logo";
import SearchForm from "./search/SearchForm";
import Menu from "./menu/Menu";

import colors from "../../utils/scss-utils/_colors.module.scss";
import "./Header.scss";
import { useEffectDeviceAndOrientation } from "../../utils/hooks";
import { Badge } from "antd";
import { useEffect } from "react";

const Header = () => {
  const { t } = useTranslation();
  const [device, orientation] = useEffectDeviceAndOrientation();

  const cart = useSelector((store: any) => store.cart);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const logoutUser = async () => {
    dispatch(logout());
    navigate("/login");
  };

  const getHeaderIcons = () => {
    return (
      <>
        <LinkLanguage className="Header-Element" to="/cart">
          <Badge count={cart.total_count ? cart.total_count : 0}>
            <ShoppingCartOutlined className="Header-Icon" />
          </Badge>

          <label className="Header-Element-Label">
            {device === "computer" && orientation === "landscape"
              ? t("korpa")
              : null}
          </label>
        </LinkLanguage>

        {device === "computer" && orientation === "landscape" && (
          <>
            {/* {isLogin() && (
              <LinkLanguage className="Header-Element" to="/profile">
                <div className="Header-Icon">
                  <UserOutlined />
                </div>
            
                <label className="Header-Element-Label">{t("profil")}</label>
              </LinkLanguage>
            )} */}
            {isLogin() ? (
              <div className="Header-Element" onClick={() => logoutUser()}>
                <div className="Header-Icon">
                  <LogoutOutlined />
                </div>
                <label className="Header-Element-Label">
                  {device === "computer" && orientation === "landscape"
                    ? t("odjavi_se")
                    : null}
                </label>
              </div>
            ) : (
              <LinkLanguage className="Header-Element" to="/login">
                <UserOutlined className="Header-Icon" />
                <label className="Header-Element-Label">
                  {device === "computer" && orientation === "landscape"
                    ? t("prijavi_se")
                    : null}
                </label>
              </LinkLanguage>
            )}
          </>
        )}

        {/* <div className="Header-Element">
          <LanguageSelector />
        </div> */}
      </>
    );
  };

  const getHeaderRowFullColor = () => {
    if (device === "computer" && orientation === "landscape") {
      return colors.blackColor;
    } else if (device === "computer" && orientation === "portrait") {
      return colors.lightColor;
    } else if (device === "mobile") {
      return colors.lightColor;
    } else {
      return colors.lightColor;
    }
  };

  return (
    <header className="Header">
      {/* Header1 */}
      <div className="Header-Row-Light ">
        <div className="Header1-Item1">
          {device === "computer" && orientation === "landscape" && (
            <Logo type="Desktop" />
          )}
          {device === "computer" && orientation === "portrait" && <Menu />}
          {device === "mobile" && <Menu />}
        </div>

        <div className="Header1-Item2">
          {device === "computer" && orientation === "landscape" && (
            <SearchForm />
          )}
          {device === "computer" && orientation === "portrait" && (
            <Logo type="Mobile" />
          )}
          {device === "mobile" && <Logo type="Mobile" />}
        </div>

        <div className="Header1-Item3">{getHeaderIcons()}</div>
      </div>

      {/* Header2 */}
      <div
        className="Header-Row-Full"
        style={{ backgroundColor: getHeaderRowFullColor() }}
      >
        <div className="Header-Row-Dark">
          {device === "computer" && orientation === "landscape" && <Menu />}
          {device === "computer" && orientation === "portrait" && (
            <SearchForm />
          )}
          {device === "mobile" && <SearchForm />}
        </div>
      </div>
    </header>
  );
};
export default Header;
