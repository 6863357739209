import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {  FloatButton } from "antd";
import { Header, Footer, Backdrop } from "../index";
import { useEffectDeviceAndOrientation } from "../../utils/hooks";
import "react-toastify/dist/ReactToastify.css";
import "./Dashboard.scss";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumbs";
import { FaViber } from "react-icons/fa6";

const Dashboard = () => {
  const { pathname } = useLocation();
  const [device, orientation] = useEffectDeviceAndOrientation();
  // const [t, i18n] = useTranslation('dashboard')

  // TODO ucitati nove namespace-ove ako se promeni neki pathname
  // Automatically scrolls to top whenever pathname changes
  // const myTranslation = async () => {
  //   
  //   if (pathname.includes("/login")) {
  //     await i18n.loadNamespaces("login");
  //   } else if (pathname.includes("/registration")) {
  //     await i18n.loadNamespaces("registration");
  //   }
  // };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // myTranslation();
  }, [pathname]);

  return (
    <div className="App">
      <Header />
      {device === "mobile" ||
      (device === "computer" && orientation === "portrait") ? (
        <Backdrop />
      ) : (
        <></>
      )}

      <div className="Content">
        <Breadcrumbs />
        <Outlet />
      </div>
      <Footer />

      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        // pauseOnHover -> PAUZIRA PRI KRAJU
        theme="light"
        className={
          device === "mobile" ? "Generic-Toast-Mobile" : "Generic-Toast"
        }
      />
      <FloatButton type="primary" style={{ insetInlineEnd: 24 }} className="Float-Button-1" tooltip={<div>Kliknite za Viber Chat</div>} href="viber://chat?number=%2B38766894882" icon={<FaViber />}/>
      <FloatButton.BackTop type="default" style={{ insetInlineEnd: 74 }} className="Float-Button-2" tooltip={<div>Vrati se na početak</div>}/>
      
    </div>
  );
};

export default Dashboard;
