// FUNKCIJA ZA POVRATAK NA POČETAK STRANICE
export const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
    // window.location.reload
};

// NE KORISTI SE 
// OTIĆI NA ŽELJENI DEO NA EKRANU
export const goToExample = (topHeight:any) => {
    window.scrollTo({
        top: topHeight,
        behavior: 'smooth',
    });
};

// NE KORISTI SE I NE RADI
// FUNKCIJA ZA ODLAZAK NA PROSLEĐENU POZICIJU NA EKRANU
/*     export const goToDown = () => {
     const scrollTop = document.getElementById("text").scrollHeight
     goToExample(scrollTop)
}*/

// FUNKCIJA KOJA MOŽE DA ZABRANI SKROLOVANJE PO EKRANU
/* const lockScroll = React.useCallback(() => {
   document.body.style.overflow = 'hidden';
 }, [])
*/

// FUNKCIJA KOJA MOŽE DA DOZVOLI SKROLOVANJE PO EKRANU
/* const unlockScroll = React.useCallback(() => {
  document.body.style.overflow = '';
 }, [])
 */