import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginThunk, registerThunk, resendVerifyThunk, verifyThunk } from "./authentication.actions";
import { toast } from "react-toastify";
import { addUserToLocalStorage, getUserDetailFromToken, removeUserFromLocalStorage } from "../../services/LocalStorage/LocalStorage.service";
import { showToast } from "../../utils/helpers/toastHelper";


export const login = createAsyncThunk('auth/login', loginThunk);
export const register = createAsyncThunk('auth/register', registerThunk);
export const verify = createAsyncThunk('auth/verify', verifyThunk);
export const resendVerify = createAsyncThunk('auth/resendVerify', resendVerifyThunk);

const userDetails = {
    id: null,
    email: "",
    user_firstname: "",
    user_lastname: "",
    penalty_num: null,
    email_verified_at: "",
    created_at: "",
    address: {
        id: null,
        state_name: "",
        region_name: "",
        city_name: "",
        location: "",
        zip: "",
        street_name: "",
        street_number: ""
    },
    roles: []
}

const newUser = {
    email: "",
    user_firstname: "",
    user_lastname: "",
    password: "",
    password_confirm: ""
}
const initialState = {
    user_details: userDetails,
    is_loading: false,
    new_user: newUser,
    verification: {
        is_loading: false,
        verified: false
    }
}

const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearValues: (state, { payload }) => {
            state.auth = initialState;
        },
        logout: (state) => {
            // console.log("nesto")
            removeUserFromLocalStorage();
            return { ...state, is_loading: false, user_details: userDetails };

        },
        getUserDetail: (state) => {
            const userDetail = getUserDetailFromToken();
            if (userDetail) {
                return { ...state, is_loading: false, user_details: { ...state.user_details, id: userDetail.id, roles: userDetail.roles } }
            }
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, { payload }) => {
                const jwtToken = payload.response_data.access_token;
                addUserToLocalStorage(jwtToken);
                const tokenPayload = jwtToken.split('.')[1];
                const decodedPayload = JSON.parse(atob(tokenPayload));
                showToast(`Dobrodošli ${payload.request_data.email}`, 'success');
                return { ...state, is_loading: false, user_details: { ...state.user_details, id: decodedPayload.id, roles: decodedPayload.roles, email: payload.request_data.email } }
            })
            .addCase(login.pending, (state) => {
                return { ...state, is_loading: true };
            })
            .addCase(login.rejected, (state) => {
                showToast("Greska prilikom prijave! Pokušajte ponovo.", 'error')
                return { ...state, is_loading: false, user_details: userDetails };
            })
            .addCase(register.fulfilled, (state, { payload }) => {
                showToast(payload.response_data.message + ". Proverite vaše sanduče.", "success")
                return { ...state, is_loading: false, new_user: payload.request_data }
            })
            .addCase(register.pending, (state) => {
                return { ...state, is_loading: true };
            })
            .addCase(register.rejected, (state) => {
                showToast("Greska. Pokušajte ponovo.", "error")
                return { ...state, is_loading: false, new_user: newUser };
            })
            .addCase(verify.fulfilled, (state, { payload }) => {
                showToast(payload.message, "success")
                state.verification.verified = true;
                state.verification.is_loading = false;
            })
            .addCase(verify.pending, (state) => {

                state.verification.is_loading = true;
            })
            .addCase(verify.rejected, (state, { payload }) => {
                state.verification.verified = false;
                state.verification.is_loading = false;

            })
            .addCase(resendVerify.fulfilled, (state, { payload }) => {
                showToast("Uspešno!", "success")
            })
            .addCase(resendVerify.pending, (state) => {

            })
            .addCase(resendVerify.rejected, (state, { payload }) => {
                showToast(payload, "error")
            });
    }

});


export const { clearValues, logout, getUserDetail } = AuthSlice.actions;
export default AuthSlice.reducer;

